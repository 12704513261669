if (typeof module === 'object') {
  window.module = module;
  module = undefined;
}

// SENTRY
// https://docs.sentry.io/platforms/javascript/configuration/options/
Sentry.init({
  dsn: 'https://dbf1d55f7ece4b1cac416a037857beaa@o266129.ingest.us.sentry.io/1470481',
  release: 'nirvana-desktop@3.9.2-beta',
});

// GLOBALS
var NIRV = {};

// console logging if true
var DEBUG = false;
var VERBOSE = false;
