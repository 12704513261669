// LISTENERS CBAR

$(function () {
  // ------------------------
  // RELOAD: UPDATE AVAILABLE
  $('body').on('click', '#cbar .update-available', function () {
    setTimeout(function () {
      window.location.reload();
    }, 20);
  });

  // ------------------------
  // REFLOW: PROJECT HEADER
  $('body').on('click', '#cbar li.task', function () {
    setTimeout(function () {
      NIRV.reflow();
    }, 20);
  });

  // ------------------------
  // FILTER RESET: SHOW ALL
  $('body').on('click', '#cbar li.tag.all', function () {
    if (NIRV.q != '') {
      NIRV.viewinitPreserveQ();
    } else {
      NIRV.viewinit();
    }
    NIRV.refreshCurrentview();
  });

  // ------------------------
  // FILTER BY DUEDATE
  $('body').on('click', '#cbar .filters li.due', function (e) {
    $(window).scrollTop(0);
    if (NIRV.currentfilters.due) {
      NIRV.currentfilters.due = !NIRV.currentfilters.due;
      NIRV.refreshCurrentview();
    } else {
      NIRV.currentfilters.due = !NIRV.currentfilters.due;
      NIRV.refreshCurrentview();
    }
  });

  // ------------------------
  // FILTER BY TAG
  $('body').on(
    'click',
    '#cbar li.tag.context, #cbar li.tag.contact, #cbar li.tag.area, #cbar li.tag.none',
    function (e) {
      // if clicked with modifier key we need to do the sticky buttons thang
      if (e.ctrlKey || e.metaKey) {
        $(this).toggleClass('on');
      }
      // else no modifier key, therefore clicks are mutually exclusive
      else {
        if ($(this).hasClass('on')) {
          if ($('#cbar li.tag.on').length > 1) {
          } else {
            $('#cbar li.tag').removeClass('on');
          }
        } else {
          $('#cbar li.tag').removeClass('on');
          $(this).addClass('on');
        }
      }

      // update global filters object
      NIRV.currentfilters.tags = [];
      $('#cbar li.tag.on').each(function () {
        var key = $(this).hasClass('none') ? '__NONE__' : $(this).text();
        NIRV.currentfilters.tags.push(key);
      });

      NIRV.refreshCurrentview();
      $(window).scrollTop(0);
    }
  );

  // ------------------------
  // CMENU: VIEW OPTIONS
  NIRV.renderViewOptionsMenu = function (e) {
    var x_pref = function (key, value) {
      return NIRV.prefs[key] != undefined && NIRV.prefs[key].value == value
        ? 'x'
        : '';
    };
    var html = '';
    html += '<ul class="sf-menu">';
    html += '   <li>';
    html += '      <a href="#" class="cmenu submenu">&nbsp;</a>';
    html += '      <ul>';
    if (NIRV.currentview == 'focus') {
      html += '         <li><span class="label">Focus View Options</span></li>';
      html +=
        '         <li><a href="#" class="' +
        x_pref('UIBFocusGroupBy', '') +
        '" fn="NIRV.setPref" p1="UIBFocusGroupBy" p2="" p3="true">unified list</a></li>';
      html +=
        '         <li><a href="#" class="' +
        x_pref('UIBFocusGroupBy', 'parentid') +
        '" fn="NIRV.setPref" p1="UIBFocusGroupBy" p2="parentid" p3="true">group by project</a></li>';
      html +=
        '         <li><a href="#" class="' +
        x_pref('UIBFocusGroupBy', 'state') +
        '" fn="NIRV.setPref" p1="UIBFocusGroupBy" p2="state" p3="true">group by state</a></li>';
    }
    if (NIRV.currentview == 'next') {
      html += '         <li><span class="label">Next View Options</span></li>';
      html +=
        '         <li><a href="#" class="' +
        x_pref('UIBNextGroupBy', 'none') +
        '" fn="NIRV.setPref" p1="UIBNextGroupBy" p2="none" p3="true">unified list</a></li>';
      html +=
        '         <li><a href="#" class="' +
        x_pref('UIBNextGroupBy', 'parentid') +
        '" fn="NIRV.setPref" p1="UIBNextGroupBy" p2="parentid" p3="true">group by project</a></li>';
      html +=
        '         <li><a href="#" class="' +
        x_pref('UIBNextGroupBy', '') +
        '" fn="NIRV.setPref" p1="UIBNextGroupBy" p2="" p3="true">sequential next actions</a></li>';
      if (x_pref('UIBNextGroupBy', 'parentid')) {
        html += '         <li><span class="divider"></span></li>';
        html += '         <li><a href="#" class="submenu">project actions</a>';
        html += '         <ul style="width:80px;">';
        html += '         <li><span class="label">Show</span></li>';
        html +=
          '         <li><a href="#" fn="NIRV.setPref" p1="UIBProjectNextActionsTopN" p2="1" p3="true" class="' +
          x_pref('UIBProjectNextActionsTopN', '1') +
          '">top 1</a></li>';
        html +=
          '         <li><a href="#" fn="NIRV.setPref" p1="UIBProjectNextActionsTopN" p2="2" p3="true" class="' +
          x_pref('UIBProjectNextActionsTopN', '2') +
          '">top 2</a></li>';
        html +=
          '         <li><a href="#" fn="NIRV.setPref" p1="UIBProjectNextActionsTopN" p2="3" p3="true" class="' +
          x_pref('UIBProjectNextActionsTopN', '3') +
          '">top 3</a></li>';
        html +=
          '         <li><a href="#" fn="NIRV.setPref" p1="UIBProjectNextActionsTopN" p2="4" p3="true" class="' +
          x_pref('UIBProjectNextActionsTopN', '4') +
          '">top 4</a></li>';
        html +=
          '         <li><a href="#" fn="NIRV.setPref" p1="UIBProjectNextActionsTopN" p2="5" p3="true" class="' +
          x_pref('UIBProjectNextActionsTopN', '5') +
          '">top 5</a></li>';
        html +=
          '         <li><a href="#" fn="NIRV.setPref" p1="UIBProjectNextActionsTopN" p2="6" p3="true" class="' +
          x_pref('UIBProjectNextActionsTopN', '6') +
          '">top 6</a></li>';
        html +=
          '         <li><a href="#" fn="NIRV.setPref" p1="UIBProjectNextActionsTopN" p2="7" p3="true" class="' +
          x_pref('UIBProjectNextActionsTopN', '7') +
          '">top 7</a></li>';
        html +=
          '         <li><a href="#" fn="NIRV.setPref" p1="UIBProjectNextActionsTopN" p2="8" p3="true" class="' +
          x_pref('UIBProjectNextActionsTopN', '8') +
          '">top 8</a></li>';
        html +=
          '         <li><a href="#" fn="NIRV.setPref" p1="UIBProjectNextActionsTopN" p2="9" p3="true" class="' +
          x_pref('UIBProjectNextActionsTopN', '9') +
          '">top 9</a></li>';
        html +=
          '         <li><a href="#" fn="NIRV.setPref" p1="UIBProjectNextActionsTopN" p2="9999" p3="true" class="' +
          x_pref('UIBProjectNextActionsTopN', '9999') +
          '">all</a></li>';
        html += '         </ul>';
        html += '         </li>';
      }
    }
    if (NIRV.currentview == 'projects') {
      NIRV.cmenuViewOptionsProjects(e);
    }
    if (NIRV.currentview == 'reflists') {
      NIRV.cmenuViewOptionsReflists(e);
    }
    html += '      </ul>';
    html += '   </li>';
    html += '</ul>';
    return html;
  };
  $('body').on('click', '#cbar li.options', function (e) {
    if (NIRV.currentview == 'next') {
      NIRV.cmenuViewOptionsNext(e);
    }
    if (NIRV.currentview == 'focus') {
      NIRV.cmenuViewOptionsFocus(e);
    }
    if (NIRV.currentview == 'projects') {
      NIRV.cmenuViewOptionsProjects(e);
    }
    if (NIRV.currentview == 'reflists') {
      NIRV.cmenuViewOptionsReflists(e);
    }
    if (NIRV.currentview == 'logbook') {
      NIRV.cmenuLogbook(e);
    }
    if (NIRV.currentview == 'trash') {
      NIRV.cmenuTrash(e);
    }
  });

  // ------------------------
  // CMENU: ETIME
  NIRV.renderEtimeMenu = function () {
    var etimes = {};
    var task = {};
    $('#main .task').each(function () {
      task = NIRV.tasks[$(this).attr('taskid')];
      etimes[task.etime] = true;
    });
    var x_etime = function (value) {
      return NIRV.currentfilters.etime == value ? 'x' : '';
    };
    var html = '';
    html += '<ul class="sf-menu">';
    html += '<li>';
    html += '<a href="#" class="cmenu submenu">&nbsp;</a>';
    html += '<ul>';
    html +=
      '<li><span class="label">Only show items that can be completed in...</span></li>';
    if (etimes['5'])
      html +=
        '<li><a href="#" class="toggle ' +
        x_etime('5') +
        '" fn="NIRV.setFilter" p1="etime" p2="5">5 minutes or less</a></li>';
    if (etimes['10'])
      html +=
        '<li><a href="#" class="toggle ' +
        x_etime('10') +
        '" fn="NIRV.setFilter" p1="etime" p2="10">10 minutes or less</a></li>';
    if (etimes['15'])
      html +=
        '<li><a href="#" class="toggle ' +
        x_etime('15') +
        '" fn="NIRV.setFilter" p1="etime" p2="15">15 minutes or less</a></li>';
    if (etimes['30'])
      html +=
        '<li><a href="#" class="toggle ' +
        x_etime('30') +
        '" fn="NIRV.setFilter" p1="etime" p2="30">30 minutes or less</a></li>';
    if (etimes['45'])
      html +=
        '<li><a href="#" class="toggle ' +
        x_etime('45') +
        '" fn="NIRV.setFilter" p1="etime" p2="45">45 minutes or less</a></li>';
    if (etimes['60'])
      html +=
        '<li><a href="#" class="toggle ' +
        x_etime('60') +
        '" fn="NIRV.setFilter" p1="etime" p2="60">1 hour or less</a></li>';
    if (etimes['120'])
      html +=
        '<li><a href="#" class="toggle ' +
        x_etime('120') +
        '" fn="NIRV.setFilter" p1="etime" p2="120">2 hours or less</a></li>';
    if (etimes['180'])
      html +=
        '<li><a href="#" class="toggle ' +
        x_etime('180') +
        '" fn="NIRV.setFilter" p1="etime" p2="180">3 hours or less</a></li>';
    if (etimes['240'])
      html +=
        '<li><a href="#" class="toggle ' +
        x_etime('240') +
        '" fn="NIRV.setFilter" p1="etime" p2="240">4 hours or less</a></li>';
    if (etimes['360'])
      html +=
        '<li><a href="#" class="toggle ' +
        x_etime('360') +
        '" fn="NIRV.setFilter" p1="etime" p2="360">6 hours or less</a></li>';
    if (etimes['480'])
      html +=
        '<li><a href="#" class="toggle ' +
        x_etime('480') +
        '" fn="NIRV.setFilter" p1="etime" p2="480">8 hours or less</a></li>';
    if (etimes['600'])
      html +=
        '<li><a href="#" class="toggle ' +
        x_etime('600') +
        '" fn="NIRV.setFilter" p1="etime" p2="600">whoa nelly!</a></li>';
    html += '         <li><span class="divider"></span></li>';
    if (etimes['0'])
      html +=
        '<li><a href="#" fn="NIRV.setFilter" class="' +
        x_etime('0') +
        '" p1="etime" p2="0">show unmarked</a></li>';
    if (NIRV.currentfilters.etime != -1)
      html +=
        '<li><a href="#" fn="NIRV.setFilter" p1="etime" p2="-1">show all</a></li>';
    html += '</ul>';
    html += '</li>';
    html += '</ul>';
    return html;
  };
  $('body').on('click', '#cbar li.etime', function (e) {
    if ($('#cmenu').offset().top > 0) {
      $('html').trigger('click');
    } else {
      var html = NIRV.renderEtimeMenu();
      $('#cmenu').html(html);
      $('#cmenu').css(
        'top',
        $(this).position().top + 65 - $(window).scrollTop()
      );
      $('#cmenu').css('left', $(this).offset().left + 155);
      $('#cmenu').css('z-index', 2000);
    }
  });

  // ------------------------
  // CMENU: ENERGY
  NIRV.renderEnergyMenu = function () {
    var energys = {};
    var task = {};
    $('#main .task').each(function () {
      task = NIRV.tasks[$(this).attr('taskid')];
      energys[task.energy] = true;
    });
    var x_energy = function (value) {
      return NIRV.currentfilters.energy == value ? 'x' : '';
    };
    var html = '';
    html += '<ul class="sf-menu">';
    html += '   <li>';
    html += '      <a href="#" class="cmenu submenu">&nbsp;</a>';
    html += '      <ul>';
    html +=
      '         <li><span class="label">Filter items based on your current energy level...</span></li>';
    if (energys['1'])
      html +=
        '         <li><a href="#" class="' +
        x_energy('1') +
        '" fn="NIRV.setFilter" p1="energy" p2="1">•&nbsp;&nbsp;&nbsp;&nbsp;low energy</a></li>';
    if (energys['2'])
      html +=
        '         <li><a href="#" class="' +
        x_energy('2') +
        '" fn="NIRV.setFilter" p1="energy" p2="2">••&nbsp;&nbsp;&nbsp;medium energy</a></li>';
    if (energys['3'])
      html +=
        '         <li><a href="#" class="' +
        x_energy('3') +
        '" fn="NIRV.setFilter" p1="energy" p2="3">•••&nbsp;&nbsp;high energy</a></li>';
    html += '         <li><span class="divider"></span></li>';
    if (energys['0'])
      html +=
        '         <li><a href="#" class="' +
        x_energy('0') +
        '" fn="NIRV.setFilter" p1="energy" p2="0">show unmarked</a></li>';
    if (NIRV.currentfilters.energy != -1)
      html +=
        '         <li><a href="#" fn="NIRV.setFilter" p1="energy" p2="-1">show all</a></li>';
    html += '      </ul>';
    html += '   </li>';
    html += '</ul>';
    return html;
  };
  $('body').on('click', '#cbar li.energy', function (e) {
    if ($('#cmenu').offset().top > 0) {
      $('html').trigger('click');
    } else {
      var html = NIRV.renderEnergyMenu();
      $('#cmenu').html(html);
      $('#cmenu').css(
        'top',
        $(this).position().top + 65 - $(window).scrollTop()
      );
      $('#cmenu').css('left', $(this).offset().left + 121);
      $('#cmenu').css('z-index', 2000);
    }
  });
});
