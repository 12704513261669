// LISTEN FOR MOUSE EVENTS
$('html')
  .bind('contextmenu', function (e) {
    NIRV.contextmenu(e);
  })
  .bind('dblclick', function (e) {
    NIRV.dblclick(e);
  })
  .bind('click', function (e) {
    NIRV.click(e);
    // prevent "scroll to top"
    if ($(e.target).attr('href') == '#') {
      e.preventDefault();
    }
  })
  .bind('mousedown', function (e) {
    NIRV.mouseIsDown = true;
    NIRV.mousedown_(e);
  })
  .bind('mouseup', function (e) {
    NIRV.mouseIsDown = false;
  });

$('html').mousemove(function (e) {
  NIRV.mouseX = e.pageX;
  NIRV.mouseY = e.pageY;
  NIRV.mouseE = e;
});

NIRV.mousedown_ = function (e) {
  if (e.button != 0) {
    return false;
  } // 0 is left-click -- so this allows for right-click

  if (
    $(e.target).hasClass('check') ||
    $(e.target).hasClass('pauserecurring') ||
    $(e.target).hasClass('star') ||
    $(e.target).hasClass('edittask') ||
    $(e.target).hasClass('parentname') ||
    $(e.target).hasClass('x_note') ||
    $(e.target).hasClass('nosave') ||
    $(e.target).hasClass('subtask') ||
    $(e.target).hasClass('toggle') ||
    $(e.target).hasClass('count') ||
    $(e.target).hasClass('more') ||
    $(e.target).hasClass('less') ||
    $(e.target).hasClass('newtask')
  ) {
    return false;
  }

  if (!$(e.target).hasClass('rapidentry')) {
    $('#main input.rapidentry').blur();
  }

  NIRV.refreshMultiselectDataPos();

  if ($(e.target).parents().hasClass('task')) {
    var $li = $(e.target).closest('#main li.task');

    if ($li.hasClass('edit')) return;

    if (e.shiftKey && NIRV.pos_selected != false) {
      var select_a = parseInt(NIRV.pos_selected.attr('data-pos'), 10);
      var select_b = parseInt($li.attr('data-pos'), 10);

      if (select_a < select_b) {
        DEBUG && console.log(select_a + ' » ' + select_b);
        $('#main li.task:visible').each(function () {
          if (
            parseInt($(this).attr('data-pos'), 10) >= select_a &&
            parseInt($(this).attr('data-pos'), 10) <= select_b
          ) {
            $(this).addClass('ui-selected');
          }
        });
      } else {
        DEBUG && console.log(select_b + ' » ' + select_a);
        $('#main li.task:visible').each(function () {
          if (
            parseInt($(this).attr('data-pos'), 10) >= select_b &&
            parseInt($(this).attr('data-pos'), 10) <= select_a
          ) {
            $(this).addClass('ui-selected');
          }
        });
      }
      NIRV.draggable();
    } else if (e.metaKey || e.ctrlKey) {
      if ($li.hasClass('ui-selected')) {
        $li.removeClass('ui-selected');
      } else {
        $li.addClass('ui-selected');
      }
      NIRV.draggable();
    } else if ($li.hasClass('ui-selected')) {
      // skip
    } else {
      $('.ui-selected').removeClass('ui-selected');
      $li.addClass('ui-selected');
      NIRV.pos_selected = $li;
      NIRV.draggable();
    }
  } else if ($(e.target).parents('#cmenu').hasClass('xcmenu')) {
    // skip
  } else if ($(e.target).parents('.ui-dialog').hasClass('ui-dialog')) {
    // skip
  } else if (e.ctrlKey || e.metaKey) {
    return false; // allow multi-lasso
  } else {
    $('.ui-selected').removeClass('ui-selected');
    NIRV.pos_selected = false;
    NIRV.draggable();
  }
};

NIRV.click = function (e) {
  // CLICKED OUTSIDE CMENU
  if (!$(e.target).closest('.xcmenu').hasClass('xcmenu')) {
    $('#cmenu').css('top', '-9999em');
    $('*').removeClass('highlight');
  }

  // CLICKED ON TASK.REPEAT
  if ($(e.target).hasClass('pauserecurring')) {
    var $task = $(e.target).closest('li.task');
    var task = NIRV.tasks[$task.attr('taskid')];
    var recurring = JSON.parse(task.recurring);
    if ($task.hasClass('paused')) {
      recurring.paused = false;
    } else {
      recurring.paused = true;
    }
    task.set('recurring', JSON.stringify(recurring));
    task.save();
    // task.redraw();
    NIRV.refreshAll();
  }

  // CLICKED ON TASK.CHECK
  if ($(e.target).hasClass('check')) {
    DEBUG && console.log('check');

    var now = time();
    var $task = $(e.target).closest('li.task');
    if ($task.hasClass('edit')) return;

    var task = NIRV.tasks[$task.attr('taskid')];

    if (task.type == NIRV.CONST.PROJECT && task.completed == 0) {
      var foundincomplete = false;
      for (var i in NIRV.tasks) {
        if (
          NIRV.tasks[i] != undefined &&
          NIRV.tasks[i].parentid == task.id &&
          NIRV.tasks[i].completed == 0
        ) {
          foundincomplete = true;
        }
      }
      if (foundincomplete) {
        for (var i in NIRV.tasks) {
          if (
            NIRV.tasks[i] != undefined &&
            NIRV.tasks[i].parentid == task.id &&
            NIRV.tasks[i].completed == 0
          ) {
            NIRV.tasks[i].set('completed', now);
            NIRV.tasks[i].set('cancelled', 0);
            if (
              NIRV.prefs.UICollectCompleted &&
              NIRV.prefs.UICollectCompleted.value == 'immediately'
            ) {
              NIRV.tasks[i].set('state', NIRV.CONST.LOGGED);
            }
          }
        }
      }
    }

    // alt pressed, handle cancelled
    if (e.altKey) {
      if (task.completed != 0 && task.cancelled == 0) {
        task.set('cancelled', 1);
      } else if (task.completed != 0 && task.cancelled != 0) {
        task.set('cancelled', 0);
      } else {
        task.set('completed', now);
        task.set('cancelled', 1);
      }
      if (
        NIRV.prefs.UICollectCompleted &&
        NIRV.prefs.UICollectCompleted.value == 'immediately'
      ) {
        task.set('state', NIRV.CONST.LOGGED);
      }
    } else {
      if (task.completed != 0) {
        task.set('completed', 0);
        task.set('cancelled', 0);
        if (task.parentid != '') {
          if (NIRV.tasks[task.parentid].completed != 0) {
            NIRV.tasks[task.parentid].set('completed', 0);
            NIRV.tasks[task.parentid].set('cancelled', 0);
          }
        }
      } else {
        task.__viewtoggle__ = 'collapse';
        task.set('completed', now);
        task.set('cancelled', 0);
        if (
          NIRV.prefs.UICollectCompleted &&
          NIRV.prefs.UICollectCompleted.value == 'immediately'
        ) {
          task.set('state', NIRV.CONST.LOGGED);
        }
      }
    }

    if (task.parentid != '' && task.state == NIRV.CONST.NEXT) {
      for (var i in NIRV.tasks) {
        if (
          NIRV.tasks[i] != undefined &&
          NIRV.tasks[i].parentid == task.parentid &&
          task.state == NIRV.CONST.NEXT
        ) {
          NIRV.tasks[i].set('seq', task.seq);
        }
      }
    }

    if (
      task.state == NIRV.CONST.LOGGED &&
      task.completed == 0 &&
      task.cancelled == 0
    ) {
      if (task.parentid != '') {
        task.set('state', NIRV.CONST.NEXT);
      } else {
        task.set('state', NIRV.CONST.INBOX);
        task.set('seq', time());
      }
    }

    // task.redraw();
    NIRV.save();
    NIRV.refreshAll();
  }

  // CLICKED ON TASK.STAR
  if ($(e.target).hasClass('star')) {
    DEBUG && console.log('star');
    var $task = $(e.target).closest('li.task');
    if ($task.hasClass('edit')) {
      if ($task.hasClass('focus')) {
        $task.removeClass('focus');
        $task.find('input[name=seqt]').val(0);
      } else {
        $task.addClass('focus');
        if ($task.find('input[name=seqt]').val() == 0) {
          $task.find('input[name=seqt]').val(time());
        } else {
          $task.find('input[name=seqt]').val(0);
        }
      }
    } else {
      var task = NIRV.tasks[$task.attr('taskid')];
      if (task.seqt == 0) {
        task.set('seqt', time());
      } else {
        if (task.duedate != '' && task.duedate <= today()) {
          NIRV.alert('Due date prevents removing from Focus');
        } else {
          task.set('seqt', 0);
        }
      }
      task.save();
      // task.redraw();
      NIRV.refreshAll();
    }
  }

  // CLICKED ON TASK.NITEMS
  if ($(e.target).hasClass('nitems')) {
    // NIRV.viewproject($(e.target).closest('li.task').attr('taskid'));
    location.hash = '#project/' + $(e.target).closest('li.task').attr('taskid');
  }

  // CLICKED ON PARALLEL/SEQUENTIAL TOGGLE
  // if ($(e.target).hasClass('ps')) {
  //   DEBUG && console.log('ps');
  //   var $task = $(e.target).closest('li.task');
  //   var task = NIRV.tasks[$task.attr('taskid')];
  //   if (task.ps == NIRV.CONST.PARALLEL) {
  //     task.set('ps', NIRV.CONST.SEQUENTIAL);
  //   } else {
  //     task.set('ps', NIRV.CONST.PARALLEL);
  //   }
  //   NIRV.refreshCurrentview();
  // }

  // CLICKED ON TASK.NAME
  if ($(e.target).hasClass('edittask')) {
    NIRV.autosave = false;
    $('#cbar .cancel').click();
    $('#main .cancel').click();
    NIRV.highlight_tasks(false);
    var $li = $(e.target).closest('li.task');
    NIRV.taskedit = cloneObject(NIRV.tasks[$li.attr('taskid')]);
    $li.replaceWith(NIRV.taskedit.renderedit());
    $li = $('li.task.edit'); // recapture
    $li.hide().fadeIn(100);
    if ($li.length > 0) {
      NIRV.scrollLiIntoView($li);
    }
    NIRV.reflow();
    NIRV.placeholders_taskedit();
  }

  // CLICKED ON TASK.PARENTNAME
  if ($(e.target).hasClass('parentname')) {
    location.hash = '#project/' + $(e.target).attr('pid');
  }

  // CLICKED ON TASK.PLUSTASK
  if ($(e.target).hasClass('plustask')) {
    NIRV.promptNewTask();
  }

  // CLICKED ON TASK.TAG
  if ($(e.target).hasClass('tag')) {
    if ($(e.target).parent().is('span.tags')) {
      $(e.target).closest('li.task').find('span.name').click();
      setTimeout(function () {
        $('input.maininput').focus();
      }, 100);
    }
  }

  // CLICKED ON TASK.CMENU
  if ($(e.target).hasClass('cmenu_task')) {
    NIRV.cmenuTask(e);
  }

  // CLICKED ON TASKEDIT.TAGS
  if ($(e.target).hasClass('cmenu_tags')) {
    NIRV.cmenuTags(e);
  }

  // CLICKED ON TASKEDIT.PARENT
  if ($(e.target).hasClass('cmenu_parent')) {
    NIRV.cmenuParent(e);
  }

  // CLICKED ON TASKEDIT.STATE
  if ($(e.target).hasClass('cmenu_state')) {
    NIRV.cmenuState(e);
  }

  // CLICKED ON TASKEDIT.ETIME
  if ($(e.target).hasClass('cmenu_ps')) {
    NIRV.cmenuPS(e);
  }

  // CLICKED ON TASKEDIT.ETIME
  if ($(e.target).hasClass('cmenu_etime')) {
    NIRV.cmenuEtime(e);
  }

  // CLICKED ON TASKEDIT.ENERGY
  if ($(e.target).hasClass('cmenu_energy')) {
    NIRV.cmenuEnergy(e);
  }

  // CLICKED ON TASKEDIT.WAITINGFOR
  if ($(e.target).hasClass('cmenu_waitingfor')) {
    NIRV.cmenuWaitingfor(e);
  }

  // CLICKED ON TASKEDIT.STARTDATE
  if ($(e.target).hasClass('cmenu_startdate')) {
    NIRV.promptDate('taskedit', 'startdate');
  }

  // CLICKED ON TASKEDIT.DUEDATE
  if ($(e.target).hasClass('cmenu_duedate')) {
    NIRV.promptDate('taskedit', 'duedate');
  }

  // CLICKED ON TASKEDIT.REPEATING
  if ($(e.target).hasClass('cmenu_repeating')) {
    NIRV.promptRecurring('taskedit');
  }

  // CLICKED ON AOR MENU
  if ($(e.target).hasClass('x_currentarea')) {
    NIRV.x_currentarea(e);
  }

  // CLICKED ON WAITINGFOR.CONTACTS
  if ($(e.target).hasClass('cmenu_contacts')) {
    // $('input').blur();
    NIRV.cmenuContacts(e);
  }

  // CLICKED ON TAGCLOUD > TAG
  if (
    $(e.target).hasClass('tag') &&
    $(e.target).parents().hasClass('tagcloud')
  ) {
    if ($(e.target).hasClass('all')) {
      if (location.hash == '#tag/__ALL__') {
        NIRV.viewtag('__ALL__');
      } else {
        location.hash = '#tag/__ALL__';
      }
    } else if ($(e.target).hasClass('none')) {
      if (location.hash == '#tag/__ALL__') {
        NIRV.viewtag('__NONE__');
      } else {
        location.hash = '#tag/__NONE__';
      }
    } else {
      let hashtag = '#tag/' + encodeURIComponent($(e.target).text());
      if (location.hash == hashtag) {
        NIRV.viewtag($(e.target).text());
      } else {
        location.hash = hashtag;
      }
    }
  }

  // CLICKED ON TAGLIB TAG CMENU
  if ($(e.target).hasClass('taglib_cmenu')) {
    NIRV.cmenuTaglib(e);
  }
};

NIRV.dblclick = function (e) {
  // DOUBLE CLICKED ON TASK
  if ($(e.target).closest('li.task.render').parent().hasClass('tasks')) {
    if (!$(e.target).hasClass('infinity')) {
      $(e.target).closest('li.task.render').find('span.name').click();
    }
  }
};

NIRV.contextmenu = function (e) {
  if ($(e.target).hasClass('nosave')) {
    return;
  }

  // NORTH BUTTONS
  if ($(e.target).closest('.x_currentarea').hasClass('x_currentarea')) {
    $('#north .x_currentarea.xcmenu').click();
  }

  if ($(e.target).closest('.button').hasClass('newtask')) {
    $('#north a.newtask.multibutton .xcmenu').click();
  }

  if ($(e.target).closest('.button').hasClass('settingsmenu')) {
    $('#north a.settingsmenu.xcmenu').click();
  }

  // RIGHT-CLICKED ON TAGLIB TAG
  if (
    $(e.target).closest('li').hasClass('tag') &&
    $(e.target).closest('li').hasClass('edit')
  ) {
    NIRV.cmenuTaglib(e);
  }

  // RIGHT-CLICKED ON CBAR TAG
  if (
    $(e.target).closest('li').hasClass('tag') &&
    $(e.target).closest('ul').hasClass('filters')
  ) {
    NIRV.cmenuCbarTag(e);
  }

  // RIGHT-CLICKED ON TASK TAG
  if (
    $(e.target).hasClass('tag') &&
    $(e.target).closest('li').hasClass('task')
  ) {
    NIRV.cmenuMainTaskTag(e);
  }

  // RIGHT CLICKED ON EAST LI.FOCUS
  if ($(e.target).closest('a.view').parent().hasClass('focus')) {
    DEBUG && console.log('right-click: focus');
    NIRV.cmenuViewOptionsFocus(e);
    e.preventDefault();
  }

  // RIGHT CLICKED ON EAST LI.NEXT
  if ($(e.target).closest('a.view').parent().hasClass('next')) {
    DEBUG && console.log('right-click: next');
    NIRV.cmenuViewOptionsNext(e);
    e.preventDefault();
  }

  // RIGHT CLICKED ON EAST LI.PROJECTS
  if ($(e.target).closest('h3').hasClass('projects')) {
    DEBUG && console.log('right-click: projects');
    NIRV.cmenuViewOptionsProjects(e);
    e.preventDefault();
  }

  // RIGHT CLICKED ON EAST LI.PROJECT
  if ($(e.target).closest('a.view').parent().hasClass('project')) {
    DEBUG &&
      console.log(
        'right-click: project ' + $(e.target).closest('a.view').attr('pid')
      );
    NIRV.cmenuTask(e);
    e.preventDefault();
  }

  // RIGHT CLICKED ON EAST LI.REFLISTS
  if ($(e.target).closest('h3').hasClass('reflists')) {
    DEBUG && console.log('right-click: reflists');
    NIRV.cmenuViewOptionsReflists(e);
    e.preventDefault();
  }

  // RIGHT CLICKED ON EAST LI.REFLIST
  if ($(e.target).closest('a.view').parent().hasClass('reflist')) {
    DEBUG &&
      console.log(
        'right-click: reflist ' + $(e.target).closest('a.view').attr('lid')
      );
    NIRV.cmenuTask(e);
    e.preventDefault();
  }

  // RIGHT CLICKED ON EAST LI.LOGBOOK
  if ($(e.target).closest('a.view').parent().hasClass('logbook')) {
    DEBUG && console.log('right-click: logbook');
    NIRV.cmenuLogbook(e);
    e.preventDefault();
  }

  // RIGHT CLICKED ON EAST LI.TRASH
  if ($(e.target).closest('a.view').parent().hasClass('trash')) {
    DEBUG && console.log('right-click: trash');
    NIRV.cmenuTrash(e);
    e.preventDefault();
  }

  // // RIGHT CLICKED ON CBAR FILTERS
  // if ($(e.target).closest('div').hasClass('filters')) {
  //     // NIRV.cmenuTrash(e);
  //     DEBUG && console.log('right-click: #cbar .filters');
  //     e.preventDefault();
  // }

  // RIGHT CLICKED ON TASK
  if ($(e.target).closest('li.task').parent().hasClass('tasks')) {
    if (
      $(e.target).closest('li.task').hasClass('edit') ||
      $(e.target).hasClass('tag')
    ) {
      // do nothing
    } else {
      if ($(e.target).closest('li.task').hasClass('ui-selected')) {
        // skip
      } else {
        $('.ui-selected').removeClass('ui-selected');
        $(e.target).closest('li.task').addClass('ui-selected');
      }
      NIRV.cmenuTask(e);
    }
  }

  // ALLOW RIGHT-CLICK IN INPUT FIELDS AND TEXTAREA ONLY (to allow native browser spelling correction)
  if (!$(e.target).is('input') && !$(e.target).is('textarea')) {
    e.preventDefault();
  }
};
