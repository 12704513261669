NIRV.Tag = {
  key: '',
  _key: 0,
  type: NIRV.CONST.CONTEXT,
  _type: 0,
  email: '',
  _email: 0,
  color: '',
  _color: 0,
  meta: '',
  _meta: 0,
  __stale__: false,
  __isNew__: false,
  set: function set(key, value, timestamp) {
    timestamp = timestamp || time();
    if (timestamp == NIRV.ajax_initiated) {
      timestamp = timestamp + 1;
    }
    if (this[key] != value) {
      this[key] = value;
      if (key != undefined && key[0] != '_') {
        this['_' + key] = timestamp;
      }
      this.__stale__ = true;
      this.redraw();
    }
    return this;
  },
  clone: function clone(init) {
    init = init || {};
    var o = cloneObject(this);
    for (var i in init) {
      o[i] = init[i];
    }
    return o;
  },
  renderedit: function Tag_renderedit() {
    var placeholder = 'new tag';
    var aclass = [];
    if (this.type == NIRV.CONST.AREA) {
      aclass.push('area');
      placeholder = 'area  (example: personal)';
    } else if (this.type == NIRV.CONST.CONTACT) {
      aclass.push('contact');
      placeholder = 'contact  (example: jennifer)';
    } else if (this.type == NIRV.CONST.CONTEXT) {
      aclass.push('context');
      placeholder = 'context  (example: errand)';
    }
    if (this.color) {
      aclass.push('color--' + this.color);
    }
    var html = '';
    html +=
      '<li class="tag tagedit ' +
      aclass.join(' ') +
      '" key="' +
      this.key +
      '">';
    html += '<span class="icon"></span>';
    html += ' <form autocomplete="off" method="post" action="#">';
    html +=
      '<input type="hidden" name="initial_key" value="' + this.key + '" />';
    html += '<input type="hidden" name="type" value="' + this.type + '" />';
    html +=
      '<input class="text key" type="text" name="key" placeholder="' +
      placeholder +
      '" value="' +
      this.key +
      '" />';
    // if (this.type == NIRV.CONST.CONTACT) {
    // html += '  <input class="text email" type="text" name="email" placeholder="email address (optional)" value="' + this.email + '" /><br />';
    // }
    // else {
    html +=
      '<input class="hidden email" type="hidden" name="email" value="' +
      this.email +
      '" /><br />';
    // }
    html += '</form>';
    html += ' <div>';
    html +=
      '  <button class="save awesome small primary">Save Changes</button>';
    html += '  <button class="cancel awesome small">Cancel</button>';
    html += ' </div>';
    html += '</li>';
    return html;
  },
  render: function Tag_render() {
    var aclass = [];
    if (this.type == NIRV.CONST.AREA) {
      aclass.push('area');
    } else if (this.type == NIRV.CONST.CONTACT) {
      aclass.push('contact');
    } else if (this.type == NIRV.CONST.CONTEXT) {
      aclass.push('context');
    }
    if (this.color) {
      aclass.push('color--' + this.color);
    }
    var html = '';
    html +=
      '<li class="tag edit ' + aclass.join(' ') + '" key="' + this.key + '">';
    html += '<span class="icon"></span>';
    html += '<span class="name-email">';
    html += '<span class="name">' + this.key + '</span>';
    // if (this.type == NIRV.CONST.CONTACT) {
    //     html += '<span class="email">' + this.email + '</span>';
    // }
    html += '</span>';
    html += '<span class="cmenu taglib_cmenu"></span>';
    html += '</li>';
    return html;
  },
  redraw: function Tag_redraw() {
    var html = this.render();
    try {
      $('li.tag[key="' + this.key + '"]').replaceWith(html);
    } catch (e) {
      console.error(e);
    }
    return this;
  },
  save: function Tag_save() {
    DEBUG && console.log('  Tag.save() ' + this.key);

    if (this.__stale__) {
      var data = {
        method: 'tag.save',
        key: this.key,
        _key: this._key,
        type: this.type,
        _type: this._type,
        email: this.email,
        _email: this._email,
        color: this.color,
        _color: this._color,
        meta: this.meta,
        _meta: this._meta,
        deleted: this.deleted,
        _deleted: this._deleted,
      };

      var ldata = cloneObject(data);
      ldata.__stale__ = this.__stale__;
      ldata.__isNew__ = this.__isNew__;
      delete ldata.method;
      NIRV.storage.setItem('tag.' + ldata.key, JSON.stringify(ldata));

      return data;
    }
  },
};
