function time() {
  var date = new Date();
  return parseInt(date.getTime().toString().slice(0, 10));
}

function unixtime() {
  var date = new Date();
  return parseInt(date.getTime().toString().slice(0, 10));
}

function gmtoffset() {
  // courtesy http://www.24hourapps.com/2009/03/javascript-gmtutc-timezone-offset.html
  var today = new Date();
  var offset = -(today.getTimezoneOffset() / 60);
  return offset;
}

function uuid4() {
  // CREDIT: broofa http://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid-in-javascript
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    .replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    })
    .toUpperCase();
}

function urlbuilder(baseurl, params, hash) {
  baseurl = baseurl || '';
  params = params || {};
  hash = hash || '';
  var querystring = '';
  for (var i in params) {
    querystring += i + '=' + encodeURIComponent(params[i]) + '&';
  }
  var url = baseurl;
  if (querystring) {
    url += '?' + querystring.slice(0, querystring.length - 1);
  }
  if (hash) {
    url += hash;
  }
  return url;
}

// CREDIT: http://javascript.crockford.com/prototypal.html
if (typeof Object.create !== 'function') {
  Object.create = function (o) {
    function F() {}
    F.prototype = o;
    return new F();
  };
}

// CREDIT http://stackoverflow.com/questions/122102/what-is-the-most-efficent-way-to-clone-a-javascript-object/1891377#1891377
function cloneObject(obj) {
  var clone = {};
  for (var i in obj) {
    if (typeof obj[i] == 'object') clone[i] = cloneObject(obj[i]);
    else clone[i] = obj[i];
  }
  return clone;
}

// we only want props that have changed `since`
function slimObject(incoming, since) {
  since = parseInt(since, 10) || 0;

  let data = cloneObject(incoming);
  let length_before = JSON.stringify(data).length;
  // console.log('----- slimObject since', since, '-----');
  // console.log('before', length_before, data);

  let propsToSupress = [];
  Object.keys(data).forEach((key) => {
    if (key[0] == '_' && key[1] != '_') {
      if (parseInt(data[key], 10) < since) {
        // console.log(' key', key, since - parseInt(data[key], 10));
        propsToSupress.push(key);
        propsToSupress.push(key.substring(1));
      }
    }
  });
  propsToSupress.forEach((key) => {
    delete data[key];
  });

  let length_after = JSON.stringify(data).length;
  // console.log(' after', length_after, data);
  console.log(
    'slimObject reduced payload by',
    Math.floor(((length_before - length_after) / length_before) * 100),
    'percent',
    data
  );
  return data;
}

if (!String.prototype.trim) {
  String.prototype.trim = function () {
    return this.replace(/^\s+|\s+$/g, '');
  };
}

// CREDIT: http://stackoverflow.com/questions/3629183/why-doesnt-indexof-work-on-an-array-ie8
// ... originally from https://developer.mozilla.org/en/JavaScript/Reference/Global_Objects/Array/indexOf#Compatibility
if (!Array.prototype.indexOf) {
  Array.prototype.indexOf = function (elt /*, from*/) {
    var len = this.length >>> 0;
    var from = Number(arguments[1]) || 0;
    from = from < 0 ? Math.ceil(from) : Math.floor(from);
    if (from < 0) from += len;

    for (; from < len; from++) {
      if (from in this && this[from] === elt) return from;
    }
    return -1;
  };
}

Array.prototype.unique = function () {
  var length = this.length;
  var a = [];
  var i, j; // iter
  for (i = 0; i < length; i++) {
    for (j = i + 1; j < length; j++) {
      if (this[i] === this[j]) j = ++i;
    }
    a.push(this[i]);
  }
  return a;
};

String.prototype.replaceAll = function (search, replacement) {
  var target = this;
  return target.replace(new RegExp(search, 'g'), replacement);
};

var unique = function (arr) {
  var length = arr.length;
  var a = [];
  var i, j; // iter
  for (i = 0; i < length; i++) {
    for (j = i + 1; j < length; j++) {
      if (arr[i] === arr[j]) j = ++i;
    }
    a.push(arr[i]);
  }
  return a;
};

var isort = function (x, y) {
  var a = String(x).toUpperCase();
  var b = String(y).toUpperCase();
  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
};

function padleft(s, l, c) {
  if (s != undefined) {
    return new Array(l - s.length + 1).join(c || '0') + s;
  } else {
    return s;
  }
}

function nescape(s) {
  s = s.replace(/"/g, '&quot;');
  s = s.replace(/</g, '&lt;');
  s = s.replace(/>/g, '&gt;');
  return s;
}

function htmlentities(str) {
  return String(str)
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;');
}

function nl2br(s) {
  return s.replace(/\n/g, '<br />');
}

function br2nl(thestring) {
  return thestring.replace(/<br>/gi, '\n');
}

function subtaskr(s) {
  var lines = s.trim().split('\n');
  for (var l = 0; l < lines.length; l++) {
    lines[l] = lines[l].trim();
    if (lines[l].slice(0, 2) == '- ') {
      lines[l] = lines[l].replace(
        '- ',
        '<span class="nosave subtask"><span>-</span></span> '
      );
    }
    if (lines[l].slice(0, 2) == 'x ') {
      lines[l] = lines[l].replace(
        'x ',
        '<span class="nosave subtask checked"><span>x</span></span> '
      );
    }
  }
  return lines.join('\n');
}

function smartparse(s) {
  var now = time();

  var task = {};
  var seq = 0;
  var name = '';
  var tags = '';
  var energy = 0;
  var etime = 0;
  var waitingfor = '';
  var startdate = '';
  var duedate = '';

  var reserved = [
    '#inbox',
    '#next',
    '#later',
    '#waiting',
    '#scheduled',
    '#someday',
    '#project',
    '#focus',
    '#due',
    '#start',
    '#e1',
    '#e2',
    '#e3',
    '#1h',
    '#2h',
    '#3h',
    '#4h',
    '#5h',
    '#6h',
    '#7h',
    '#8h',
    '#5m',
    '#10m',
    '#15m',
    '#20m',
    '#25m',
    '#30m',
    '#35m',
    '#40m',
    '#45m',
    '#50m',
    '#55m',
    '#60m',
    '#90m',
  ];

  // initial parse
  var a = s.split(' ');
  var t = []; // #tag
  for (var i = 0; i < a.length; i++) {
    if (a[i].substr(0, 1) == '#') {
      t.push(a.splice(i, 1));
      i--;
    }
  }

  // process discreet #tags
  for (var i = 0; i < t.length; i++) {
    if (false) {
    } else if (t[i] == '#e1') {
      energy = 1;
      t.splice(i, 1);
      i--;
    } else if (t[i] == '#e2') {
      energy = 2;
      t.splice(i, 1);
      i--;
    } else if (t[i] == '#e3') {
      energy = 3;
      t.splice(i, 1);
      i--;
    } else if (t[i] == '#1h') {
      etime = 60;
      t.splice(i, 1);
      i--;
    } else if (t[i] == '#2h') {
      etime = 120;
      t.splice(i, 1);
      i--;
    } else if (t[i] == '#3h') {
      etime = 180;
      t.splice(i, 1);
      i--;
    } else if (t[i] == '#4h') {
      etime = 240;
      t.splice(i, 1);
      i--;
    } else if (t[i] == '#5h') {
      etime = 360;
      t.splice(i, 1);
      i--;
    } // round up
    else if (t[i] == '#6h') {
      etime = 360;
      t.splice(i, 1);
      i--;
    } else if (t[i] == '#7h') {
      etime = 480;
      t.splice(i, 1);
      i--;
    } // round up
    else if (t[i] == '#8h') {
      etime = 480;
      t.splice(i, 1);
      i--;
    } else if (t[i] == '#5m') {
      etime = 5;
      t.splice(i, 1);
      i--;
    } else if (t[i] == '#10m') {
      etime = 10;
      t.splice(i, 1);
      i--;
    } else if (t[i] == '#15m') {
      etime = 15;
      t.splice(i, 1);
      i--;
    } else if (t[i] == '#20m') {
      etime = 20;
      t.splice(i, 1);
      i--;
    } else if (t[i] == '#25m') {
      etime = 30;
      t.splice(i, 1);
      i--;
    } // round up
    else if (t[i] == '#30m') {
      etime = 30;
      t.splice(i, 1);
      i--;
    } else if (t[i] == '#35m') {
      etime = 45;
      t.splice(i, 1);
      i--;
    } // round up
    else if (t[i] == '#40m') {
      etime = 45;
      t.splice(i, 1);
      i--;
    } // round up
    else if (t[i] == '#45m') {
      etime = 45;
      t.splice(i, 1);
      i--;
    } else if (t[i] == '#55m') {
      etime = 60;
      t.splice(i, 1);
      i--;
    } // round up
    else if (t[i] == '#50m') {
      etime = 60;
      t.splice(i, 1);
      i--;
    } // round up
    else if (t[i] == '#60m') {
      etime = 60;
      t.splice(i, 1);
      i--;
    } else if (t[i] == '#focus' || t[i] == '#star') {
      task.seqt = time();
      task._seqt = now;
      t.splice(i, 1);
      i--;
    } else if (t[i] == '#inbox') {
      task.state = NIRV.CONST.INBOX;
      task._state = now;
      t.splice(i, 1);
      i--;
    } else if (t[i] == '#next') {
      task.state = NIRV.CONST.NEXT;
      task._state = now;
      t.splice(i, 1);
      i--;
    } else if (t[i] == '#later' || t[i] == '#inactive') {
      task.state = NIRV.CONST.LATER;
      task._state = now;
      t.splice(i, 1);
      i--;
    } else if (t[i] == '#waiting' || t[i] == '#waitingfor') {
      task.state = NIRV.CONST.WAITING;
      task._state = now;
      t.splice(i, 1);
      i--;
    } else if (t[i] == '#someday') {
      task.state = NIRV.CONST.SOMEDAY;
      task._state = now;
      t.splice(i, 1);
      i--;
    } else if (t[i] == '#project') {
      task.type = NIRV.CONST.PROJECT;
      task._type = now;
      task.state = NIRV.CONST.ACTIVEPROJECT;
      task._state = now;
      t.splice(i, 1);
      i--;
    }
  }

  // process duedate
  var dparts = s.split('#due ');
  if (dparts.length > 1) {
    var dparts1 = dparts[1].split('#');
    var dstring = dparts1[0];
    var ddate = Date.parse(dstring);
    if (ddate) duedate = ddate.toString('yyyyMMdd');
  }

  // process startdate
  var sparts = s.split('#start ');
  if (sparts.length > 1) {
    var sparts1 = sparts[1].split('#');
    var sstring = sparts1[0];
    var sdate = Date.parse(sstring);
    if (sdate) {
      startdate = sdate.toString('yyyyMMdd');
      state = NIRV.CONST.SCHEDULED;
    }
  }

  // process waitingfor
  var wparts = s.split('#waiting ');
  if (wparts.length > 1) {
    var wparts1 = wparts[1].split('#');
    var wstring = wparts1[0].trim();
    if (wstring.length > 0) {
      waitingfor = wstring;
      state = NIRV.CONST.WAITING;
    }
  }

  // rebuild task name
  var fu = []; // kungfu
  fu = s.split('#due ');
  fu = fu[0];
  fu = fu.split('#start ');
  fu = fu[0];
  fu = fu.split('#waiting ');
  fu = fu[0];
  fu = fu.split(' ');
  for (var i = 0; i < fu.length; i++) {
    if (fu[i].substr(0, 1) == '#') {
      fu.splice(i, 1);
      i--;
    }
  }
  name = fu.join(' ');

  // rebuild task tags
  var tags = [];
  for (var i = 0; i < t.length; i++) {
    if (reserved.indexOf(t[i].toString()) == -1) {
      tags.push(t[i].toString().substr(1));
    }
  }
  tags.unique();
  tags.sort();

  task.name = name;
  task._name = now;
  task.tags = tags.join(',');
  task._tags = now;
  task.seq = now;
  task._seq = now;
  if (startdate != null) {
    task.startdate = startdate;
    task._startdate = now;
  }
  if (duedate != null) {
    task.duedate = duedate;
    task._duedate = now;
  }
  if (task.type != undefined && task.type == NIRV.CONST.TASK) {
    task.energy = energy;
    task._energy = now;
    task.etime = etime;
    task._etime = now;
    if (waitingfor != null) {
      task.waitingfor = waitingfor.trim();
      task._waitingfor = now;
    }
  }

  return task;
}

function smartentry(task) {
  // Regex Credit: http://pinoytech.org/question/4913555/find-twitter-hashtags-using-jquery-and-apply-a-link
  var hashtag_regexp = /#([a-zA-Z0-9]+)/g;
  var name = task.name;
  var tags = task.tags;

  function linkHashtags(text) {
    return text.replace(
      hashtag_regexp,
      '<a class="hashtag" href="http://twitter.com/#search?q=$1">#$1</a>'
    );
  }
}

function today(plus) {
  plus = plus || 0;
  var date = new Date();
  date.setDate(date.getDate() + plus);
  var yyyy = date.getFullYear();
  var mm = date.getMonth() + 1;
  var dd = date.getDate();
  mm = mm < 10 ? '' + '0' + mm : '' + mm; // pad left with 0
  dd = dd < 10 ? '' + '0' + dd : '' + dd; // pad left with 0
  return '' + yyyy + mm + dd;
}

function timetoymd(unixtime) {
  var microtime = unixtime * 1000;
  var date = new Date();
  date.setTime(microtime);
  var yyyy = date.getFullYear();
  var mm = date.getMonth() + 1;
  var dd = date.getDate();
  mm = mm < 10 ? '' + '0' + mm : '' + mm; // pad left with 0
  dd = dd < 10 ? '' + '0' + dd : '' + dd; // pad left with 0
  var str = yyyy < 2000 ? '' : '' + yyyy + mm + dd;
  return str;
}

function timetomd(unixtime) {
  var microtime = unixtime * 1000;
  var date = new Date();
  date.setTime(microtime);
  return ymdtomd(datetoymd(date));
}

function timetomdy(unixtime) {
  var microtime = unixtime * 1000;
  var date = new Date();
  date.setTime(microtime);
  var yyyy = date.getFullYear();
  var mm = date.getMonth() + 1;
  var dd = date.getDate();
  var M = '';
  switch (mm) {
    case 1:
      M = 'Jan';
      break;
    case 2:
      M = 'Feb';
      break;
    case 3:
      M = 'Mar';
      break;
    case 4:
      M = 'Apr';
      break;
    case 5:
      M = 'May';
      break;
    case 6:
      M = 'Jun';
      break;
    case 7:
      M = 'Jul';
      break;
    case 8:
      M = 'Aug';
      break;
    case 9:
      M = 'Sep';
      break;
    case 10:
      M = 'Oct';
      break;
    case 11:
      M = 'Nov';
      break;
    case 12:
      M = 'Dec';
      break;
  }
  mm = mm < 10 ? '' + '0' + mm : '' + mm; // pad left with 0
  dd = dd < 10 ? '' + '0' + dd : '' + dd; // pad left with 0
  var str = unixtime == 0 ? '' : '' + M + ' ' + dd + ', ' + yyyy;
  return str;
}

function timetodmdy(unixtime) {
  var microtime = unixtime * 1000;
  var date = new Date();
  date.setTime(microtime);
  var day = date.getDay();
  var D = '';
  switch (day) {
    case 0:
      D = 'Sun';
      break;
    case 1:
      D = 'Mon';
      break;
    case 2:
      D = 'Tue';
      break;
    case 3:
      D = 'Wed';
      break;
    case 4:
      D = 'Thu';
      break;
    case 5:
      D = 'Fri';
      break;
    case 6:
      D = 'Sat';
      break;
  }
  var yyyy = date.getFullYear();
  var mm = date.getMonth() + 1;
  var dd = date.getDate();
  var M = '';
  switch (mm) {
    case 1:
      M = 'Jan';
      break;
    case 2:
      M = 'Feb';
      break;
    case 3:
      M = 'Mar';
      break;
    case 4:
      M = 'Apr';
      break;
    case 5:
      M = 'May';
      break;
    case 6:
      M = 'Jun';
      break;
    case 7:
      M = 'Jul';
      break;
    case 8:
      M = 'Aug';
      break;
    case 9:
      M = 'Sep';
      break;
    case 10:
      M = 'Oct';
      break;
    case 11:
      M = 'Nov';
      break;
    case 12:
      M = 'Dec';
      break;
  }
  mm = mm < 10 ? '' + '0' + mm : '' + mm; // pad left with 0
  dd = dd < 10 ? '' + '0' + dd : '' + dd; // pad left with 0
  var str = unixtime == 0 ? '' : '' + D + ' ' + M + ' ' + dd + ', ' + yyyy;
  return str;
}

function timetomdhi(unixtime) {
  var microtime = unixtime * 1000;
  var date = new Date();
  date.setTime(microtime);
  var yyyy = date.getFullYear();
  var mm = date.getMonth() + 1;
  var dd = date.getDate();
  var hh = date.getHours();
  var ii = date.getMinutes();
  var ss = date.getSeconds();
  var M = '';
  switch (mm) {
    case 1:
      M = 'Jan';
      break;
    case 2:
      M = 'Feb';
      break;
    case 3:
      M = 'Mar';
      break;
    case 4:
      M = 'Apr';
      break;
    case 5:
      M = 'May';
      break;
    case 6:
      M = 'Jun';
      break;
    case 7:
      M = 'Jul';
      break;
    case 8:
      M = 'Aug';
      break;
    case 9:
      M = 'Sep';
      break;
    case 10:
      M = 'Oct';
      break;
    case 11:
      M = 'Nov';
      break;
    case 12:
      M = 'Dec';
      break;
  }
  mm = mm < 10 ? '' + '0' + mm : '' + mm; // pad left with 0
  dd = dd < 10 ? '' + '0' + dd : '' + dd; // pad left with 0
  ii = ii < 10 ? '' + '0' + ii : '' + ii; // pad left with 0
  ss = ss < 10 ? '' + '0' + ss : '' + ss; // pad left with 0
  var str =
    unixtime == 0 ? '' : '' + M + ' ' + dd + ' - ' + hh + ':' + ii + ':' + ss;
  return str;
}

function timetoDate(unixtime) {
  var microtime = unixtime * 1000;
  var date = new Date();
  date.setTime(microtime);
  return date;
}

function ymdtostr(ymd) {
  var yyyy = ymd.slice(0, 4);
  var mm = ymd.slice(4, 6);
  var dd = ymd.slice(6, 8);
  var M = '';
  switch (mm) {
    case '01':
      M = 'Jan';
      break;
    case '02':
      M = 'Feb';
      break;
    case '03':
      M = 'Mar';
      break;
    case '04':
      M = 'Apr';
      break;
    case '05':
      M = 'May';
      break;
    case '06':
      M = 'Jun';
      break;
    case '07':
      M = 'Jul';
      break;
    case '08':
      M = 'Aug';
      break;
    case '09':
      M = 'Sep';
      break;
    case '10':
      M = 'Oct';
      break;
    case '11':
      M = 'Nov';
      break;
    case '12':
      M = 'Dec';
      break;
  }
  var str = M + ' ' + dd + ', ' + yyyy;
  return str;
}

function ymdtolocale(ymd, style, locale) {
  style = style || 'SMART';
  locale = locale || NIRV.prefs['UIDateLocale'].value;

  var yyyy = ymd.slice(0, 4);
  var mm = parseInt(ymd.slice(4, 6)) - 1;
  var dd = ymd.slice(6, 8);
  var date = new Date(yyyy, mm, dd);
  var options = {};

  if (ymd == '') return 'Pending'; // duedate || startdate == ''

  if (yyyy > 2100) return 'Pending'; // recurring.freq == aftercompletion

  if (style == 'SMART') {
    var this_year = new Date().toString('yyyy');
    if (yyyy == this_year) {
      style = 'MMMD';
    } else {
      style = 'MMMDY';
    }
  }

  if (style == 'SHORT') {
    var this_year = new Date().toString('yyyy');
    if (yyyy == this_year) {
      style = 'MMMD';
    } else {
      style = 'MMMY';
    }
  }

  switch (style) {
    // case 'MDY': options = { year: 'numeric', month: '2-digit', day: '2-digit' }; break;
    case 'MDY':
      options = { year: 'numeric', month: 'numeric', day: 'numeric' };
      break;
    case 'MMMD':
      options = { month: 'short', day: 'numeric' };
      break;
    case 'MMMY':
      options = { year: 'numeric', month: 'short' };
      break;
    case 'MMMDY':
      options = { year: 'numeric', month: 'short', day: 'numeric' };
      break;
  }

  try {
    return date.toLocaleDateString(locale, options);
  } catch (e) {
    console.error(e);
    return date.toLocaleDateString('en-US', options);
  }
}

function ymdtomd(ymd) {
  if (ymd == undefined) {
    return 'ymd';
  }
  return ymdtolocale(ymd, 'MMMD');
  // var yyyy = ymd.slice(0, 4);
  // var mm = parseInt(ymd.slice(4, 6)) - 1;
  // var dd = ymd.slice(6, 8);
  // var date = new Date(yyyy, mm, dd);
  // var datetoday = new Date();
  // if (date.toString('yyyy') != datetoday.toString('yyyy')) {
  //     return date.toString('M/d/yyyy');
  // } else {
  //     return date.toString('MMM d');
  // }
}

function datetoymd(date) {
  var yyyy = date.getFullYear();
  var mm = date.getMonth() + 1;
  var dd = date.getDate();
  mm = mm < 10 ? '0' + mm : mm;
  dd = dd < 10 ? '0' + dd : dd;
  return '' + yyyy + mm + dd;
}

function duedatetohuman(yyyymmdd) {
  var out = '';
  var timestamp = parseInt(
    new Date(yyyymmdd2mmddyyyy(yyyymmdd)).getTime().toString().slice(0, 10)
  );
  var dueinxdays = Math.floor((time() - timestamp) / 86400);
  if (timestamp > 0) {
    if (dueinxdays <= -7) {
      out = 'Due ' + timetomd(timestamp);
    } else if (dueinxdays <= -2) {
      out = 'Due in ' + -1 * dueinxdays + ' Days';
    } else if (dueinxdays == -1) {
      out = 'Due Tomorrow';
    } else if (dueinxdays == 0) {
      out = 'Due Today';
    } else if (dueinxdays == 1) {
      out = '1 Day Late';
    } else if (dueinxdays >= 2) {
      out = dueinxdays + ' Days Late';
    }
  }
  return out;
}

function etimetomstr(etime) {
  etime = etime.toString();
  var str = '';
  switch (etime) {
    case '5':
      str = '5m';
      break;
    case '10':
      str = '10m';
      break;
    case '15':
      str = '15m';
      break;
    case '20':
      str = '20m';
      break;
    case '30':
      str = '30m';
      break;
    case '45':
      str = '45m';
      break;
    case '60':
      str = '1h';
      break;
    case '120':
      str = '2h';
      break;
    case '240':
      str = '4h';
      break;
    case '360':
      str = '3h';
      break;
    case '480':
      str = '8h';
      break;
    case '600':
      str = '10h';
      break;
  }
  return str;
}

function etimetostr(etime) {
  etime = etime.toString();
  var str = '';
  switch (etime) {
    case '0':
      str = 'none';
      break;
    case '5':
      str = '5 minutes';
      break;
    case '10':
      str = '10 minutes';
      break;
    case '15':
      str = '15 minutes';
      break;
    case '20':
      str = '20 minutes';
      break;
    case '30':
      str = '30 minutes';
      break;
    case '45':
      str = '45 minutes';
      break;
    case '60':
      str = '1 hour';
      break;
    case '120':
      str = '2 hours';
      break;
    case '180':
      str = '3 hours';
      break;
    case '240':
      str = '4 hours';
      break;
    case '360':
      str = '6 hours';
      break;
    case '480':
      str = '8 hours';
      break;
    case '600':
      str = 'whoa nelly!';
      break;
  }
  return str;
}

function etimehumansum(etime) {
  etime = parseInt(etime);
  var out = '';
  var m = '';
  var h = '';
  if (etime < 60) {
    out = etime + 'm';
  } else {
    h = Math.floor(etime / 60);
    m = etime % 60;
    if (h == 1) {
      out = '1h ';
    } else if (h > 1) {
      out = h + 'h ';
    }
    if (m != 0) {
      out += m + 'm';
    }
  }
  return out;
}

function energytomstr(energy) {
  energy = energy.toString();
  var str = '';
  switch (energy) {
    case '1':
      str = '•';
      break;
    case '2':
      str = '••';
      break;
    case '3':
      str = '•••';
      break;
  }
  return str;
}

function energytostr(energy) {
  energy = energy.toString();
  var str = '';
  switch (energy) {
    case '0':
      str = 'none';
      break;
    case '1':
      str = 'low energy •';
      break;
    case '2':
      str = 'medium energy ••';
      break;
    case '3':
      str = 'high energy •••';
      break;
  }
  return str;
}

function statetostr(state) {
  state = state.toString();
  var str = '';
  switch (state) {
    case '0':
      str = 'Inbox';
      break;
    case '1':
      str = 'Next';
      break;
    case '2':
      str = 'Waiting';
      break;
    case '3':
      str = 'Scheduled';
      break;
    case '4':
      str = 'Someday';
      break;
    case '5':
      str = 'Later';
      break;
    case '6':
      str = 'Trash';
      break;
    case '7':
      str = 'Logbook';
      break;
    case '8':
      str = 'Deleted';
      break;
    case '9':
      str = 'Recurring';
      break;
    case '10':
      str = 'Reference';
      break;
    case '11':
      str = 'Active';
      break;
  }
  return str;
}

function recurringtostr(recurring, markup) {
  var recurring = JSON.parse(recurring);
  var htmlmarkup = markup == 'text' ? false : true;
  var out = 'Repeats ';

  if (recurring.freq == 'aftercompletion') {
    out += recurring.interval + ' ' + recurring.period;
    if (recurring.interval > 1) {
      out += 's';
    }
  } else {
    out += 'every ';
  }

  // frequency
  if (recurring.freq == 'daily') {
    out += recurring.interval > 1 ? recurring.interval + ' ' : '';
    out += 'day';
    out += recurring.interval > 1 ? 's ' : ' ';
  }
  if (recurring.freq == 'weekly') {
    out += recurring.interval > 1 ? recurring.interval + ' ' : '';
    out += 'week';
    out += recurring.interval > 1 ? 's ' : ' ';
  }
  if (recurring.freq == 'monthly') {
    out += recurring.interval > 1 ? recurring.interval + ' ' : '';
    out += 'month';
    out += recurring.interval > 1 ? 's ' : ' ';
  }
  if (recurring.freq == 'yearly') {
    out += recurring.interval > 1 ? recurring.interval + ' ' : '';
    out += 'year';
    out += recurring.interval > 1 ? 's ' : ' ';
  }

  if (recurring.freq == 'aftercompletion') {
    out += ' after completion';
  } else {
    // on
    var nextdates = NIRV.calcnextdates(recurring);
    if (htmlmarkup) {
      out += '<span class="nextdates cmenu_state">';
    } else {
      out += ' \n';
    }
    if (recurring.hasduedate == 1) {
      out += 'Due on ';
    } else {
      out += 'Next up ';
    }
    for (var i = 0; i < nextdates.length; i++) {
      if (i < 2) {
        // out += new Date(yyyymmdd2mmddyyyy(nextdates[i])).toString('M/d/yy');
        out += ymdtolocale(nextdates[i], 'MDY');
      }
      if (i < 1) {
        out += ', ';
      }
      if (i == 2) {
        out += ' …';
      }
    }
    if (htmlmarkup) {
      out += '</span>';
    }
  }

  return out;
}

function sortEntitiesBy(entities, column, ascdesc, numeric) {
  // insanity
  ascdesc = ascdesc || 'asc';
  numeric = numeric || false;

  // sanitize
  ascdesc = ascdesc == 'desc' ? 'desc' : 'asc';
  numeric = numeric == true ? true : false;

  // vars
  var entity = {};
  var oentities = {}; // entities --> converted to object / collection with entityid as key
  var rentities = []; // entities --> sorted, to be returned by this method / function, incremented 0-n
  var column_entityids = []; // to hold concat: column | entityid, for array sort by string comparison
  var i; // iter
  var entityid = 0; // temp entity holder
  var exploded = [];

  // ensure we are dealing with an object
  for (i = 0; i < entities.length; i++) {
    if (typeof entities != 'function') {
      oentities[entities[i].id] = entities[i];
    }
  }

  // pad column value as array key for string based sorting
  if (numeric) {
    for (i in oentities) {
      column_entityids.push(
        padleft(oentities[i][column].toString(), 10) + '\r' + oentities[i].id
      );
    }
  } else {
    for (i in oentities) {
      column_entityids.push(
        oentities[i][column].toLowerCase() + '\r' + oentities[i].id
      );
    }
  }

  // sort asc/desc
  if (ascdesc == 'asc') {
    column_entityids.sort();
  } else {
    column_entityids.sort();
    column_entityids.reverse();
  }

  // build return object
  for (i = 0; i < column_entityids.length; i++) {
    exploded = column_entityids[i].split('\r');
    var id = exploded[1];
    if (oentities[id] != undefined) {
      rentities[i] = oentities[id];
    }
  }

  return rentities;
}

function _isvalid_yyyymmdd(date) {
  date = date || '';
  date = date.toString();
  if (parseInt(date, 10) != date) {
    return false;
  }
  if (date.length != 8) {
    return false;
  }
  if (date < 20000101 || date > 22221111) {
    return false;
  }
  return true;
}

// RECURRING ADDITIONS

function datetommddyy(date) {
  var mm = '' + (date.getMonth() + 1);
  var dd = '' + date.getDate();
  var yy = '' + (date.getFullYear() + '').substr(2, 2);
  return mm + '/' + dd + '/' + yy;
}

function datetommddyyyy(date) {
  var mm = '' + (date.getMonth() + 1);
  var dd = '' + date.getDate();
  var yyyy = '' + date.getFullYear();
  return mm + '/' + dd + '/' + yyyy;
}

function mmddyyyy2yyyymmdd(s) {
  var parts = s.split('/');
  var mm = '' + parts[0];
  var dd = '' + parts[1];
  var yyyy = '' + parts[2];
  return '' + yyyy + mm + dd;
}

function yyyymmdd2mmddyyyy(s) {
  var yyyy = s.substr(0, 4);
  var mm = s.substr(4, 2);
  var dd = s.substr(6, 2);
  return mm + '/' + dd + '/' + yyyy;
}

function yyyymmdd2time(s) {
  var yyyy = parseInt(s.substr(0, 4));
  var mm = parseInt(s.substr(4, 2)) - 1; // js months are from 0 - 11
  var dd = parseInt(s.substr(6, 2));
  var newdate = new Date(yyyy, mm, dd);
  return newdate.getTime() / 1000 || 0;
}

// CREDIT: http://stackoverflow.com/questions/1248302/javascript-object-size
function roughSizeOfObject(object) {
  var objectList = [];
  var recurse = function (value) {
    var bytes = 0;
    if (typeof value === 'boolean') {
      bytes = 4;
    } else if (typeof value === 'string') {
      bytes = value.length * 2;
    } else if (typeof value === 'number') {
      bytes = 8;
    } else if (typeof value === 'object' && objectList.indexOf(value) === -1) {
      objectList[objectList.length] = value;

      for (i in value) {
        bytes += 8; // an assumed existence overhead
        bytes += recurse(value[i]);
      }
    }
    return bytes;
  };

  return recurse(object);
}

var bytesToHuman = function (bytes) {
  if (bytes > 1000 * 1000) {
    return Math.round((bytes / 1000 / 1000) * 10) / 10 + 'M'; // megabytes
  } else {
    return Math.round(bytes / 1000) + 'K'; // kilobytes
  }
};
