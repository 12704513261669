// LISTENERS TBAR

$(function () {
  $('body').on('focus', 'input.rapidentry', function () {
    // NIRV.q = '';
    // $('#north .q').val(NIRV.q).removeClass('focus');
    $(this).parent().addClass('focus');
    $(this).addClass('focus');
  });

  $('body').on('blur', 'input.rapidentry', function () {
    $(this).parent().removeClass('focus');
    $(this).removeClass('focus');
  });

  $('body').on('submit', 'form.rapidentry', function () {
    var uuid = NIRV.uuid();
    var now = time();
    var state = 0; // default: inbox
    var name = $(this).find('input.rapidentry').val().trim();
    var parenttags = '';
    var tags = ',';
    var ps = NIRV.prefs.UIDefaultProjectType
      ? NIRV.prefs.UIDefaultProjectType.value
      : 0;

    if (name == '') {
      return false;
    }

    // SERVICE LEVEL CHECK
    if (
      NIRV.currentview == 'projects' &&
      NIRV.projectsCount() >= NIRV.user.maxprojects
    ) {
      NIRV.promptUpgradeRequired('projects');
      return false;
    }
    if (
      NIRV.currentview == 'reflists' &&
      NIRV.reflistsCount() >= NIRV.user.maxreflists
    ) {
      NIRV.promptUpgradeRequired('reflists');
      return false;
    }

    // SMART PARSE -- parse string for new task; then override where appropriate
    var stask = smartparse(name);
    stask.id = uuid;
    stask.seq = NIRV.shiftKey ? 0 : now;
    stask._seq = now;
    stask.__stale__ = true;
    stask.__isNew__ = true;

    // DETERMINE INHERITED TAGS
    if (NIRV.currentview != 'inbox') {
      // determine parent tags if any
      if (NIRV.currentview[0] == 'p' && NIRV.currentview != 'projects') {
        parenttags = NIRV.tasks[NIRV.currentview.substr(1)].tags;
      }
      // append currentarea
      if (NIRV.currentarea != '__ALL__' && NIRV.currentarea != '__NONE__') {
        if (parenttags.indexOf(',' + NIRV.currentarea + ',') == -1) {
          tags += NIRV.currentarea + ',';
        }
      }
    }
    if (NIRV.currentfilters.tags.length != 0) {
      for (var i = 0; i < NIRV.currentfilters.tags.length; i++) {
        if (parenttags.indexOf(',' + NIRV.currentfilters.tags[i] + ',') == -1) {
          tags += NIRV.currentfilters.tags[i] + ',';
        }
      }
    }
    stask.tags += tags;

    NIRV.tasks[stask.id] = NIRV.Task.clone(stask);

    if (NIRV.currentview == 'focus') {
      NIRV.tasks[uuid].state = NIRV.CONST.NEXT;
      NIRV.tasks[uuid]._state = now;
      NIRV.tasks[uuid].seq = NIRV.shiftKey ? 0 : now;
      NIRV.tasks[uuid]._seq = now;
      NIRV.tasks[uuid].seqt = NIRV.shiftKey ? -1 : now;
      NIRV.tasks[uuid]._seqt = now;
    } else if (NIRV.currentview == 'next') {
      NIRV.tasks[uuid].state = NIRV.CONST.NEXT;
      NIRV.tasks[uuid]._state = now;
    } else if (NIRV.currentview == 'later') {
      NIRV.tasks[uuid].state = NIRV.CONST.LATER;
      NIRV.tasks[uuid]._state = now;
    } else if (NIRV.currentview == 'waiting') {
      NIRV.tasks[uuid].state = NIRV.CONST.WAITING;
      NIRV.tasks[uuid]._state = now;
    } else if (NIRV.currentview == 'scheduled') {
      NIRV.tasks[uuid].state = NIRV.CONST.SCHEDULED;
      NIRV.tasks[uuid]._state = now;
    } else if (NIRV.currentview == 'someday') {
      NIRV.tasks[uuid].state = NIRV.CONST.SOMEDAY;
      NIRV.tasks[uuid]._state = now;
    } else if (NIRV.currentview == 'projects') {
      NIRV.tasks[uuid].type = NIRV.CONST.PROJECT;
      NIRV.tasks[uuid]._type = now;
      NIRV.tasks[uuid].ps = ps;
      NIRV.tasks[uuid]._ps = now;
      NIRV.tasks[uuid].state = NIRV.CONST.ACTIVEPROJECT;
      NIRV.tasks[uuid]._state = now;
    } else if (NIRV.currentview == 'reflists') {
      NIRV.tasks[uuid].type = NIRV.CONST.REFLIST;
      NIRV.tasks[uuid]._type = now;
      NIRV.tasks[uuid].state = NIRV.CONST.REFERENCE;
      NIRV.tasks[uuid]._state = now;
    } else if (NIRV.currentview == 'logbook') {
      NIRV.tasks[uuid].seq = 0;
      NIRV.tasks[uuid]._seq = now;
      NIRV.tasks[uuid].state = NIRV.CONST.LOGGED;
      NIRV.tasks[uuid]._state = now;
      NIRV.tasks[uuid].completed = now;
      NIRV.tasks[uuid]._completed = now;
    } else if (NIRV.currentview[0] == 'p') {
      NIRV.tasks[uuid].parentid = NIRV.currentview.substr(1);
      NIRV.tasks[uuid]._parentid = now;
      NIRV.tasks[uuid].state = NIRV.CONST.NEXT;
      NIRV.tasks[uuid]._state = now;
      NIRV.tasks[uuid].seq = NIRV.shiftKey ? 0 : now;
      NIRV.tasks[uuid]._seq = now;
      NIRV.tasks[uuid].seqp = NIRV.shiftKey ? 0 : now;
      NIRV.tasks[uuid]._seqp = now;
      if (NIRV.tasks[NIRV.tasks[uuid].parentid].state == NIRV.CONST.LOGGED) {
        NIRV.tasks[uuid].state = NIRV.CONST.LOGGED;
        NIRV.tasks[uuid]._state = now;
        NIRV.tasks[uuid].completed = now;
        NIRV.tasks[uuid]._completed = now;
      } else {
        // PARENT ADJUSTMENTS
        if (NIRV.tasks[uuid].parentid != '') {
          if (NIRV.tasks[NIRV.tasks[uuid].parentid] != undefined) {
            if (NIRV.tasks[NIRV.tasks[uuid].parentid].completed != 0) {
              NIRV.tasks[NIRV.tasks[uuid].parentid].set('completed', 0);
              NIRV.tasks[NIRV.tasks[uuid].parentid].set('cancelled', 0);
            }
          }
        }
      }
    } else if (NIRV.currentview[0] == 'l') {
      NIRV.tasks[uuid].parentid = NIRV.currentview.substr(1);
      NIRV.tasks[uuid]._parentid = now;
      NIRV.tasks[uuid].type = NIRV.CONST.REFITEM;
      NIRV.tasks[uuid]._type = now;
      NIRV.tasks[uuid].state = NIRV.CONST.REFERENCE;
      NIRV.tasks[uuid]._state = now;
      NIRV.tasks[uuid].seq = NIRV.shiftKey ? 0 : now;
      NIRV.tasks[uuid]._seq = now;
      NIRV.tasks[uuid].seqp = NIRV.shiftKey ? 0 : now;
      NIRV.tasks[uuid]._seqp = now;
      if (NIRV.tasks[NIRV.tasks[uuid].parentid].state == NIRV.CONST.LOGGED) {
        NIRV.tasks[uuid].state = NIRV.CONST.LOGGED;
        NIRV.tasks[uuid]._state = now;
        NIRV.tasks[uuid].completed = now;
        NIRV.tasks[uuid]._completed = now;
      } else {
        // PARENT ADJUSTMENTS
        if (NIRV.tasks[uuid].parentid != '') {
          if (NIRV.tasks[NIRV.tasks[uuid].parentid] != undefined) {
            if (NIRV.tasks[NIRV.tasks[uuid].parentid].completed != 0) {
              NIRV.tasks[NIRV.tasks[uuid].parentid].set('completed', 0);
              NIRV.tasks[NIRV.tasks[uuid].parentid].set('cancelled', 0);
            }
          }
        }
      }
    }

    // RESEQUENCE BASED ON TASKLIST
    NIRV.recalcWtasks();
    NIRV.refreshMain();
    var $li = $('li.task[taskid=' + uuid + ']');
    var $ul = $li.closest('ul');
    if ($('li.task[taskid=' + uuid + ']').length > 0) {
      var sortby = $ul.attr('sortby');
      if (sortby != undefined && sortby.slice(0, 3) != 'seq') {
        // nada
      } else {
        var position = 1;
        $ul.children('li.task').each(function () {
          NIRV.tasks[$(this).attr('taskid')].set(sortby, position++);
        });
      }
    }

    NIRV.recalcWtasks();
    NIRV.refreshMain(() => {
      $('#main').find('input.rapidentry').val('');
      $('#main').find('input.rapidentry').focus();
    });
    NIRV.refreshCbar();
    NIRV.refreshEast();
    NIRV.reflow();

    return false;
  });
});
