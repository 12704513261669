NIRV.Pref = {
  key: 'PREF',
  _key: 0,
  value: '',
  _value: 0,
  __stale__: false,
  __isNew__: false,
  set: function (key, value, timestamp) {
    timestamp = timestamp || time();
    if (timestamp == NIRV.ajax_initiated) {
      timestamp = timestamp + 1;
    }
    if (this[key] != value) {
      this[key] = value;
      if (key != undefined && key[0] != '_') {
        this['_' + key] = timestamp;
      }
      this.__stale__ = true;
    }
    return this;
  },
  clone: function clone(init) {
    init = init || {};
    var o = cloneObject(this);
    for (var i in init) {
      o[i] = init[i];
    }
    return o;
  },
  save: function Pref_save() {
    // check if stale to avoid saving defaults at launch
    if (this.__stale__) {
      DEBUG && console.log('  Pref.save() ' + this.key);
      var data = {
        method: 'pref.save',
        key: this.key,
        _key: this._key,
        value: this.value,
        _value: this._value,
      };

      var ldata = cloneObject(data);
      ldata.__stale__ = this.__stale__;
      ldata.__isNew__ = this.__isNew__;
      delete ldata.method;
      NIRV.storage.setItem('pref.' + ldata.key, JSON.stringify(ldata));

      return data;
    }
  },
};
