NIRV.activateDarkMode = function () {
  $('html').removeClass('appearance--light').addClass('appearance--dark');
};

NIRV.activateLightMode = function () {
  $('html').removeClass('appearance--dark').addClass('appearance--light');
};

// themes
NIRV.themes = [
  {
    name: 'classic',
    accentColor: '#2F88F7',
    backgroundColor: '#ffffff',
    taskSelectedBackground: '#f3f3f3',
    textColor: '#333333',
    cbar: 'classic',
  },
  {
    name: 'snow',
    accentColor: '#5588cc',
    backgroundColor: '#f0f0f0',
    taskSelectedBackground: '#f3f3f3',
    textColor: '#333333',
  },
  {
    name: 'blue',
    accentColor: '#2667b7',
    backgroundColor: '#4687d7',
    taskSelectedBackground: '#f3f3f3',
    textColor: '#ffffff',
    cbar: 'inverted',
  },
  {
    name: 'classicblue',
    accentColor: '#0F4E81',
    backgroundColor: '#0F4E81',
    taskSelectedBackground: '#f3f3f3',
    textColor: '#ffffff',
    cbar: 'inverted',
  },
  {
    name: 'vivamagenta',
    accentColor: '#BC2849',
    backgroundColor: '#BC2849',
    taskSelectedBackground: '#f3f3f3',
    textColor: '#ffffff',
    cbar: 'inverted',
  },
  {
    name: 'veryperi',
    accentColor: '#6768AB',
    backgroundColor: '#6768AB',
    taskSelectedBackground: '#f3f3f3',
    textColor: '#ffffff',
    cbar: 'inverted',
  },
  {
    name: 'illuminating',
    accentColor: '#949599',
    backgroundColor: '#F5DF4E',
    taskSelectedBackground: '#f3f3f3',
    textColor: '#747579',
  },
  {
    name: 'livingcoral',
    accentColor: '#FE7163',
    backgroundColor: '#FE7163',
    taskSelectedBackground: '#f3f3f3',
    textColor: '#ffffff',
    cbar: 'inverted',
  },
  {
    name: 'greenery',
    accentColor: '#8AB04C',
    backgroundColor: '#8AB04C',
    taskSelectedBackground: '#f3f3f3',
    textColor: '#ffffff',
    cbar: 'inverted',
  },
];

NIRV.theme = NIRV.themes[0];

NIRV.changeTheme = function () {
  NIRV.theme = NIRV.themes[0]; // fallback
  if (NIRV.prefs && NIRV.prefs.UITheme) {
    NIRV.themes.forEach((t) => {
      if (t.name == NIRV.prefs.UITheme.value) {
        NIRV.theme = t;
      }
    });
  }

  // update theme variables
  document.documentElement.style.setProperty(
    '--nirv-color-accent',
    NIRV.theme.accentColor
  );
  document.documentElement.style.setProperty(
    '--nirv-theme-background-color',
    NIRV.theme.backgroundColor
  );
  document.documentElement.style.setProperty(
    '--nirv-theme-text-color',
    NIRV.theme.textColor
  );
  document.documentElement.style.setProperty(
    '--nirv-theme-task-selected-background',
    NIRV.theme.taskSelectedBackground
  );

  // update html theme.cbar className hack
  if (NIRV.theme.cbar == 'inverted') {
    $('html').addClass('ThemeCbar--inverted');
  } else {
    $('html').removeClass('ThemeCbar--inverted');
  }
};

NIRV.applyTheme = function () {
  // DEBUG && console.log('NIRV.applyTheme()');

  var $html = $('html');
  $html.attr('class', '');

  // PLATFORM
  if (NIRV.isMac) {
    $html.addClass('os--mac');
  }
  if (NIRV.isWindows) {
    $html.addClass('os--windows');
  }
  if (NIRV.isElectron) {
    $html.addClass('browser--electron');
  }

  // DENSITY - compact | comfortable
  if (
    typeof NIRV.prefs.UIBDensity !== 'undefined' &&
    NIRV.prefs.UIBDensity.value
  ) {
    $html.addClass('density--' + NIRV.prefs.UIBDensity.value);
  } else {
    $html.addClass('density--compact');
  }

  // APPEARANCE - light | dark | auto (sync with os)
  if (NIRV.prefs.UIBAppearance && NIRV.prefs.UIBAppearance.value == 'light') {
    NIRV.activateLightMode();
  } else if (
    NIRV.prefs.UIBAppearance &&
    NIRV.prefs.UIBAppearance.value == 'dark'
  ) {
    NIRV.activateDarkMode();
  } else {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      NIRV.activateDarkMode();
    } else {
      NIRV.activateLightMode();
    }
  }

  if (NIRV.prefs.UITheme == undefined || NIRV.prefs.UITheme?.value == 'classic') {
    // CLASSIC THEME LIGHT
    $html.addClass('theme--classic');
    $html.removeClass('themes--2025');

    if ($html.hasClass('appearance--light')) {
      if (
        NIRV.prefs.UIBThemeBackground &&
        NIRV.prefs.UIBThemeBackground.value == 'light'
      ) {
        $html.addClass('backdrop--snow');
      } else if (
        NIRV.prefs.UIBThemeBackground &&
        NIRV.prefs.UIBThemeBackground.value == 'dark'
      ) {
        $html.addClass('backdrop--slate');
      } else {
        $html.addClass('backdrop--snow');
      }
    }

    // FONT FAMILY
    // var style = '';
    // if (NIRV.prefs.UIB3FontFamily != undefined) {
    //   style += '<style>';
    //   style += 'html { font-family: ';
    //   style += NIRV.prefs.UIB3FontFamily.value;
    //   style += ', sans-serif; } ';
    //   style += '</style>';
    // }
    // $('#theme').html(style);
  } else {
    $html.removeClass('theme--classic');
    $html.addClass('themes--2025');
  }
  NIRV.changeTheme(NIRV.prefs.UITheme?.value);
};
