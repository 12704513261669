// JQuery .each() backwards
// I present you with the cleanest way ever, in the form of the world's smallest jquery plugin:
// https://stackoverflow.com/a/5386150/3703931
jQuery.fn.reverse = [].reverse;

// Detecting prefers-color-scheme via javascript, inspired by
// https://stackoverflow.com/a/60000747/3703931
var darkMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
var lightMediaQuery = window.matchMedia('(prefers-color-scheme: light)');

try {
  // Chrome & Firefox
  darkMediaQuery.addEventListener('change', function (e) {
    if (
      this.matches &&
      NIRV.prefs.UIBAppearance &&
      NIRV.prefs.UIBAppearance.value == 'auto'
    ) {
      NIRV.applyTheme();
    }
  });
  lightMediaQuery.addEventListener('change', function (e) {
    if (
      this.matches &&
      NIRV.prefs.UIBAppearance &&
      NIRV.prefs.UIBAppearance.value == 'auto'
    ) {
      NIRV.applyTheme();
    }
  });
} catch (e1) {
  try {
    // Safari
    darkMediaQuery.addListener(function (e) {
      if (
        this.matches &&
        NIRV.prefs.UIBAppearance &&
        NIRV.prefs.UIBAppearance.value == 'auto'
      ) {
        NIRV.applyTheme();
      }
    });
    lightMediaQuery.addListener(function (e) {
      if (
        this.matches &&
        NIRV.prefs.UIBAppearance &&
        NIRV.prefs.UIBAppearance.value == 'auto'
      ) {
        NIRV.applyTheme();
      }
    });
  } catch (e2) {
    DEBUG && console.error(e2);
  }
}

$(function () {
  $('body').on('click', '#help span.closex', function () {
    NIRV.toggleSidebarHelp();
  });

  $('body').on('mouseover', '#cmenu ul.sf-menu a.submenu', function () {
    NIRV.coerceMenuIntoViewport(this);
  });

  $('body').on('click', '#cmenu ul.sf-menu a.submenu', function () {
    NIRV.coerceMenuIntoViewport(this);
  });

  if (NIRV.isElectron) {
    $('body').on('click', 'a[rel=external]', function (e) {
      e.preventDefault();
      window.open($(this).attr('href'), '_blank');
    });
  } else {
    $('body').on('click', 'a[rel!=external]', function (e) {
      e.preventDefault();
    });
  }

  // NAVIGATION VIA URL HASH
  NIRV.hashnav = function () {
    var hash = location.hash;
    var tokens = hash.substr(1).split('/');
    var view = tokens[0];
    var p1 = tokens[1];
    var p2 = tokens[2];
    DEBUG && console.log(hash, view, p1, p2);
    if (view == 'inbox') {
      NIRV.view('inbox');
    } else if (view == 'next') {
      NIRV.view('next');
    } else if (view == 'later') {
      NIRV.view('later');
    } else if (view == 'waiting') {
      NIRV.view('waiting');
    } else if (view == 'scheduled') {
      NIRV.view('scheduled');
    } else if (view == 'someday') {
      NIRV.view('someday');
    } else if (view == 'focus') {
      NIRV.view('focus');
    } else if (view == 'projects') {
      NIRV.view('projects');
    } else if (view == 'reflists') {
      NIRV.view('reflists');
    } else if (view == 'logbook') {
      NIRV.view('logbook');
    } else if (view == 'trash') {
      NIRV.view('trash');
    } else if (view == 'project') {
      NIRV.viewproject(p1);
    } else if (view == 'reflist') {
      NIRV.viewreflist(p1);
    } else if (view == 'tag') {
      NIRV.viewtag(decodeURIComponent(p1));
    } else if (view == 'account') {
      NIRV.gotoAccount(p1);
    } else if (view == 'upgrade') {
      if (NIRV.user.servicelevel == 'pro') {
        NIRV.view('focus');
        setTimeout(function () {
          NIRV.alert('You are already on Nirvana Pro!\nThanks for upgrading.');
        }, 1000);
      } else {
        NIRV.gotoAccount('upgrade');
      }
    } else {
      NIRV.view('focus');
    }
  };

  $(window).bind('hashchange', function (e) {
    e.preventDefault();
    NIRV.hashnav();
  });

  // REFRESH PAGE ON FOCUS
  $(window).focus(function () {
    DEBUG && console.log('---------- window.focus ----------');
    if (NIRV.viewport == 'app') {
      $('#north a.sync').addClass('inprogress');
      setTimeout(function () {
        $('#north a.sync').removeClass('inprogress');
      }, 800);
      NIRV.must_sync = true;
      NIRV.sync();
    }
  });

  $(window).blur(function () {
    DEBUG && console.log('---------- window.blur ----------');
    NIRV.keyIsDown = false;
    NIRV.mouseIsDown = false;
  });

  // REFLOW PAGE ON WINDOW RESIZE
  $(window).resize(function () {
    if (NIRV.viewport == 'app') {
      NIRV.reflow();
    }
  });
});
