// LISTENERS TASK

$(function () {
  // TASK LI NOTE
  $('body').on('click', 'li.task span.x_note', function () {
    var $task = $(this).closest('li.task');
    var ui_selected = $task.hasClass('ui-selected');
    var taskid = $task.attr('taskid');
    var task = NIRV.tasks[taskid];
    if (
      (NIRV.prefs.UIBTaskDetails &&
        NIRV.prefs.UIBTaskDetails.value != '3' &&
        task.__viewtoggle__ != 'expand') ||
      task.__viewtoggle__ == 'collapse'
    ) {
      task.__viewtoggle__ = 'expand';
    } else {
      task.__viewtoggle__ = 'collapse';
    }
    $task.replaceWith(NIRV.wtasks[taskid].render());
    NIRV.recalcWtasks();
    NIRV.reflow();
    if (ui_selected) {
      $('#main li.task[taskid=' + taskid + ']').addClass('ui-selected');
      NIRV.draggable();
    }
  });

  // TASK LI INLINE-CHECKLISTS
  $('body').on('click', 'li.task .infinity span.subtask', function () {
    var $li = $(this).closest('li');
    var $infinity = $li.find('div.infinity');
    var task = NIRV.tasks[$li.attr('taskid')];
    var innerhtml = '';
    // toggle tickboxes in UI
    if ($(this).hasClass('checked')) {
      $(this).removeClass('checked');
      $(this).html('<span>-</span>');
    } else {
      $(this).addClass('checked');
      $(this).html('<span>x</span>');
    }
    // parse and save
    $infinity.after('<div class="hide">' + $infinity.html() + '</div>');
    $infinity
      .next()
      .find('.nosave')
      .each(function () {
        innerhtml = $(this).text();
        $(this).replaceWith(innerhtml);
      });
    task.set('note', br2nl($infinity.next().html()));
    task.save();
    $infinity.next().remove();
  });

  // TASK EDIT
  $('body').on('submit', 'li.task.edit form', function (e) {
    e.preventDefault();

    var $ul = $(this).closest('ul');

    NIRV.tasks[NIRV.taskedit.id] = cloneObject(NIRV.taskedit);
    var task = NIRV.tasks[NIRV.taskedit.id]; // easier to read
    var must_resequence_after_save = false;

    // Are we editing a task inline?
    if ($('#main').has('.task.edit').length > 0) {
      // Yes we are! so we should probably set:
      must_resequence_after_save = true;
    }

    $(this)
      .find('input[name!=save], textarea, select')
      .each(function () {
        if ($(this).prop('name') == 'tags[]') {
          if ($(this).val() == null) {
            task.set('tags', '');
          } else {
            task.set('tags', $(this).val().toString());
          }
        } else {
          task.set($(this).prop('name'), $(this).val());
        }
      });

    // Did we change task.state inline?
    if (NIRV.taskedit.state != task.state) {
      // Yes we did, therefore:
      must_resequence_after_save = false;
      task.set('seq', time());
    }

    // Did we change task.parentid inline?
    if (NIRV.taskedit.parentid != task.parentid) {
      // Yes we did, therefore:
      must_resequence_after_save = false;
      task.set('seqp', time());
    }

    NIRV.taskedit = {}; // just in case

    // PARENT ADJUSTMENTS
    if (task.parentid != '') {
      if (NIRV.tasks[task.parentid] != undefined) {
        if (NIRV.tasks[task.parentid].completed != 0) {
          NIRV.tasks[task.parentid].set('completed', 0);
          NIRV.tasks[task.parentid].set('cancelled', 0);
        }
      }
    }

    if ($(this).hasClass('prompt')) {
      if ($('#newtask').data('ui-dialog')) {
        $('#newtask').dialog('close');
      }
      if (task.type == NIRV.CONST.PROJECT && NIRV.currentview != 'projects') {
        NIRV.viewproject(task.id);
        if ($('#east h3.projects').hasClass('collapsed')) {
          $('#east h3.projects span.name').trigger($.Event(NIRV.mousedown));
        }
      }
      if (task.type == NIRV.CONST.REFLIST && NIRV.currentview != 'reflists') {
        NIRV.viewproject(task.id);
        if ($('#east h3.reflists').hasClass('collapsed')) {
          $('#east h3.reflists span.name').trigger($.Event(NIRV.mousedown));
        }
      }
    }

    // INSERTED?!!
    if (must_resequence_after_save) {
      var sortby = $ul.attr('sortby');
      if (sortby != undefined && sortby.slice(0, 3) != 'seq') {
        // resequence skipped
      } else {
        var position = 1;
        $ul.children('li.task').each(function () {
          if (NIRV.currentview == 'focus') {
            if (
              $(this).attr('taskid') != task.id ||
              ($(this).attr('taskid') == task.id && task.seqt != 0)
            ) {
              NIRV.tasks[$(this).attr('taskid')].set(sortby, position++);
            }
          } else {
            NIRV.tasks[$(this).attr('taskid')].set(sortby, position++);
          }
        });
      }
    }

    NIRV.save();

    NIRV.recalcWtasks();
    NIRV.refreshMain();
    NIRV.refreshCbar();
    NIRV.refreshEast();
    NIRV.reflow();

    NIRV.highlight_tasks([task.id]);

    // REFRESH current view if:
    // -- we are in project view
    // -- we are saving changes to the project itself
    if (
      NIRV.currentview[0] == 'p' &&
      NIRV.currentview != 'projects' &&
      task.type == NIRV.CONST.PROJECT
    ) {
      setTimeout(function () {
        NIRV.refreshCurrentview();
      }, 0);
    }

    NIRV.autosave = true;
    return false;
  });

  /*
  // upload file
  $('body').on('change', 'li.task.edit form input[type=file]', function (e) {
    e.preventDefault();

    var form = $('#fileUploadForm')[0];
    var formData = new FormData(form);
    var file = e.target.files[0];
    var filename = encodeURIComponent(file.name);
    var appendid = NIRV.uuid();

    $('.uploadFile').val('');

    var html =
      '<div data-appendid="' +
      appendid +
      '" style="margin: 1px 0; padding: 5px; background: #eee; font-size: 12px;">' +
      '<span class="downloadFile uploadFilename">• ' +
      file.name +
      '</span> (' +
      bytesToHuman(file.size) +
      ')' +
      '<span style="float: right; margin-left: 1em;" class="removeFile" data-appendid="' +
      appendid +
      '">x</span>' +
      '<span style="float: right;" class="uploadProgress">preparing to upload</span>' +
      '</div>';

    $('td.task-appends').append(html);

    var $uploadDiv = $('div[data-appendid="' + appendid + '"]');
    var $uploadProgress = $uploadDiv.find('.uploadProgress');
    var $uploadRemove = $uploadDiv.find('.removeFile');
    $uploadRemove.hide();

    var taskid = NIRV.taskedit.id;
    var nirv_api_url =
      '/api/?api=s3&method=preSignedUploadParams' +
      '&authtoken=' +
      NIRV.authtoken +
      '&t=' +
      taskid +
      '&a=' +
      appendid +
      '&f=' +
      filename;

    $.ajaxSetup({ processData: true });
    NIRV.get(nirv_api_url, function (res) {
      var aws_url = res.results[0].url;
      var fields = res.results[0].fields;
      var object_keys = Object.keys(fields);
      for (var i = 0; i < object_keys.length; i++) {
        formData[object_keys[i]] = fields[object_keys[i]];
        formData.append(object_keys[i], fields[object_keys[i]]);
      }
      formData.append('file', file);

      $.ajax({
        type: 'POST',
        enctype: 'multipart/form-data',
        url: aws_url,
        data: formData,
        dataFilter: false,
        processData: false,
        contentType: false,
        cache: false,
        xhr: function () {
          var xhr = new window.XMLHttpRequest();
          window.xhruploads = window.xhruploads || [];
          window.xhruploads[appendid] = xhr;
          xhr.upload.addEventListener(
            'progress',
            function (event) {
              if (event.lengthComputable) {
                var percentUploaded = Math.round(
                  (event.loaded / event.total) * 100
                );
                if (percentUploaded < 100) {
                  $uploadProgress.text(percentUploaded + '%');
                  $uploadRemove.show();
                } else {
                  $uploadProgress.text('');
                  $uploadRemove.hide();
                }
              }
            },
            false
          );
          return xhr;
        },
        success: function (data, status, xhr) {
          console.log('upload success', data, status, xhr);
          var now = time();
          var meta = {
            filename: file.name,
            filesize: file.size,
            filetype: file.type,
          };
          NIRV.appends[appendid] = NIRV.Append.clone({
            id: appendid,
            taskid: taskid,
            _taskid: now,
            type: 1,
            _type: now,
            meta: JSON.stringify(meta),
            _meta: now,
            deleted: 0,
            _deleted: now,
            __stale__: true,
            __isNew__: true,
          });
          NIRV.appends[appendid].save();
          $uploadDiv.replaceWith(NIRV.appends[appendid].render());
        },
        error: function (err) {
          console.log('upload interrupted');
          $uploadDiv.remove();
        },
      });
      return false;
    });
  });

  // Remove from DOM and mark deleted
  $('body').on('click', 'li.task .removeFile', function (e) {
    e.preventDefault();
    var appendid = $(this).attr('data-appendid');
    if (NIRV.appends[appendid]) {
      var meta = JSON.parse(NIRV.appends[appendid].meta);
      NIRV.confirm('Remove the file "' + meta.filename + '"?', function () {
        NIRV.appends[appendid].set('deleted', time());
        $(this).closest('div').remove();
      });
    } else {
      try {
        if (window.xhruploads[appendid]) {
          window.xhruploads[appendid].abort();
          $(this).closest('div').remove();
        }
      } catch (err) {
        console.error(err);
      }
    }
  });

  // Download file
  $('body').on('click', 'li.task .downloadFile', function (e) {
    e.preventDefault();
    var appendid = $(this).attr('data-appendid');
    var taskid = NIRV.appends[appendid].taskid;
    var filename = encodeURIComponent(
      JSON.parse(NIRV.appends[appendid].meta).filename
    );
    var nirv_api_url =
      '/api/?api=s3&method=preSignedDownloadUrl' +
      '&authtoken=' +
      NIRV.authtoken +
      '&t=' +
      taskid +
      '&a=' +
      appendid +
      '&f=' +
      filename +
      '&r=true';
    window.open(nirv_api_url, '_blank');
  });
  */

  $('body').on('click', 'li.task.edit .save', function () {
    $(this).closest('form').submit();
    var taskid = $(this).closest('li.task').find('input[name=id').attr('value');
    NIRV.highlight_tasks([taskid]);
  });

  $('body').on('click', 'li.task.edit .cancel', function () {
    var taskid = $(this).parents('li.task').attr('taskid');
    if (NIRV.tasks[taskid] != undefined) {
      NIRV.tasks[taskid].redraw();
    } else {
      $('input').blur();
      NIRV.refreshMain();
    }
    if ($('#newtask').dialog() !== undefined) {
      $('#newtask').dialog('close');
      $('#newtask').dialog('destroy');
    }
    NIRV.autosave = true;
    NIRV.reflow();
    NIRV.highlight_tasks([taskid]);
  });

  $('body').on('click', 'li.task.edit div.set_state', function () {
    $('li.task.edit div.state').removeClass('selected');
    $(this).addClass('selected');
    var li = $(this).closest('li.task');
    li.find('input[name=state]').val($(this).attr('state'));
    li.find('input.waitingfor').val('');
    li.find('span.waitingfor').html('');
    li.find('input.startdate').val('');
    li.find('span.startdate').html('');
  });

  $('body').on('click', '#pop .cancel', function () {
    if ($('#pop').data('ui-dialog')) {
      $('#pop').dialog('close');
    }
  });

  // RECURRNG POPUP
  $('body').on('click', 'li.task .x_recurring', function () {
    // SETUP VARS
    var li = $(this).closest('li.task');
    var taskid = li.attr('taskid');
    NIRV.promptRecurring(taskid);
  });

  $('body').on('submit', 'div.pop-recurring form.recurring', function () {
    $(
      'div.pop-recurring div.ui-dialog-buttonpane button:eicontains("save") > span'
    ).click();
    return false;
  });

  // WAITINGFOR POPUP
  $('body').on('click', 'li.task .x_waitingfor', function () {
    NIRV.promptWaitingfor($(this).closest('li.task').attr('taskid'));
  });

  // DATEPICKER POPUP
  $('body').on('click', 'li.task .x_startdate', function () {
    NIRV.promptDate($(this).closest('li.task').attr('taskid'), 'startdate');
  });

  $('body').on('click', 'li.task .x_duedate', function () {
    NIRV.promptDate($(this).closest('li.task').attr('taskid'), 'duedate');
  });

  // DEBUG
  $('body').on('click', 'li.task .debugtaskidcopy', function () {
    var id = $(this).text();
    var text = '';
    if (id) {
      text = `NIRV.tasks['${id}']`;
      navigator.clipboard.writeText(text);
      console.warn('copied to clipboard', text);
      Object.keys(NIRV.tasks[id])
        .sort()
        .forEach((key) => {
          if (typeof NIRV.tasks[id][key] != 'function' && key[0] == '_') {
            console.warn(key, NIRV.tasks[id][key]);
          }
        });
    }
  });
});
