NIRV.nKey = function () {
  if (NIRV.currentview == 'trash' || NIRV.q != '') {
    NIRV.promptNewTask('i');
  } else if (NIRV.currentview[0] == 'g') {
    NIRV.promptNewTask();
  } else {
    if ($('#main .tasklist:visible:first').hasClass('collapsed')) {
      NIRV.promptNewTask();
    } else if ($('#main .ui-selected').length > 0) {
      NIRV.goNewTask($('#main .ui-selected:last'));
    } else {
      NIRV.goNewTask();
    }
  }
};

NIRV.goNewTask = function (target, init) {
  NIRV.q = '';
  $('#north .q').val(NIRV.q).blur().removeClass('focus');
  $('.cancel').click();

  NIRV.highlight_tasks(false);
  NIRV.autosave = false;

  target = target || undefined;
  var prompt = false;
  var now = time();
  var id = NIRV.uuid();
  var type = NIRV.CONST.TASK;
  var state = NIRV.CONST.INBOX;
  var completed = 0;
  var ps = 0;
  var parentid = '';
  var seq = now;
  var seqt = 0;
  var seqp = 0;
  var name = '';
  var tags = '';
  var etime = 0;
  var energy = 0;
  var waitingfor = '';
  var recurring = '';
  var startdate = '';
  var duedate = '';
  var note = '';
  var view = NIRV.currentview;

  var atags = [];
  for (var i = 0; i < NIRV.currentfilters.tags.length; i++) {
    if (NIRV.currentfilters.tags[i] != '__NONE__') {
      //     if (view[0] == 'p' && view != 'projects') {
      //         // skip
      //     }
      //     else {
      atags.push(NIRV.currentfilters.tags[i]);
      //     }
    }
  }
  if (NIRV.currentarea != '__ALL__' && NIRV.currentarea != '__NONE__') {
    if (
      view == 'inbox' ||
      (view[0] == 'p' && view != 'projects') ||
      (view[0] == 'l' && view != 'later' && view != 'logbook')
    ) {
      // skip
    } else {
      atags.push(NIRV.currentarea);
    }
  }
  atags.unique();
  tags = ',' + atags.join(',') + ',';

  // SERVICE LEVEL CHECK
  if (view == 'projects') {
    if (NIRV.projectsCount() >= NIRV.user.maxprojects) {
      NIRV.promptUpgradeRequired('projects');
      return false;
    }
  }
  if (view == 'reflists') {
    if (NIRV.reflistsCount() >= NIRV.user.maxreflists) {
      NIRV.promptUpgradeRequired('reflists');
      return false;
    }
  }

  switch (view) {
    case 'inbox':
      state = NIRV.CONST.INBOX;
      break;
    case 'focus':
      state = NIRV.CONST.NEXT;
      seqt = now;
      break;
    case 'next':
      state = NIRV.CONST.NEXT;
      break;
    case 'later':
      state = NIRV.CONST.LATER;
      break;
    case 'waiting':
      state = NIRV.CONST.WAITING;
      waitingfor = 'Someone';
      break;
    case 'scheduled':
      state = NIRV.CONST.SCHEDULED;
      startdate = today(1);
      break;
    case 'someday':
      state = NIRV.CONST.SOMEDAY;
      break;
    case 'projects':
      state = NIRV.CONST.ACTIVEPROJECT;
      type = NIRV.CONST.PROJECT;
      // name = "New Project";
      ps = NIRV.prefs.UIDefaultProjectType.value;
      break;
    case 'reflists':
      state = NIRV.CONST.REFERENCE;
      type = NIRV.CONST.REFLIST;
      // name = "New Reference List";
      break;
    case 'logbook':
      state = NIRV.CONST.LOGGED;
      name = 'Action Completed';
      break;
    default:
      state = NIRV.CONST.INBOX;
      break;
  }

  if (view[0] == 'p' && view != 'projects') {
    parentid = view.substr(1);
    state = NIRV.CONST.NEXT;
    seqp = now;
  }

  if (view[0] == 'l' && view != 'later' && view != 'logbook') {
    parentid = view.substr(1);
    type = NIRV.CONST.REFITEM;
    state = NIRV.CONST.REFERENCE;
    // name = "New Reference Item";
    seq = now;
  }

  // IF INSERTING TASK, FURTHER AMMEND INITAL STATE, SEQUENCE, NAME ETC
  if (target) {
    var key = $(target).closest('.tasklist').attr('key').split('.');
    // var sortby = $(target).closest('ul').attr('sortby');

    // INBOX
    if (key[0] == 'inbox') {
      if (key[1] == 'completed') {
        prompt = true;
      }
    }

    // NEXT
    else if (key[0] == 'next') {
      if (key[1] == 'todo') {
        // nada
      } else if (key[1] == 'standalone') {
        // nada
      } else if (key[1] == 'seqentialdrilldown') {
        prompt = true;
      } else if (key[1].length > 30) {
        parentid = key[1];
        state = NIRV.CONST.NEXT;
        seqp = now;
      } else if (key[1] == 'completed') {
        prompt = true;
      }
    }

    // LATER
    else if (key[0] == 'later') {
      if (key[1] == 'tasks') {
        // nada
      } else if (key[1] == 'projects') {
        type = NIRV.CONST.PROJECT;
      } else if (key[1] == 'completed') {
        prompt = true;
      }
    }

    // WAITING
    else if (key[0] == 'waiting') {
      if (key[1] == 'todo') {
        // nada
      } else if (key[1] == 'completed') {
        prompt = true;
      }
    }

    // SCHEDULED
    else if (key[0] == 'scheduled') {
      prompt = true;
    }

    // SOMEDAY
    else if (key[0] == 'someday') {
      if (key[1] == 'standalone') {
        // nada
      } else if (key[1] == 'projects') {
        type = NIRV.CONST.PROJECT;
        state = NIRV.CONST.SOMEDAY;
        name = 'New Project';
        ps = NIRV.prefs.UIDefaultProjectType.value;
      } else if (key[1] == 'completed') {
        prompt = true;
      }
    }

    // FOCUS
    else if (key[0] == 'focus') {
      if (key[1] == 'inbox') {
        state = NIRV.CONST.INBOX;
      } else if (key[1] == 'next') {
        state = NIRV.CONST.NEXT;
      } else if (key[1] == 'later') {
        state = NIRV.CONST.LATER;
      } else if (key[1] == 'waiting') {
        state = NIRV.CONST.WAITING;
        waitingfor = 'Someone';
      } else if (key[1] == 'scheduled') {
        state = NIRV.CONST.SCHEDULED;
        startdate = today(1);
      } else if (key[1] == 'someday') {
        state = NIRV.CONST.SOMEDAY;
      } else if (key[1] == 'activeprojects') {
        state = NIRV.CONST.ACTIVEPROJECT;
        type = NIRV.CONST.PROJECT;
        name = 'New Project';
        ps = NIRV.prefs.UIDefaultProjectType.value;
      } else if (key[1].length > 30) {
        parentid = key[1];
        state = NIRV.CONST.NEXT;
        seqp = now;
      } else if (key[1] == 'completed') {
        prompt = true;
      }
    }

    // PROJECTS
    else if (key[0] == 'projects') {
      if (key[1] == 'active') {
        state = NIRV.CONST.ACTIVEPROJECT;
      } else if (key[1] == 'inactive') {
        state = NIRV.CONST.LATER;
      } else if (key[1] == 'scheduled') {
        state = NIRV.CONST.SCHEDULED;
        startdate = today(1);
      } else if (key[1] == 'someday') {
        state = NIRV.CONST.SOMEDAY;
      } else if (key[1] == 'completed') {
        prompt = true;
      }
    }

    // PROJECT
    else if (key[0] == 'project') {
      if (key[1] == 'nextsteps') {
        state = NIRV.CONST.NEXT;
      } else if (key[1] == 'next') {
        state = NIRV.CONST.NEXT;
      } else if (key[1] == 'later') {
        state = NIRV.CONST.LATER;
      } else if (key[1] == 'waiting') {
        state = NIRV.CONST.WAITING;
        waitingfor = 'Someone';
      } else if (key[1] == 'scheduled') {
        state = NIRV.CONST.SCHEDULED;
        startdate = today(1);
      } else if (key[1] == 'repeating') {
      } else if (key[1] == 'someday') {
        state = NIRV.CONST.SOMEDAY;
      } else if (key[1] == 'completed') {
        prompt = true;
      }
    }

    // LOGBOOK
    else if (key[0] == 'logbook') {
      prompt = true;
    }

    // TRASH
    else if (key[0] == 'trash') {
      prompt = true;
    }

    // TAGS
    else if (key[0] == 'tag') {
      prompt = true;
    }

    // SEARCH
    else if (key[0] == 'search') {
      prompt = true;
    }
  }

  if (prompt) {
    NIRV.promptNewTask(false, init);
  } else {
    // SET INITIAL VALUES
    if (undefined != init && undefined != init.task) {
      if (undefined != init.task.type) {
        type = init.task.type;
      }
      if (undefined != init.task.state) {
        state = init.task.state;
      }
      if (undefined != init.task.parentid) {
        parentid = init.task.parentid;
      }
      if (undefined != init.task.name) {
        name = init.task.name;
      }
      if (undefined != init.task.tags) {
        tags = init.task.tags;
      }
      if (undefined != init.task.etime) {
        etime = init.task.etime;
      }
      if (undefined != init.task.energy) {
        energy = init.task.energy;
      }
      if (undefined != init.task.waitingfor) {
        waitingfor = init.task.waitingfor;
      }
      if (undefined != init.task.recurring) {
        recurring = init.task.recurring;
      }
      if (undefined != init.task.startdate) {
        startdate = init.task.startdate;
      }
      if (undefined != init.task.duedate) {
        duedate = init.task.duedate;
      }
      if (undefined != init.task.note) {
        note = init.task.note;
      }
    }

    NIRV.taskedit = NIRV.Task.clone({
      id: id,
      type: type,
      _type: now,
      ps: ps,
      _ps: now,
      state: state,
      _state: now,
      completed: completed,
      _completed: now,
      parentid: parentid,
      _parentid: now,
      seq: seq,
      _seq: now,
      seqt: seqt,
      _seqt: now,
      seqp: seqp,
      _seqp: now,
      name: name,
      _name: now,
      tags: tags,
      _tags: now,
      etime: etime,
      _etime: now,
      energy: energy,
      _energy: now,
      waitingfor: waitingfor,
      _waitingfor: now,
      recurring: recurring,
      _recurring: now,
      startdate: startdate,
      _startdate: now,
      duedate: duedate,
      _duedate: now,
      note: note,
      _note: now,
      __stale__: true,
    });

    $('#tips').hide();

    if (target) {
      // INSERTION !!
      var $li = $(target).closest('li');
      // begin: workaround for jquery .before() bug
      var target_taskid = $li.attr('taskid');
      var $target_li = $('#main li[taskid=' + target_taskid + ']');
      var new_li_html = NIRV.taskedit.renderedit();
      // end: workaround for jquery .before() bug
      if (undefined != init && init.makeacopy) {
        $target_li.after(new_li_html);
      } else {
        $target_li.after(new_li_html);
        // $target_li.before(new_li_html);
      }
    } else {
      if (
        NIRV.currentview == 'focus' &&
        NIRV.prefs.UIBFocusGroupBy.value == 'state'
      ) {
        if (NIRV.currentfilters.due) {
          $('#main div.tasklist[key="focus.1"]').show();
          if (NIRV.shiftKey) {
            $('#main div.tasklist[key="focus.1"] ul.tasks').prepend(
              NIRV.taskedit.renderedit()
            );
          } else {
            $('#main div.tasklist[key="focus.1"] ul.tasks').append(
              NIRV.taskedit.renderedit()
            );
          }
        } else {
          $('#main div.tasklist[key="focus.next"]').show();
          if (NIRV.shiftKey) {
            $('#main div.tasklist[key="focus.next"] ul.tasks').prepend(
              NIRV.taskedit.renderedit()
            );
          } else {
            $('#main div.tasklist[key="focus.next"] ul.tasks').append(
              NIRV.taskedit.renderedit()
            );
          }
        }
      } else if (NIRV.currentview == 'logbook') {
        $('#main div.tasklist:first').show();
        $('#main ul.tasks:first').prepend(NIRV.taskedit.renderedit());
      } else {
        $('#main div.tasklist:first').show();
        if (NIRV.shiftKey) {
          $('#main ul.tasks:first').prepend(NIRV.taskedit.renderedit());
        } else {
          $('#main ul.tasks:first').append(NIRV.taskedit.renderedit());
        }
      }
    }

    $('#main li.task.edit').hide().fadeIn(150);

    NIRV.reflow();
    NIRV.scrollLiIntoView($('#main li.task.edit'));
    NIRV.placeholders_taskedit();
  }
};

NIRV.promptNewTask = function (flag, init) {
  NIRV.highlight_tasks(false);
  NIRV.autosave = false;

  setTimeout(function () {
    NIRV.q = '';
    $('#north .q').val(NIRV.q).blur().removeClass('focus');
    $('.cancel').click();
    NIRV.highlight_tasks(false);

    flag = flag || '';
    var now = time();
    var id = NIRV.uuid();
    var type = NIRV.CONST.TASK;
    var ps = 0;
    var state = NIRV.CONST.INBOX;
    var completed = 0;
    var parentid = '';
    var seq = now;
    var seqt = 0;
    var seqp = 0;
    var name = '';
    var tags = '';
    var etime = 0;
    var energy = 0;
    var waitingfor = '';
    var recurring = '';
    var startdate = '';
    var duedate = '';
    var note = '';
    var view = NIRV.currentview;
    var dialog_title = 'New Action';

    if (flag == 'i') {
      view = 'inbox';
    } else if (NIRV.currentview == 'reflists' && !flag) {
      view = 'inbox';
    } else if (
      NIRV.currentview[0] == 'l' &&
      NIRV.currentview != 'later' &&
      !flag
    ) {
      view = 'inbox';
    } else {
      var atags = [];
      for (var i = 0; i < NIRV.currentfilters.tags.length; i++) {
        if (NIRV.currentfilters.tags[i] != '__NONE__') {
          // if (view[0] == 'p' && view != 'projects') {
          //     // skip
          // }
          // else {
          atags.push(NIRV.currentfilters.tags[i]);
          // }
        }
      }
      if (NIRV.currentarea != '__ALL__' && NIRV.currentarea != '__NONE__') {
        if (
          (view == 'inbox' || (view[0] == 'p' && view != 'projects')) &&
          flag != 'p' &&
          flag != 'l'
        ) {
          // skip
        } else {
          atags.push(NIRV.currentarea);
        }
      }
      if (NIRV.currentview[0] == 'g') {
        atags.push(NIRV.currentview.substr(1));
      }
      for (var i = 0; i < atags.length; i++) {
        if (atags[i] == '__ALL__') {
          delete atags[i];
        }
      }
      DEBUG && console.log(atags);
      atags.unique();
      tags = ',' + atags.join(',') + ',';

      if (flag == 'p') {
        // SERVICE LEVEL CHECK
        if (NIRV.projectsCount() >= NIRV.user.maxprojects) {
          NIRV.promptUpgradeRequired('projects');
          return false;
        }
        view = 'projects';
        type = NIRV.CONST.PROJECT;
        state = NIRV.CONST.ACTIVEPROJECT;
        // name = 'New Project';
        dialog_title = 'New Project';
        ps = NIRV.prefs.UIDefaultProjectType.value;
      } else if (flag == 'l') {
        // SERVICE LEVEL CHECK
        if (NIRV.reflistsCount() >= NIRV.user.maxreflists) {
          NIRV.promptUpgradeRequired('reflists');
          return false;
        } else {
          view = 'reflists';
          type = NIRV.CONST.REFLIST;
          state = NIRV.CONST.REFERNECE;
          // name = 'New Reference List';
          dialog_title = 'New Reference List';
        }
      } else if (flag == 'f' || flag == 'F') {
        // name = "Next Action";
        type = NIRV.CONST.TASK;
        state = NIRV.CONST.NEXT;
        seqt = now;
        if (flag == 'F') {
          view = 'focus';
        }
      } else if (flag == 'x' || flag == 'X') {
        // name = "Next Action";
        type = NIRV.CONST.TASK;
        state = NIRV.CONST.NEXT;
        if (flag == 'X') {
          view = 'next';
        }
      } else if (flag == 'r' || flag == 'R') {
        // name = "Later Action";
        type = NIRV.CONST.TASK;
        state = NIRV.CONST.LATER;
        if (flag == 'R') {
          view = 'later';
        }
      } else if (flag == 'w' || flag == 'W') {
        // name = "Waiting For Action";
        type = NIRV.CONST.TASK;
        state = NIRV.CONST.WAITING;
        waitingfor = 'Someone';
        if (flag == 'W') {
          view = 'waiting';
        }
      } else if (flag == 's' || flag == 'S') {
        // name = "Scheduled Action";
        type = NIRV.CONST.TASK;
        state = NIRV.CONST.SCHEDULED;
        startdate = today(1);
        if (flag == 'S') {
          view = 'scheduled';
        }
      } else if (flag == 'y' || flag == 'Y') {
        // name = "Someday Action";
        type = NIRV.CONST.TASK;
        state = NIRV.CONST.SOMEDAY;
        if (flag == 'Y') {
          view = 'someday';
        }
      } else {
        switch (view) {
          case 'later':
            state = NIRV.CONST.LATER;
            break;
          default:
            state = NIRV.CONST.INBOX;
            break;
        }
      }
    }

    if (view[0] == 'p' && view != 'projects') {
      parentid = view.substr(1);
      // state = NIRV.CONST.NEXT;
      seqp = now;
    }
    if (view[0] == 'l' && view != 'later' && view != 'logbook') {
      parentid = view.substr(1);
      type = NIRV.CONST.REFITEM;
      state = NIRV.CONST.REFERENCE;
      // name = "New Reference Item";
      seq = now;
    }

    // SET INITIAL VALUES
    if (undefined != init && undefined != init.task) {
      if (undefined != init.task.type) {
        type = init.task.type;
      }
      if (undefined != init.task.state) {
        state = init.task.state;
      }
      if (undefined != init.task.parentid) {
        parentid = init.task.parentid;
      }
      if (undefined != init.task.name) {
        name = init.task.name;
      }
      if (undefined != init.task.tags) {
        tags = init.task.tags;
      }
      if (undefined != init.task.etime) {
        etime = init.task.etime;
      }
      if (undefined != init.task.energy) {
        energy = init.task.energy;
      }
      if (undefined != init.task.waitingfor) {
        waitingfor = init.task.waitingfor;
      }
      if (undefined != init.task.recurring) {
        recurring = init.task.recurring;
      }
      if (undefined != init.task.startdate) {
        startdate = init.task.startdate;
      }
      if (undefined != init.task.duedate) {
        duedate = init.task.duedate;
      }
      if (undefined != init.task.note) {
        note = init.task.note;
      }
    }

    NIRV.taskedit = NIRV.Task.clone({
      id: id,
      type: type,
      _type: now,
      ps: ps,
      _ps: now,
      state: state,
      _state: now,
      completed: completed,
      _completed: now,
      parentid: parentid,
      _parentid: now,
      seq: seq,
      _seq: now,
      seqt: seqt,
      _seqt: now,
      seqp: seqp,
      _seqp: now,
      name: name,
      _name: now,
      tags: tags,
      _tags: now,
      etime: etime,
      _etime: now,
      energy: energy,
      _energy: now,
      waitingfor: waitingfor,
      _waitingfor: now,
      recurring: recurring,
      _recurring: now,
      startdate: startdate,
      _startdate: now,
      duedate: duedate,
      _duedate: now,
      note: note,
      _note: now,
      __stale__: true,
    });

    var html = '';
    html += '<div class="tasklist">';
    html += '<ul class="tasks prompt" style="width:100%;">';
    html += NIRV.taskedit.renderedit('prompt');
    html += '</ul>';
    html += '</div>';

    var options = {
      autoOpen: true,
      dialogClass: 'promptnewtask',
      title: dialog_title,
      height: 'auto',
      width: 720,
      modal: true,
      resizable: false,
      position: NIRV.dialog_position_default,
      open: function () {
        NIRV.autosave = false;
      },
      close: function () {
        NIRV.autosave = true;
        $('#newtask').remove();
      },
    };
    $('body').append('<div id="newtask" class="nialog"></div>');
    $('#newtask').html(html);
    $('#newtask').dialog(options);
    $('#newtask ul.prompt li.task.edit td.task-edit-col1 .holder').css(
      'min-width',
      '700'
    );
    NIRV.placeholders_taskedit();
  }, 1);
};

NIRV.setState = function (taskid, state) {
  var task = NIRV.tasks[taskid];
  task.set('state', state);
  task.save();
  NIRV.refreshAll();
  if (NIRV.currentview == 'p' + taskid) {
    if (
      state == NIRV.CONST.INBOX ||
      state == NIRV.CONST.LOGGED ||
      state == NIRV.CONST.TRASHED
    )
      NIRV.view('projects');
  }
};

NIRV.editState = function (state) {
  $('li.task.edit input[name=state]').val(state);
  if (state == NIRV.CONST.INBOX) {
    $('li.task.edit input[name=parentid]').val('');
    $('li.task.edit input[name=waitingfor]').val('');
    $('li.task.edit input[name=recurring]').val('');
    $('li.task.edit input[name=startdate]').val('');
    $('li.task.edit div.cmenu_state').html(
      '<span class="metaicon inbox"></span>' + NIRV.L.inbox
    );
    $('li.task.edit div.cmenu_parent').html(
      '<span class="metaicon belongsto"></span>Standalone'
    );
  } else if (state == NIRV.CONST.ACTIVEPROJECT) {
    $('li.task.edit input[name=startdate]').val('');
    $('li.task.edit div.cmenu_state').html(
      '<span class="metaicon activeproject"></span>' + NIRV.L.active
    );
  } else if (state == NIRV.CONST.NEXT) {
    $('li.task.edit input[name=waitingfor]').val('');
    $('li.task.edit input[name=recurring]').val('');
    $('li.task.edit input[name=startdate]').val('');
    $('li.task.edit div.cmenu_state').html(
      '<span class="metaicon next"></span>' + NIRV.L.next
    );
  } else if (state == NIRV.CONST.LATER) {
    $('li.task.edit input[name=waitingfor]').val('');
    $('li.task.edit input[name=recurring]').val('');
    $('li.task.edit input[name=startdate]').val('');
    $('li.task.edit div.cmenu_state').html(
      '<span class="metaicon later"></span>Later'
    );
  } else if (state == NIRV.CONST.WAITING) {
    $('li.task.edit input[name=recurring]').val('');
    $('li.task.edit input[name=startdate]').val('');
    $('li.task.edit div.cmenu_state').html(
      '<span class="metaicon waiting"></span>' + NIRV.L.waiting
    );
  } else if (state == NIRV.CONST.SCHEDULED) {
    $('li.task.edit input[name=waitingfor]').val('');
    $('li.task.edit div.cmenu_state').html(
      '<span class="metaicon scheduled"></span>' + NIRV.L.scheduled
    );
  } else if (state == NIRV.CONST.RECURRING) {
    $('li.task.edit input[name=waitingfor]').val('');
    $('li.task.edit div.cmenu_state').html(
      '<span class="metaicon recurring"></span>' + NIRV.L.recurring
    );
  } else if (state == NIRV.CONST.SOMEDAY) {
    $('li.task.edit input[name=waitingfor]').val('');
    $('li.task.edit input[name=recurring]').val('');
    $('li.task.edit input[name=startdate]').val('');
    $('li.task.edit div.cmenu_state').html(
      '<span class="metaicon someday"></span>' + NIRV.L.someday
    );
  } else if (state == NIRV.CONST.LOGGED) {
    $('li.task.edit div.cmenu_state').html(
      '<span class="metaicon logbook"></span>' + NIRV.L.logbook
    );
  } else if (state == NIRV.CONST.TRASHED) {
    $('li.task.edit div.cmenu_state').html(
      '<span class="metaicon trash"></span>' + NIRV.L.trash
    );
  }
};

NIRV.setParentid = function (taskid, parentid) {
  var task = NIRV.tasks[taskid];
  if (parentid.length > 30) {
    var parent = NIRV.tasks[parentid];
    if (parent.type == NIRV.CONST.PROJECT) {
      task.set('parentid', parentid);
      task.set('type', NIRV.CONST.TASK);
      if (
        task.state == NIRV.CONST.INBOX ||
        task.state == NIRV.CONST.REFERENCE ||
        task.state == NIRV.CONST.TRASHED
      ) {
        task.set('state', NIRV.CONST.NEXT);
      }
    } else if (parent.type == NIRV.CONST.REFLIST) {
      task.set('parentid', parentid);
      task.set('type', NIRV.CONST.REFITEM);
      task.set('state', NIRV.CONST.REFERENCE);
    }
  } else {
    task.set('tags', task.__tags__());
    task.set('parentid', '');
    task.set('type', NIRV.CONST.TASK);
  }
  task.save();
  NIRV.refreshAll();
};

NIRV.editParentid = function (parentid) {
  $('li.task.edit input[name=parentid]').val(parentid);
  if (parentid == '') {
    $('li.task.edit div.cmenu_parent').html(
      '<span class="metaicon belongsto"></span>Standalone'
    );
  } else {
    $('li.task.edit div.cmenu_parent').html(
      '<span class="metaicon belongsto"></span>' + NIRV.tasks[parentid].name
    );
  }
};

NIRV.setWaitingfor = function (taskid, waitingfor) {
  var task = NIRV.tasks[taskid];
  task.set('state', NIRV.CONST.WAITING);
  task.set('waitingfor', waitingfor);
  task.save();
  NIRV.refreshAll();
};

NIRV.editWaitingfor = function (waitingfor) {
  $('li.task.edit input[name=waitingfor]').val(waitingfor);
  $('li.task.edit input[name=startdate]').val('');
  if (waitingfor == '') {
    $('li.task.edit input[name=state]').val(NIRV.CONST.NEXT);
    $('li.task.edit div.cmenu_state').html(
      '<span class="metaicon next"></span>' + NIRV.L.next
    );
  } else {
    $('li.task.edit input[name=state]').val(NIRV.CONST.WAITING);
    $('li.task.edit div.cmenu_state').html(
      '<span class="metaicon waiting"></span>Waiting for ' + waitingfor
    );
  }
};

NIRV.setPromptWaitingfor = function (newcontact) {
  var $fcbk = $('#waitingfor');
  var existing = $('form.waitingfor ul.holder li.bit-box:first').attr('rel');
  $fcbk.trigger('removeItem', [
    {
      title: existing,
      value: existing,
    },
  ]);
  $fcbk.trigger('addItem', [
    {
      title: newcontact,
      value: newcontact,
    },
  ]);
};

NIRV.promptWaitingfor = function (taskid, callback) {
  // DEBUG && console.log('promptWaitingfor(' + taskid + ')');

  // SANITY
  if (taskid == undefined) return false;
  // PREP
  // var i;
  // var atags = [];
  // for (var i in NIRV.tags) {
  //     if (NIRV.tags[i].type == NIRV.CONST.CONTACT) {
  //         atags.push(i);
  //     }
  // }
  // SETUP NIALOG
  var html = '';
  html +=
    '<form class="waitingfor" autocomplete="off" onsubmit="return false;">';
  html += '  <select id="waitingfor" name="waitingfor"></select>';
  html += '  <span class="cmenu cmenu_contacts"></span>';
  html += '</form>';
  html +=
    '<span class="inline-help">Enter the name of the person you are waiting for, or select an existing contact from the pulldown menu.</span>';
  // SETUP OPTIONS
  var w = NIRV.dimensions.windowW;
  var h = NIRV.dimensions.windowH;
  var x = NIRV.mouseX;
  var y = NIRV.mouseY;
  var top = $(document).scrollTop();
  x = w - x < 280 ? w - 280 : x - 40;
  y = y - top;
  y = h - y < 320 ? h - 320 : y - 30;
  var position_at = 'left+' + x + ' top+' + y;
  var options = {
    autoOpen: false,
    dialogClass: 'pop-waitingfor',
    title: NIRV.L.waitingfor,
    position: {
      my: 'left top',
      at: position_at,
      of: window,
    },
    height: 'auto',
    width: 300,
    modal: true,
    resizable: false,
  };
  // SETUP EVENT HANDLING BASED ON INVOCATION
  if (taskid == 'taskedit') {
    var li = $('li.task.edit');
    options.buttons = {
      Cancel: function () {
        $(this).dialog('close');
      },
      OK: function () {
        var waitingfor = $('#pop #waitingfor').val();

        // https://nirvanahq.sentry.io/issues/2801376022
        waitingfor = waitingfor[0] ? waitingfor[0].toString() : '';

        if (waitingfor == '') {
          waitingfor = $('form.waitingfor input.maininput').val();
        }
        if (waitingfor != '') {
          li.find('div.cmenu_state').html(
            '<span class="metaicon waiting"></span>Waiting for ' + waitingfor
          );
          li.find('input[name=state]').val(NIRV.CONST.WAITING);
          li.find('input[name=waitingfor]').val(waitingfor);
        } else {
          li.find('div.cmenu_state').html(
            '<span class="metaicon next"></span>Next'
          );
          li.find('input[name=state]').val(NIRV.CONST.NEXT);
          li.find('input[name=waitingfor]').val('');
        }
        $(this).dialog('close');
      },
    };
  } else {
    var taskids = typeof taskid == 'string' ? [taskid] : taskid;
    options.buttons = {
      Cancel: function () {
        $(this).dialog('close');
      },
      OK: function () {
        var waitingfor;
        var waitingfor_input = $(
          'form.waitingfor li.bit-input input.maininput'
        ).val();
        var waitingfor_select = $('form.waitingfor select#waitingfor').val();

        if (waitingfor_input.length > 0) {
          waitingfor = waitingfor_input;
        } else if (Array.isArray(waitingfor_select)) {
          waitingfor = waitingfor_select[0];
        }

        if (waitingfor == undefined) {
          waitingfor = 'Someone';
        }

        for (var i = 0; i < taskids.length; i++) {
          if (NIRV.tasks[taskids[i]] != undefined) {
            if (NIRV.tasks[taskids[i]].type == NIRV.CONST.TASK) {
              if (waitingfor != '') {
                NIRV.tasks[taskids[i]].set('state', NIRV.CONST.WAITING);
                NIRV.tasks[taskids[i]].set('waitingfor', waitingfor);
              } else {
                NIRV.tasks[taskids[i]].set('state', NIRV.CONST.NEXT);
                NIRV.tasks[taskids[i]].set('waitingfor', '');
              }
            }
          }
        }
        if (typeof callback === 'function') {
          callback();
        }

        NIRV.recalcWtasks();
        NIRV.refreshCbar();
        NIRV.refreshEast();
        NIRV.refreshMain();
        NIRV.reflow();
        $(this).dialog('close');
      },
    };
  }
  // SHOW DIALOG
  $('#pop').html(html);
  $('#pop').dialog(options);
  $('#pop').dialog('open');
  $('#pop #waitingfor')
    .fcbkcomplete({
      json_data: NIRV.fcbkcontacts(),
      filter_case: false,
      filter_hide: true,
      firstselected: true,
      filter_selected: true,
      maxitems: 1,
      complete_text: 'Enter a Contact...',
      newel: true,
    })
    .next()
    .click();
};

NIRV.promptDate = function (taskid, rel, callback) {
  // SANITY
  if (taskid == undefined) return false;
  if (rel == undefined) return false;
  var actionorproject = 'Item';

  if (taskid.indexOf(',') != -1) {
    taskid = taskid.split(',');
  }

  if (typeof taskid == 'object') {
    actionorproject = 'Items';
  } else {
    if (taskid != 'taskedit' && NIRV.tasks[taskid].type == NIRV.CONST.TASK)
      actionorproject = 'Action';
    else if (
      taskid != 'taskedit' &&
      NIRV.tasks[taskid].type == NIRV.CONST.PROJECT
    )
      actionorproject = 'Project';
  }

  // SETUP NIALOG
  var html = '';
  html +=
    '   <div class="datepicker" rel="' +
    rel +
    '" taskid="' +
    taskid +
    '"></div>';
  html += '<div style="margin: 0.7em 0 0 0;"></div>';
  if (rel == 'duedate') {
    html +=
      '<span class="inline-help datepicker-help">' +
      actionorproject +
      ' will become due and appear in Focus on the assigned date.</span>';
  } else if (rel == 'startdate' && actionorproject == 'Action') {
    html +=
      '<span class="inline-help datepicker-help">Action will move to Next and appear in Focus on the assigned date.</span>';
  } else if (rel == 'startdate' && actionorproject == 'Project') {
    html +=
      '<span class="inline-help datepicker-help">Project will become active on the assigned date. Until then all project actions will be suspended.</span>';
  } else {
    html +=
      '<span class="inline-help datepicker-help">Items will appear in Focus on the assigned date.</span>';
  }

  // SETUP DATEPICKER
  var w = NIRV.dimensions.windowW;
  var h = NIRV.dimensions.windowH;
  var x = NIRV.mouseX;
  var y = NIRV.mouseY;
  var top = $(document).scrollTop();
  x = w - x < 300 ? w - 300 : x - 40;
  y = y - top;
  y = h - y < 360 ? h - 360 : y - 30;
  var position_at = 'left+' + x + ' top+' + y;
  var options = {
    autoOpen: false,
    // dialogClass: 'pop',
    title: NIRV.L[rel],
    position: { my: 'left top', at: position_at, of: window },
    // position: NIRV.dialog_position_default,
    height: 'auto',
    width: 'auto',
    modal: true,
    resizable: false,
  };
  // SETUP EVENT HANDLING BASED ON INVOCATION
  if (taskid == 'taskedit') {
    var li = $('li.task.edit');
    options.buttons = {
      Close: function () {
        if (rel == 'startdate' && li.find('input.startdate').val() == '') {
          if (li.find('input[name=type]').val() == NIRV.CONST.PROJECT) {
            li.find('input.state').val(NIRV.CONST.ACTIVEPROJECT);
          } else {
            li.find('input.state').val(NIRV.CONST.NEXT);
          }
        }
        $(this).dialog('close');
      },
    };
    if (rel == 'startdate') {
      options.buttons['Remove Date'] = function () {
        li.find('input[name=startdate]').val('');
        if (li.find('input[name=type]').val() == NIRV.CONST.PROJECT) {
          li.find('input[name=startdate]').val('');
          li.find('input[name=state]').val(NIRV.CONST.ACTIVEPROJECT);
          li.find('div.cmenu_state').html(
            '<span class="metaicon activeproject"></span>Active'
          );
        } else if (li.find('input[name=type]').val() == NIRV.CONST.TASK) {
          li.find('input[name=startdate]').val('');
          li.find('input[name=state]').val(NIRV.CONST.NEXT);
          li.find('div.cmenu_state').html(
            '<span class="metaicon next"></span>Next'
          );
        }
        $(this).dialog('close');
      };
    }
    if (rel == 'duedate') {
      options.buttons['Remove Date'] = function () {
        li.find('input[name=duedate]').val('');
        li.find('div.cmenu_duedate').html(
          '<span class="metaicon due"></span><i>due</i>'
        );
        $(this).dialog('close');
      };
    }
  } else {
    options.buttons = {
      Cancel: function () {
        $(this).dialog('close');
      },
      'Remove Date': function () {
        var taskids = typeof taskid == 'object' ? taskid : [taskid];
        for (var i = 0; i < taskids.length; i++) {
          if (NIRV.tasks[taskids[i]] != undefined) {
            NIRV.tasks[taskids[i]].set(rel, '');
            if (rel == 'startdate') {
              NIRV.tasks[taskids[i]].set('state', NIRV.CONST.NEXT);
            }
          }
        }
        if (typeof callback === 'function') {
          callback();
        }

        NIRV.recalcWtasks();
        NIRV.refreshCbar();
        NIRV.refreshEast();
        NIRV.refreshMain();
        NIRV.reflow();
        $(this).dialog('close');
      },
    };
  }
  // SHOW DATEPICKER
  $('#pop').html(html);
  $('#pop').dialog(options);
  $('#pop').dialog('open');
  $('#pop .datepicker').data('callback', callback);

  $('div.datepicker').each(function () {
    var taskid = $(this).attr('taskid');
    var rel = $(this).attr('rel');
    var date = '';
    var options = {
      dateFormat: 'yymmdd',
      firstDay: 0,
      showWeek: false,
      showOtherMonths: true,
      selectOtherMonths: true,
    };
    if (NIRV.prefs.UIDateWeekStartsOn != undefined) {
      options.firstDay = parseInt(NIRV.prefs.UIDateWeekStartsOn.value);
    }
    if (NIRV.prefs.UIWeekNumber != undefined) {
      options.showWeek = NIRV.prefs.UIWeekNumber.value ? true : false;
    }
    // HOW WAS THIS INVOKED ?
    if (taskid == 'taskedit') {
      var li = $('li.task.edit');
      options.onSelect = function (dateText, inst) {
        if (rel == 'duedate') {
          li.find('input[name=duedate]').val(dateText);
          li.find('div.cmenu_duedate').html(
            '<span class="metaicon due"></span>Due: ' +
              ymdtolocale(dateText, 'MMMDY')
          );
          $('#pop').dialog('close');
          date = li.find('input[name="' + rel + '"]').val();
        } else if (rel == 'startdate') {
          li.find('input[name=state]').val(NIRV.CONST.SCHEDULED);
          li.find('input[name=waitingfor]').val('');
          li.find('input[name=startdate]').val(dateText);
          li.find('div.cmenu_state').html(
            '<span class="metaicon scheduled"></span>Scheduled: ' +
              ymdtolocale(dateText, 'MMMDY')
          );
          $('#pop').dialog('close');
          date = li.find('input[name="' + rel + '"]').val();
        }
      };
    } else {
      options.onSelect = function (dateText, inst) {
        var taskids = taskid.split(',');
        for (var i = 0; i < taskids.length; i++) {
          if (NIRV.tasks[taskids[i]] != undefined) {
            if (rel == 'duedate') {
              NIRV.tasks[taskids[i]].set(rel, dateText);
            }
            if (rel == 'startdate') {
              NIRV.tasks[taskids[i]].set(rel, dateText);
              NIRV.tasks[taskids[i]].set('state', NIRV.CONST.SCHEDULED);
              NIRV.tasks[taskids[i]].set('seqt', 0);
            }
          }
        }
        if ($(this).data('callback')) {
          var callback = $(this).data('callback');
          if (typeof callback == 'function') {
            callback();
          }
        }
        if (rel == 'duedate' || rel == 'startdate') {
          NIRV.recalcWtasks();
          NIRV.refreshCbar();
          NIRV.refreshEast();
          NIRV.refreshMain();
          NIRV.reflow();
          $('#pop').dialog('close');
        }
      };
      if (NIRV.tasks[taskid]) {
        if (rel == 'duedate' || rel == 'startdate') {
          date = NIRV.tasks[taskid][rel] ? NIRV.tasks[taskid][rel] : '';
        }
      }
    }
    // START DATE MUST BE IN THE FUTURE
    if (rel == 'startdate') {
      var minDate = new Date();
      minDate.setDate(minDate.getDate() + 1);
      options.minDate = datetoymd(minDate);
    }
    // RENDER
    $(this).datepicker(options);
    // DATE SHOULD BE SELECTED ON RENDER (IF SET)
    if (date != '') {
      $(this).datepicker('setDate', date.substr(0, 8));
    }
  });
};

NIRV.setPS = function (taskid, ps) {
  var ataskids = taskid.split(',');
  var task;
  for (var i = 0; i < ataskids.length; i++) {
    task = NIRV.tasks[ataskids[i]];
    task.set('ps', ps);
  }

  NIRV.refreshCurrentview();
};

NIRV.editPS = function (ps) {
  $('li.task.edit input[name=ps]').val(ps);
  if (ps == NIRV.CONST.PARALLEL) {
    $('li.task.edit div.cmenu_ps').html(
      '<span class="metaicon parallel"></span>Parallel'
    );
  } else if (ps == NIRV.CONST.SEQUENTIAL) {
    $('li.task.edit div.cmenu_ps').html(
      '<span class="metaicon sequential"></span>Sequential'
    );
  }
};

NIRV.setEnergy = function (taskid, energy) {
  var ataskids = taskid.split(',');
  var task;
  for (var i = 0; i < ataskids.length; i++) {
    task = NIRV.tasks[ataskids[i]];
    task.set('energy', energy);
    // task.redraw();
  }

  NIRV.refreshAll();
};

NIRV.editEnergy = function (energy) {
  $('li.task.edit input[name=energy]').val(energy);
  if (energy == 0) {
    $('li.task.edit div.cmenu_energy').html(
      '<span class="metaicon energy"></span><i>energy</i>'
    );
  } else {
    $('li.task.edit div.cmenu_energy').html(
      '<span class="metaicon energy"></span>' + energytostr(energy)
    );
  }
};

NIRV.setEtime = function (taskid, etime) {
  var ataskids = taskid.split(',');
  var task;
  for (var i = 0; i < ataskids.length; i++) {
    task = NIRV.tasks[ataskids[i]];
    task.set('etime', etime);
    // task.redraw();
  }

  NIRV.refreshAll();
};

NIRV.editEtime = function (etime) {
  $('li.task.edit input[name=etime]').val(etime);
  if (etime == 0) {
    $('li.task.edit div.cmenu_etime').html(
      '<span class="metaicon etime"></span><i>time</i>'
    );
  } else {
    $('li.task.edit div.cmenu_etime').html(
      '<span class="metaicon etime"></span>' + etimetostr(etime) + ' required'
    );
  }
};

NIRV.editTags = function (tag) {
  var edittags = [];
  $('li.task.edit ul.holder li.bit-box').each(function () {
    edittags.push($(this).prop('rel'));
  });
  var $fcbk = $('li.task.edit form #tags');
  if (edittags.indexOf(tag) == -1) {
    $fcbk.trigger('addItem', [
      {
        title: tag,
        value: tag,
      },
    ]);
  } else {
    $fcbk.trigger('removeItem', [
      {
        title: tag,
        value: tag,
      },
    ]);
  }
  NIRV.fcbkcomplete_applycolors();
};

NIRV.placeholders_taskedit = function () {
  setTimeout(function () {
    NIRV.fcbkcomplete(NIRV.fcbktags());
    if ($('ul li.bit-box').length == 0) {
      $('li.bit-input:first input')
        .attr('placeholder', 'Tags (areas, contacts, contexts) comma separated')
        .css('width', 376)
        .css('font-size', '12px')
        .css('padding-left', '1px')
        .css('line-height', '1.2');
      $('li.bit-input:first input').css('font-size', 'inherit');
    }
    var $name = $('li.task.edit input[name=name]:first');

    // https://nirvanahq.sentry.io/issues/2799450813
    if ($name.length > 0) {
      $name.focus().select();
      var name_length = $name.val() ? $name.val().length : 0;
      $name[0].setSelectionRange(name_length, name_length);
    }
  }, 0);
};

NIRV.fcbkcomplete = function (json_data) {
  $('li.task.edit form #tags').fcbkcomplete({
    json_data: json_data,
    filter_case: false,
    filter_hide: true,
    firstselected: true,
    filter_selected: true,
    maxitems: 25,
    height: 7,
    complete_text: 'Enter tags, comma separated',
    newel: true,
  });
};

NIRV.fcbkcomplete_applycolors = function () {
  // DEBUG && console.log('NIRV.fcbkcomplete_applycolors()');
  $('li.bit-box').each(function () {
    $(this).removeClass('color_red');
    $(this).removeClass('color_orange');
    $(this).removeClass('color_yellow');
    $(this).removeClass('color_green');
    $(this).removeClass('color_blue');
    $(this).removeClass('color_purple');
    $(this).removeClass('area');
    $(this).removeClass('contact');
    $(this).removeClass('context');
    var rel = $(this).attr('rel');
    // DEBUG && console.log(rel);
    for (var i in NIRV.tags) {
      if (i == rel) {
        $(this).addClass('color_' + NIRV.tags[i]['color']);
        if (NIRV.tags[i]['type'] == NIRV.CONST.AREA) {
          $(this).addClass('area');
        }
        if (NIRV.tags[i]['type'] == NIRV.CONST.CONTACT) {
          $(this).addClass('contact');
        }
        if (NIRV.tags[i]['type'] == NIRV.CONST.CONTEXT) {
          $(this).addClass('context');
        }
      }
    }
  });
};

NIRV.dropTasksOnList = function (incoming_taskids, rel, highlight_after) {
  DEBUG &&
    console.log('NIRV.dropTasksOnList(' + incoming_taskids + ',' + rel + ')'); // return;
  if (incoming_taskids == undefined) return; // insanity trap

  var taskids = [];
  if (Array.isArray(incoming_taskids)) {
    taskids = incoming_taskids;
  } else {
    taskids = incoming_taskids.split(',');
  }

  var now = time();
  var panic = false;
  var panic_alert = '';

  for (var i = 0; i < taskids.length; i++) {
    if (NIRV.tasks[taskids[i]] == undefined) {
      panic = true;
    }
    if (NIRV.tasks[taskids[i]] != undefined) {
      if (NIRV.tasks[taskids[i]].recurring != '' && rel == 'scheduled') {
        panic = true;
        panic_alert =
          'To edit Repeating Rules, right-click on a the repeating task or click on the task name.';
      }
    }
  }

  if (panic) {
    NIRV.highlight_tasks(false);
    if (panic_alert) {
      NIRV.alert(panic_alert);
    }
    return false;
  }

  NIRV.autosave = false;

  // SERVICE LEVEL CHECK
  if (rel == 'projects') {
    var countConvertToProject = 0;
    for (var i = 0; i < taskids.length; i++) {
      if (NIRV.tasks[taskids[i]].type == NIRV.CONST.TASK) {
        countConvertToProject++;
      }
    }
    if (countConvertToProject > NIRV.user.maxprojects - NIRV.projectsCount()) {
      NIRV.promptUpgradeRequired('projects');
      return false;
    }
  }

  // SERVICE LEVEL CHECK
  if (rel == 'reflists') {
    var countConvertToReflist = 0;
    for (var i = 0; i < taskids.length; i++) {
      if (NIRV.tasks[taskids[i]].type == NIRV.CONST.TASK) {
        countConvertToReflist++;
      }
    }
    if (countConvertToReflist > NIRV.user.maxreflists - NIRV.reflistsCount()) {
      NIRV.promptUpgradeRequired('reflists');
      return false;
    }
  }

  if (rel == 'waiting') {
    NIRV.promptWaitingfor(taskids, function () {
      // callback
      if (NIRV.prefs.UIAreaAssignment.value == '1') {
        if (NIRV.currentarea != '__ALL__' && NIRV.currentarea != '__NONE__') {
          for (var i = 0; i < taskids.length; i++) {
            if (NIRV.tasks[taskids[i]] != undefined) {
              if (
                NIRV.tasks[taskids[i]].tags.indexOf(
                  ',' + NIRV.currentarea + ','
                ) == -1
              ) {
                NIRV.tasks[taskids[i]].set(
                  'tags',
                  ',' + NIRV.currentarea + NIRV.tasks[taskids[i]].tags
                );
              }
            }
            NIRV.tasks[taskids[i]].set('seq', now + i);
          }
        }
      }
    });
  } else if (rel == 'scheduled') {
    NIRV.promptDate(taskids, 'startdate', function () {
      // callback
      if (NIRV.prefs.UIAreaAssignment.value == '1') {
        if (NIRV.currentarea != '__ALL__' && NIRV.currentarea != '__NONE__') {
          for (var i = 0; i < taskids.length; i++) {
            if (NIRV.tasks[taskids[i]] != undefined) {
              if (
                NIRV.tasks[taskids[i]].tags.indexOf(
                  ',' + NIRV.currentarea + ','
                ) == -1
              ) {
                NIRV.tasks[taskids[i]].set(
                  'tags',
                  ',' + NIRV.currentarea + NIRV.tasks[taskids[i]].tags
                );
              }
            }
          }
        }
      }
    });
  } else {
    for (var i = 0; i < taskids.length; i++) {
      var task = NIRV.tasks[taskids[i]];
      if (rel == 'inbox' || rel == 'collect') {
        if (
          task.type == NIRV.CONST.PROJECT ||
          task.type == NIRV.CONST.REFLIST
        ) {
          task.set('tags', task.__tags__());
          task.set('type', NIRV.CONST.TASK);
          task.set('state', NIRV.CONST.INBOX);
          task.set('startdate', '');
          task.set('waitingfor', '');
          for (var s in NIRV.tasks) {
            if (NIRV.tasks[s].parentid == task.id) {
              if (NIRV.tasks[s].state != NIRV.CONST.TRASHED) {
                NIRV.tasks[s].set('tags', NIRV.tasks[s].__tags__());
                NIRV.tasks[s].set('type', NIRV.CONST.TASK);
                NIRV.tasks[s].set('state', NIRV.CONST.INBOX);
                NIRV.tasks[s].set('startdate', '');
                NIRV.tasks[s].set('waitingfor', '');
              }
              NIRV.tasks[s].set('parentid', '');
            }
          }
          if (
            NIRV.currentview == 'p' + task.id ||
            NIRV.currentview == 'l' + task.id
          ) {
            NIRV.view('inbox');
          }
        } else if (
          task.type == NIRV.CONST.TASK ||
          task.type == NIRV.CONST.REFITEM
        ) {
          task.set('tags', task.__tags__());
          task.set('type', NIRV.CONST.TASK);
          task.set('state', NIRV.CONST.INBOX);
          task.set('parentid', '');
          task.set('startdate', '');
          task.set('waitingfor', '');
          task.set('recurring', '');
          task.set('seq', now + i);
        }
      } else if (rel == 'focus') {
        if (task.state != NIRV.CONST.LOGGED) {
          if (task.seqt == 0) {
            task.set('seqt', now + i);
            if (task.state == NIRV.CONST.TRASHED) {
              task.set('state', NIRV.CONST.NEXT);
            }
          }
          if (NIRV.prefs.UIAreaAssignment.value == '1') {
            if (
              NIRV.currentarea != '__ALL__' &&
              NIRV.currentarea != '__NONE__'
            ) {
              if (task.tags.indexOf(',' + NIRV.currentarea + ',') == -1) {
                task.set('tags', ',' + NIRV.currentarea + task.tags);
              }
            }
          }
        }
      } else if (rel == 'next') {
        if (task.type == NIRV.CONST.TASK) {
          task.set('state', NIRV.CONST.NEXT);
          task.set('seq', now + i);
          task.set('startdate', '');
          task.set('waitingfor', '');
          task.set('recurring', '');
          if (NIRV.prefs.UIAreaAssignment.value == '1') {
            if (
              NIRV.currentarea != '__ALL__' &&
              NIRV.currentarea != '__NONE__'
            ) {
              if (task.tags.indexOf(',' + NIRV.currentarea + ',') == -1) {
                task.set('tags', ',' + NIRV.currentarea + task.tags);
              }
            }
          }
        } else if (task.type == NIRV.CONST.PROJECT) {
          task.set('state', NIRV.CONST.ACTIVEPROJECT);
          task.set('seq', now + i);
          task.set('startdate', '');
          task.set('waitingfor', '');
          task.set('recurring', '');
        }
      } else if (rel == 'later') {
        task.set('state', NIRV.CONST.LATER);
        task.set('seq', now + i);
        task.set('waitingfor', '');
        task.set('startdate', '');
        task.set('recurring', '');
        if (NIRV.prefs.UIAreaAssignment.value == '1') {
          if (NIRV.currentarea != '__ALL__' && NIRV.currentarea != '__NONE__') {
            if (task.tags.indexOf(',' + NIRV.currentarea + ',') == -1) {
              task.set('tags', ',' + NIRV.currentarea + task.tags);
            }
          }
        }
      } else if (rel == 'someday') {
        task.set('state', NIRV.CONST.SOMEDAY);
        task.set('seq', now + i);
        task.set('waitingfor', '');
        task.set('startdate', '');
        task.set('recurring', '');
        if (NIRV.prefs.UIAreaAssignment.value == '1') {
          if (NIRV.currentarea != '__ALL__' && NIRV.currentarea != '__NONE__') {
            if (task.tags.indexOf(',' + NIRV.currentarea + ',') == -1) {
              task.set('tags', ',' + NIRV.currentarea + task.tags);
            }
          }
        }
      } else if (rel == 'projects') {
        if (task.type == NIRV.CONST.TASK || task.type == NIRV.CONST.REFITEM) {
          var parenttags = task.__parenttags__();
          task.set('parentid', '');
          task.set('seq', now + i);
          task.set('tags', '' + task.tags + parenttags);
          task.set('type', NIRV.CONST.PROJECT);
          task.set('state', NIRV.CONST.ACTIVEPROJECT);
          task.set('ps', NIRV.prefs.UIDefaultProjectType.value);
          task.set('recurring', '');
          if (NIRV.prefs.UIAreaAssignment.value == '1') {
            if (
              NIRV.currentarea != '__ALL__' &&
              NIRV.currentarea != '__NONE__'
            ) {
              if (task.tags.indexOf(',' + NIRV.currentarea + ',') == -1) {
                task.set('tags', ',' + NIRV.currentarea + task.tags);
              }
            }
          }
        } else if (task.type == NIRV.CONST.REFLIST) {
          task.set('parentid', '');
          task.set('seq', now + i);
          task.set('seqp', 0);
          task.set('type', NIRV.CONST.PROJECT);
          task.set('state', NIRV.CONST.ACTIVEPROJECT);
          task.set('ps', NIRV.prefs.UIDefaultProjectType.value);
          task.set('recurring', '');
          for (var s in NIRV.tasks) {
            if (NIRV.tasks[s].parentid == task.id) {
              NIRV.tasks[s].set('seqp', NIRV.tasks[s].seq);
              NIRV.tasks[s].set('seq', 0);
              NIRV.tasks[s].set('type', NIRV.CONST.TASK);
              NIRV.tasks[s].set('state', NIRV.CONST.NEXT);
              NIRV.tasks[s].set('ps', '');
              NIRV.tasks[s].set('startdate', '');
              NIRV.tasks[s].set('duedate', '');
              NIRV.tasks[s].set('waitingfor', '');
              NIRV.tasks[s].set('recurring', '');
            }
          }
          if (NIRV.currentview == 'l' + task.id) {
            setTimeout(function () {
              NIRV.viewproject(task.id);
            }, 10);
          }
        }
      } else if (rel == 'reflists') {
        if (task.type == NIRV.CONST.TASK || task.type == NIRV.CONST.REFITEM) {
          if (task.state != NIRV.CONST.REFERENCE) {
            task = NIRV.taskreplace(task);
          }
          var parenttags = task.__parenttags__();
          task.set('parentid', '');
          task.set('seq', now + i);
          task.set('seqp', 0);
          task.set('tags', '' + task.tags + parenttags);
          task.set('type', NIRV.CONST.REFLIST);
          task.set('state', NIRV.CONST.REFERENCE);
          task.set('ps', '');
          task.set('startdate', '');
          task.set('duedate', '');
          task.set('waitingfor', '');
          task.set('recurring', '');
          task.set('completed', 0);
          task.set('cancelled', 0);
          if (NIRV.prefs.UIAreaAssignment.value == '1') {
            if (
              NIRV.currentarea != '__ALL__' &&
              NIRV.currentarea != '__NONE__'
            ) {
              if (task.tags.indexOf(',' + NIRV.currentarea + ',') == -1) {
                task.set('tags', ',' + NIRV.currentarea + task.tags);
              }
            }
          }
        } else if (task.type == NIRV.CONST.PROJECT) {
          var original_task_id = task.id;
          task = NIRV.taskreplace(task);
          task.set('parentid', '');
          task.set('seq', now + i);
          task.set('seqp', 0);
          task.set('type', NIRV.CONST.REFLIST);
          task.set('state', NIRV.CONST.REFERENCE);
          task.set('ps', '');
          task.set('startdate', '');
          task.set('duedate', '');
          task.set('waitingfor', '');
          task.set('recurring', '');
          task.set('completed', 0);
          task.set('cancelled', 0);
          for (var s in NIRV.tasks) {
            if (NIRV.tasks[s].parentid == original_task_id) {
              NIRV.tasks[s].set('parentid', task.id);
              NIRV.tasks[s].set('seq', NIRV.tasks[s].seqp);
              NIRV.tasks[s].set('seqp', 0);
              NIRV.tasks[s].set('type', NIRV.CONST.REFITEM);
              NIRV.tasks[s].set('state', NIRV.CONST.REFERENCE);
              NIRV.tasks[s].set('ps', '');
              NIRV.tasks[s].set('startdate', '');
              NIRV.tasks[s].set('duedate', '');
              NIRV.tasks[s].set('waitingfor', '');
              NIRV.tasks[s].set('recurring', '');
              NIRV.tasks[s].set('completed', 0);
              NIRV.tasks[s].set('cancelled', 0);
              NIRV.taskreplace(NIRV.tasks[s]);
              NIRV.tasks[s].set('parentid', '');
            }
          }
          if (NIRV.currentview == 'p' + original_task_id) {
            setTimeout(function () {
              NIRV.viewreflist(task.id);
            }, 10);
          }
        }
      } else if (rel == 'logbook') {
        if (task.recurring != '') {
          return false;
        }
        if (task.type == NIRV.CONST.PROJECT) {
          task.set('state', NIRV.CONST.LOGGED);
          task.set('seqt', 0);
          if (task.completed == 0) {
            task.set('completed', time());
            // task.set('cancelled', 1);
          }
          for (var s in NIRV.tasks) {
            if (NIRV.tasks[s].parentid == task.id) {
              NIRV.tasks[s].set('state', NIRV.CONST.LOGGED);
              task.set('seqt', 0);
              if (NIRV.tasks[s].completed == 0) {
                NIRV.tasks[s].set('completed', time());
                // NIRV.tasks[s].set('cancelled', 1);
              }
            }
          }
          if (NIRV.currentview == 'p' + task.id) {
            $('#east a.projects').trigger($.Event(NIRV.mousedown));
          }
        } else if (task.type == NIRV.CONST.TASK) {
          task.set('state', NIRV.CONST.LOGGED);
          task.set('seqt', 0);
          if (task.completed == 0) {
            task.set('completed', time());
            // task.set('cancelled', 1);
          }
        }
      } else if (rel == 'trash') {
        if (
          task.type == NIRV.CONST.PROJECT ||
          task.type == NIRV.CONST.REFLIST
        ) {
          task.set('type', NIRV.CONST.TASK);
          task.set('state', NIRV.CONST.TRASHED);
          task.set('seqt', 0);
          task.set('parentid', '');
          for (var s in NIRV.tasks) {
            if (NIRV.tasks[s].parentid == task.id) {
              NIRV.tasks[s].set('type', NIRV.CONST.TASK);
              NIRV.tasks[s].set('state', NIRV.CONST.TRASHED);
              NIRV.tasks[s].set('seqt', 0);
              NIRV.tasks[s].set('parentid', '');
            }
          }
          if (NIRV.currentview == 'p' + task.id) {
            $('#east .view.projects').trigger($.Event(NIRV.mousedown));
          }
          if (NIRV.currentview == 'l' + task.id) {
            $('#east .view.reflists').trigger($.Event(NIRV.mousedown));
          }
        } else if (
          task.type == NIRV.CONST.TASK ||
          task.type == NIRV.CONST.REFITEM
        ) {
          task.set('type', NIRV.CONST.TASK);
          task.set('state', NIRV.CONST.TRASHED);
          task.set('seqt', 0);
          task.set('parentid', '');
        }
      } else if (rel[0] == 'p') {
        if (task.type == NIRV.CONST.TASK) {
          task.set('parentid', rel.substr(1));
          if (
            task.state == NIRV.CONST.INBOX ||
            task.state == NIRV.CONST.TRASHED
          ) {
            task.set('state', NIRV.CONST.NEXT);
          }
          task.set('seqp', now + i);
        }
      } else if (rel[0] == 'l') {
        if (task.type != NIRV.CONST.PROJECT) {
          if (task.state != NIRV.CONST.REFERENCE) {
            task = NIRV.taskreplace(task);
          }
          task.set('parentid', rel.substr(1));
          task.set('type', NIRV.CONST.REFITEM);
          task.set('state', NIRV.CONST.REFERENCE);
          task.set('seq', now + i);
          task.set('ps', 0);
          task.set('energy', 0);
          task.set('etime', 0);
          task.set('duedate', '');
          task.set('startdate', '');
          task.set('recurring', '');
          task.set('waitingfor', '');
        }
      }
    }
  }

  NIRV.selecteditems = '';

  NIRV.save();
  NIRV.refreshAll();

  if (Array.isArray(highlight_after)) {
    NIRV.highlight_tasks(highlight_after);
  }

  NIRV.autosave = true;
};

NIRV.appendsForTaskId = function (taskid) {
  var appends = [];
  for (var i in NIRV.appends) {
    if (NIRV.appends[i].taskid == taskid && NIRV.appends[i].deleted == 0) {
      appends.push(NIRV.appends[i]);
    }
  }
  return appends;
};
