// LISTENERS NORTH
$(function () {
  // NEW TASK
  $('body').on('click', '#north a.newtask', function (e) {
    if (!$(e.target).hasClass('chev') && !$(e.target).hasClass('xcmenu')) {
      NIRV.nKey();
    }
  });

  // NEW ITEM MENU
  $('body').on('click', '#north a.newtask.multibutton .xcmenu', function (e) {
    if ($('#cmenu').offset().top > 0) {
      $('html').trigger('click');
    } else {
      var html = '';
      html += '<ul class="sf-menu">';
      html += '   <li>';
      html += '       <a href="#" class="cmenu submenu">&nbsp;</a>';
      html += '       <ul style="width:180px;">';
      html += '           <li><span class="label">Create New...</span></li>';
      html +=
        '           <li><a href="#" fn="NIRV.promptNewTask" p1="i">Action &nbsp;&rarr;&nbsp; Inbox<span class="hotkey">I</span></a></li>';
      html += '           <li><span class="divider"></span></li>';
      html +=
        '           <li><a href="#" fn="NIRV.promptNewTask" p1="x">Action &nbsp;&rarr;&nbsp; Next<span class="hotkey">X</span></a></li>';
      html +=
        '           <li><a href="#" fn="NIRV.promptNewTask" p1="w">Action &nbsp;&rarr;&nbsp; Waiting<span class="hotkey">W</span></a></li>';
      html +=
        '           <li><a href="#" fn="NIRV.promptNewTask" p1="s">Action &nbsp;&rarr;&nbsp; Scheduled<span class="hotkey">S</span></a></li>';
      html +=
        '           <li><a href="#" fn="NIRV.promptNewTask" p1="y">Action &nbsp;&rarr;&nbsp; Someday<span class="hotkey">Y</span></a></li>';
      html += '           <li><span class="divider"></span></li>';
      html +=
        '           <li><a href="#" fn="NIRV.promptNewTask" p1="f">Action &nbsp;&rarr;&nbsp; Focus<span class="hotkey">F</span></a></li>';
      html += '           <li><span class="divider"></span></li>';
      html +=
        '           <li><a href="#" fn="NIRV.promptNewTask" p1="p">Project<span class="hotkey">P</span></a></li>';
      html +=
        '           <li><a href="#" fn="NIRV.promptNewTask" p1="l">Reference List<span class="hotkey">L</span></a></li>';
      html += '           <li><span class="divider"></span></li>';
      html +=
        '           <li><a href="#" fn="NIRV.manageTags">Ta<u>g</u><span class="hotkey">G</span></a></li>';
      html += '       </ul>';
      html += '   </li>';
      html += '</ul>';
      $('#cmenu').html(html);
      $('#cmenu').css('top', $(this).offset().top - $(window).scrollTop() + 30);
      $('#cmenu').css(
        'left',
        $(this).closest('.multibutton').offset().left + 166
      );
      $('#cmenu').css('z-index', 2000);
    }
  });

  // SEARCH
  $('body').on('focus', '#north input.q', function () {
    $(this).addClass('focus');
    clearInterval(NIRV.qinterval);
  });
  $('body').on('blur', '#north input.q', function () {
    $(this).removeClass('focus');
    clearInterval(NIRV.qinterval);
  });
  $('body').on('keyup', '#north form.search input.q', function (e) {
    clearTimeout(NIRV.qinterval);
    if (e.keyCode == 9) {
      // tab: ignore
    } else {
      NIRV.currentfilters.tags = [];
      NIRV.currentfilters.due = false;
      NIRV.qnow = $('#north form.search input.q').val().trim();
      if (NIRV.qnow == '' || NIRV.qnow == '-') {
        clearTimeout(NIRV.qinterval);
      } else {
        clearTimeout(NIRV.qinterval);
        NIRV.qinterval = setTimeout(function () {
          if (NIRV.q != NIRV.qnow) {
            NIRV.q = NIRV.qnow;
            $('#east li.on').removeClass('on');
            if (location.hash != '#search') {
              history.pushState(null, null, '#search');
            }
            clearTimeout(NIRV.qinterval);
            NIRV.search(NIRV.q);
          }
        }, 400);
      }
    }
    e.preventDefault();
  });

  // CLEAN UP
  $('body').on('click', '#north a.cleanup', function () {
    NIRV.collectCompleted(true);
  });

  // UPGRADE
  $('body').on('click', '#north a.upgrade', function () {
    NIRV.gotoAccount('upgrade');
  });

  // REFRESH
  $('body').on('click', '#north a.sync, #north .logo', function (e) {
    if (e.shiftKey) {
      NIRV.confirm(
        'Re-sync all Nirvana data on this device with the cloud? Depending on the amount of data you have it may take a few extra moments to complete.',
        () => {
          // ready, set
          NIRV.processNightly();
          NIRV.setSince(0);
          NIRV.must_sync = true;
          // go!
          NIRV.hard_sync();
          $('#north a.sync').addClass('inprogress');
          setTimeout(function () {
            $('#north a.sync').removeClass('inprogress');
          }, 2000);
        }
      );
    } else {
      // ready, set
      NIRV.setSince(0);
      NIRV.must_sync = true;
      // go
      NIRV.sync();
      $('#north a.sync').addClass('inprogress');
      setTimeout(function () {
        $('#north a.sync').removeClass('inprogress');
      }, 2000);
    }
  });

  // SETTINGS MENU
  $('body').on('click', '#north a.settingsmenu', function (e) {
    if ($('#cmenu').offset().top > 0) {
      $('html').trigger('click');
    } else {
      var html = NIRV.renderSettingsMenu();
      $('#cmenu').html(html);
      $('#cmenu').css('top', $(this).offset().top - $(window).scrollTop() + 30);
      $('#cmenu').css('left', $(window).width() - 8);
      $('#cmenu').css('z-index', 2000);
    }
  });
});
