// LISTENERS LOGIN

$(function () {
  $(document).on('click', '.notme', function (e) {
    e.preventDefault();
    NIRV.storage.setItem('u', '');
    NIRV.goLogin();
  });

  $(document).on('submit', '#login form', function (e) {
    DEBUG && console.log('login submit');
    NIRV.ready = false;

    $('html').addClass('loading');

    var gmtoffset = $(this).find('input[name=gmtoffset]').val();
    var username = $(this).find('input[name=username]').val();
    var password = md5($(this).find('input[name=password]').val());
    var data = {
      method: 'auth.new',
      gmtoffset: gmtoffset,
      u: username,
      p: password,
    };

    $.ajaxSetup({
      contentType: 'application/x-www-form-urlencoded',
      processData: true,
    });

    NIRV.post(
      NIRV.apiurlbuilder({ api: 'rest' }),
      data,
      function (json) {
        if (json.results[0].error != undefined) {
          if (json.results[0].error.code == 98) {
            $('#login form div.error').html(
              '<span>Incorrect password, please try again.</span>'
            );
            $('#login form input[name=password]').val('');
          } else if (json.results[0].error.code == 13) {
            location.href = NIRV.baseurl_app + 'reset';
          } else {
            NIRV.alert(
              'Nirvana appears to be temporarily unavailable. Please try again in a few minutes?'
            );
          }
        } else {
          DEBUG && console.log('Login Success');

          // save 'u' for logging in next time
          NIRV.storage.setItem('u', username);

          // set authtoken
          NIRV.authtoken = json.results[0].auth.token;
          NIRV.storage.setItem('authtoken', NIRV.authtoken);

          // let's go
          NIRV.must_sync = true;
          NIRV.goApp();
        }
      },
      'json'
    );

    return false; // kill default form submit
  });
});
