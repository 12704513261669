NIRV.refreshCbar = function () {
  DEBUG && console.log(' NIRV.refreshCbar()');

  NIRV.autosave = false;

  $('#cbar').empty();

  if (NIRV.currentview == 'inbox')
    $('#cbar').prepend("<h2>Inbox</h2><p>new & unprocessed to-do's</p>");

  if (NIRV.currentview == 'focus')
    $('#cbar').prepend(`<h2>Focus</h2><p>today</p>`);
  if (NIRV.currentview == 'next')
    $('#cbar').prepend("<h2>Next</h2><p>to-do's for anytime</p>");
  if (NIRV.currentview == 'later')
    $('#cbar').prepend("<h2>Later</h2><p>to-do's for later</p>");
  if (NIRV.currentview == 'scheduled')
    $('#cbar').prepend('<h2>Scheduled</h2><p>... for a future date</p>');
  if (NIRV.currentview == 'someday')
    $('#cbar').prepend('<h2>Someday</h2><p>(maybe)</p>');
  if (NIRV.currentview == 'waiting')
    $('#cbar').prepend('<h2>Waiting</h2><p>... for someone / on hold</p>');

  if (NIRV.currentview == 'projects')
    $('#cbar').prepend(
      "<h2>Projects</h2><p>... for goals that require multiple to-do's"
    );
  if (NIRV.currentview == 'reflists')
    $('#cbar').prepend('<h2>Reference</h2><p>... for future reference</p>');

  if (NIRV.currentview == 'logbook')
    $('#cbar').prepend(
      '<h2>Logbook</h2><p>completed items get collected here</p>'
    );
  if (NIRV.currentview == 'trash')
    $('#cbar').prepend('<h2>Trash</h2><p>... to be deleted</p>');

  if (NIRV.currentview[0] == 'g' && NIRV.q == '') {
    var tag = NIRV.currentview.substr(1);
    if (tag == '__ALL__') tag = 'All';
    if (tag == '__NONE__') tag = 'No Tags';
    $('#cbar').prepend('<h2>' + tag + '</h2>');
  }

  if (
    NIRV.currentview[0] == 'p' &&
    NIRV.currentview != 'projects' &&
    NIRV.q == ''
  ) {
    var pid = NIRV.currentview.substr(1);
    if (NIRV.tasks[pid]) {
      $('#cbar').append(
        // '<h5 style="margin: 0 0 5px 5px; letter-spacing: 1px; font-size: 0.875rem; color: inherit;">PROJECT</h5>' +
        '<div class="tasklist" key="__id__' +
          pid +
          '"><ul class="tasks" sortby="seq" sortable="0">' +
          NIRV.tasks[pid].render() +
          '</ul></div>'
      );
    }
  }

  if (
    NIRV.currentview[0] == 'l' &&
    NIRV.currentview != 'later' &&
    NIRV.currentview != 'logbook' &&
    NIRV.q == ''
  ) {
    var pid = NIRV.currentview.substr(1);
    if (NIRV.tasks[pid]) {
      $('#cbar').append(
        '<div class="tasklist" key="__id__' +
          pid +
          '"><ul class="tasks" sortby="seq" sortable="0">' +
          NIRV.tasks[pid].render() +
          '</ul></div>'
      );
    }
  }

  $('#cbar').append('<ul class="filters"></ul>');

  var maintasks = {};
  var taskids = [];
  var task = {};
  var tags = '';
  var stags = '';
  var ctags = [];
  var areas = [];
  var contacts = [];
  var contexts = [];
  var tasktagcount = 0;
  var taskareatagcount = 0;
  var maintaskcount = 0;
  var noneflag = false;
  var etimeflag = false;
  var energyflag = false;
  var duecount = 0;
  var i, t;

  var xDue = function () {
    return NIRV.currentfilters.due ? 'on' : '';
  };

  var xEtime = function () {
    return NIRV.currentfilters.etime != -1 ? 'on' : '';
  };

  var xEnergy = function () {
    return NIRV.currentfilters.energy != -1 ? 'on' : '';
  };

  var xTag = function (tag) {
    return NIRV.currentfilters.tags.indexOf(tag) != -1 ? 'on' : '';
  };

  var xAll = function () {
    return true ? 'on' : '';
  };

  var xViewtoggle = function (val) {
    return NIRV.prefs.UIBTaskDetails && val == NIRV.prefs.UIBTaskDetails.value
      ? 'on'
      : '';
  };

  var due = NIRV.currentfilters.due ? 'Due' : 'Due';

  var etime = '';
  switch (NIRV.currentfilters.etime) {
    case 0:
      etime = '–';
      break;
    case 5:
      etime = '5m';
      break;
    case 10:
      etime = '10m';
      break;
    case 15:
      etime = '15m';
      break;
    case 30:
      etime = '30m';
      break;
    case 45:
      etime = '45m';
      break;
    case 60:
      etime = '1h';
      break;
    case 120:
      etime = '2h';
      break;
    case 180:
      etime = '3h';
      break;
    case 240:
      etime = '4h';
      break;
    case 360:
      etime = '6h';
      break;
    case 480:
      etime = '8h';
      break;
    case 600:
      etime = '!!';
      break;
    default:
      etime = 'Time';
  }

  var energy = '';
  switch (NIRV.currentfilters.energy) {
    case 0:
      energy = '–';
      break;
    case 1:
      energy = '•';
      break;
    case 2:
      energy = '••';
      break;
    case 3:
      energy = '•••';
      break;
    default:
      energy = 'Energy';
  }

  var taskid;
  $('#main li.task').each(function () {
    taskid = $(this).attr('taskid');
    maintasks[taskid] = NIRV.tasks[taskid];
  });

  var atags = [];
  for (i in maintasks) {
    maintaskcount++;
    task = maintasks[i];
    if (typeof task?.__tags__ == 'function') {
      atags = task.__tags__().split(',');
    }
    tasktagcount = 0;
    taskareatagcount = 0;
    if (task.etime != 0) {
      etimeflag = true;
    }
    if (task.energy != 0) {
      energyflag = true;
    }
    for (t = 0; t < atags.length; t++) {
      if (atags[t] != '') {
        ctags.push(atags[t]);
        tasktagcount++;
      }
      if (NIRV.tags[atags[t]] && NIRV.tags[atags[t]].type == NIRV.CONST.AREA) {
        taskareatagcount++;
      }
    }
    if (task.waitingfor != '') {
      ctags.push(task.waitingfor);
    }
    if (task.duedate != '' && task.completed == 0) {
      duecount++;
    }
    if (tasktagcount == 0) {
      noneflag = true;
    }
    if (NIRV.currentarea != '__ALL__' && tasktagcount == taskareatagcount) {
      noneflag = true;
    }
  }

  ctags = ctags.unique();

  var indexof = ctags.indexOf(NIRV.currentarea);
  for (i = 0; i < ctags.length; i++) {
    if (ctags[i].trim() == '') {
      // skip
    } else if (NIRV.tags[ctags[i]] != undefined) {
      if (parseInt(NIRV.tags[ctags[i]].type, 10) == NIRV.CONST.AREA) {
        if (ctags[i] != NIRV.currentarea) {
          areas.push(ctags[i]);
        }
      } else if (i != indexof) {
        contexts.push(ctags[i]);
      }
    }
  }

  areas.sort(isort);
  contexts.sort(isort);

  $('#cbar ul.filters').append('<li class="viewtoggle"></li>');
  $('#cbar ul.filters li.viewtoggle').append(
    '<a class="button fn viewtoggle tri-L single   ' +
      xViewtoggle(1) +
      '" fn="NIRV.setPref" p1="UIBTaskDetails" p2="1" p3="true" title="Hide notes"><span class="bar"></span></a>'
  );
  $('#cbar ul.filters li.viewtoggle').append(
    '<a class="button fn viewtoggle tri-C double   ' +
      xViewtoggle(2) +
      '" fn="NIRV.setPref" p1="UIBTaskDetails" p2="2" p3="true" title="Show notes preview"><span class="bar"></span></a>'
  );
  $('#cbar ul.filters li.viewtoggle').append(
    '<a class="button fn viewtoggle tri-R infinity ' +
      xViewtoggle(3) +
      '" fn="NIRV.setPref" p1="UIBTaskDetails" p2="3" p3="true" title="Show notes in full"><span class="bar"></span></a>'
  );

  if (NIRV.currentview == 'next') {
    $('#cbar ul.filters').append(
      '<li class="xcmenu options" title="Next View Options">•••</li> '
    );
  }
  if (NIRV.currentview == 'focus') {
    $('#cbar ul.filters').append(
      '<li class="xcmenu options" title="Focus View Options">•••</li> '
    );
  }
  if (NIRV.currentview == 'projects') {
    $('#cbar ul.filters').append(
      '<li class="xcmenu options" title="Project View Options">•••</li> '
    );
  }
  if (NIRV.currentview == 'reflists') {
    $('#cbar ul.filters').append(
      '<li class="xcmenu options" title="Reference View Options">•••</li> '
    );
  }
  if (NIRV.currentview == 'logbook') {
    $('#cbar ul.filters').append(
      '<li class="xcmenu options" title="Logbook Options">•••</li> '
    );
  }
  if (NIRV.currentview == 'trash') {
    $('#cbar ul.filters').append(
      '<li class="xcmenu options" title="Trash Options">•••</li> '
    );
  }

  if (maintaskcount != 0) {
    if (
      NIRV.currentfilters.tags.length != 0 ||
      NIRV.currentfilters.etime != -1 ||
      NIRV.currentfilters.energy != -1 ||
      NIRV.currentfilters.due == true
    ) {
      $('#cbar ul.filters').append(
        '<li class="tag all" title="Remove all filters (show all)">All</li> '
      );
    } else {
      $('#cbar ul.filters').append('<li class="tag all on">All</li> ');
    }
  }

  if (duecount != 0) {
    $('#cbar ul.filters').append(
      '<li class="due ' +
        xDue() +
        '" title="Filter for items that are due">' +
        due +
        '</li> '
    );
  }

  if (NIRV.currentview != 'projects') {
    if (etimeflag) {
      $('#cbar ul.filters').append(
        '<li class="xcmenu etime ' +
          xEtime() +
          '" title="Filter for available time">' +
          etime +
          '</li> '
      );
    }
    if (energyflag) {
      $('#cbar ul.filters').append(
        '<li class="xcmenu energy ' +
          xEnergy() +
          '" title="Filter for available energy">' +
          energy +
          '</li> '
      );
    }
  }

  if (areas.length != 0) {
    for (i = 0; i < areas.length; i++) {
      if (NIRV.currentview[0] == 'p' && NIRV.currentview != 'projects') {
        if (
          NIRV.tasks[NIRV.currentview.substr(1)].tags
            .split(',')
            .indexOf(areas[i]) == -1
        ) {
          $('#cbar ul.filters').append(
            '<li class="tag area ' + xTag(areas[i]) + '">' + areas[i] + '</li> '
          );
        }
      } else {
        $('#cbar ul.filters').append(
          '<li class="tag area ' + xTag(areas[i]) + '">' + areas[i] + '</li> '
        );
      }
    }
  }

  if (contexts.length != 0) {
    for (i = 0; i < contexts.length; i++) {
      if (NIRV.currentview[0] == 'p' && NIRV.currentview != 'projects') {
        if (
          NIRV.tasks[NIRV.currentview.substr(1)].tags
            .split(',')
            .indexOf(contexts[i]) == -1
        ) {
          $('#cbar ul.filters').append(
            '<li class="tag context ' +
              xTag(contexts[i]) +
              '">' +
              contexts[i] +
              '</li> '
          );
        }
      } else {
        $('#cbar ul.filters').append(
          '<li class="tag context ' +
            xTag(contexts[i]) +
            '">' +
            contexts[i] +
            '</li> '
        );
      }
    }
  }

  if (noneflag && contexts.length > 0) {
    $('#cbar ul.filters').append(
      '<li class="tag none ' +
        xTag('__NONE__') +
        '" title="Filter for items without tags">&mdash;</li> '
    );
  }

  // NIRV.reflow();
  NIRV.autosave = true;
};

NIRV.setFilter = function (key, value) {
  NIRV.currentfilters[key] = parseInt(value, 10);
  NIRV.refreshCurrentview();
  $(window).scrollTop(0);
};
