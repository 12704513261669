$(function () {
  $(document).keyup(function (e) {
    if (
      e.originalEvent.key == 'Shift' ||
      e.originalEvent.key == 'Alt' ||
      e.originalEvent.key == 'Ctrl' ||
      e.originalEvent.key == 'Meta'
    ) {
      NIRV.keyIsDown = false;
    }
  });

  $(document).keydown(function (e) {
    if (
      e.originalEvent.key == 'Shift' ||
      e.originalEvent.key == 'Alt' ||
      e.originalEvent.key == 'Ctrl' ||
      e.originalEvent.key == 'Meta'
    ) {
      NIRV.keyIsDown = true;
    }

    NIRV.shiftKey = e.shiftKey;
    NIRV.altKey = e.altKey;
    NIRV.metaKey = e.metaKey;
    NIRV.ctrlKey = e.ctrlKey;

    if (NIRV.viewport == 'app') {
      if (e.keyCode == 27) {
        // [esc] key

        NIRV.highlight_tasks(false);

        // blur search
        if ($('input.q').hasClass('focus')) {
          if ($('input.q').val() != '') {
            $('input.q').val('').focus();
          } else {
            $('input.q').val('').blur();
          }
        }

        // blur rapid entry
        else if ($('input.rapidentry').hasClass('focus')) {
          if ($('input.rapidentry').val() != '') {
            $('input.rapidentry').val('').focus();
          } else {
            $('input.rapidentry').val('').blur();
          }
        }

        // trigger click on .cancel buttons
        else if (true) {
          $('.cancel').click();
        }
      } else if (e.keyCode == 13) {
        // [return/enter] key

        if ($('.NIRV_alert').is(':visible')) return;
        if ($('.NIRV_confirm').is(':visible')) return;

        if (NIRV.metaKey || NIRV.ctrlKey || NIRV.altKey) {
          // go to selected project (trigger click)
          if (
            $(
              '#main li.task.ui-selected.project, #main li.task.ui-selected.reflist'
            ).length == 1
          ) {
            $('#main li.task.ui-selected:first .nitems')
              .css('background', '#777')
              .click();
          }

          // go to selected task's parent project (trigger click)
          else if ($('#main li.task.ui-selected.hasparent').length == 1) {
            $('#main li.task.ui-selected:first .parentname')
              .css('color', '#444')
              .click();
          }

          // save changes to task (trigger form submit)
          else if ($('li.task.edit input').is(':focus')) {
            $('input:focus').closest('form').submit();
          }

          // save changes to task (trigger form submit)
          else if ($('li.task.edit textarea').is(':focus')) {
            $('textarea:focus').closest('form').submit();
          }
        } else {
          // save changes to task (trigger form submit)
          if ($('li.task.edit input').is(':focus')) {
            $('input:focus').closest('form').submit();
          }

          // set waitingfor (trigger form submit)
          else if ($('form.waitingfor input.maininput').is(':focus')) {
            $('input:focus').closest('.ui-dialog').find('button:last').click();
          }

          // go task edit (trigger click)
          else if ($('#main li.task.ui-selected').length == 1) {
            $('#main li.task.ui-selected .name').click();
          }

          // expand/collapse #east Projects (trigger click)
          else if ($('#east h3.projects').hasClass('on')) {
            if ($('#main li.task.ui-selected').length == 0) {
              $('#east h3.projects').find('.toggle').click();
            }
          }

          // expand/collapse #east References (trigger click)
          else if ($('#east h3.reflists').hasClass('on')) {
            if ($('#main li.task.ui-selected').length == 0) {
              $('#east h3.reflists').find('.toggle').click();
            }
          }

          // go task edit (project edit #cbar)
          // else if (NIRV.currentview.substr(0, 1) == 'p' && NIRV.currentview.length > 32) {
          //    if ($('#main li.task.ui-selected').length == 0) {
          //       $('#cbar .task .name').click();
          //    }
          // }

          // go task edit (reflist edit #cbar)
          // else if (NIRV.currentview.substr(0, 1) == 'l' && NIRV.currentview.length > 32) {
          //    if ($('#main li.task.ui-selected').length == 0) {
          //       $('#cbar .task .name').click();
          //    }
          // }
        }
      } else if (e.keyCode == 9) {
        // [tab] key
        // nothin
      }
    }
  });

  // new action (inbox)
  $(document).on('keydown', null, 'i', function () {
    if (NIRV.viewport != 'app') return;
    NIRV.promptNewTask('i');
  });

  // new action (next)
  $(document).on('keydown', null, 'x', function () {
    if (NIRV.viewport != 'app') return;
    NIRV.promptNewTask('x');
  });

  // sentry error (debug)
  // $(document).on('keydown', null, 'shift+x', function () {
  //   throw new Error('Sentry Error Test');
  // });

  // new action (later)
  $(document).on('keydown', null, 'shift+l', function () {
    if (NIRV.viewport != 'app') return;
    if (NIRV.prefs.UIEnableLater && NIRV.prefs.UIEnableLater.value == 1) {
      NIRV.promptNewTask('r');
    }
  });

  // new action (waiting)
  $(document).on('keydown', null, 'w', function () {
    if (NIRV.viewport != 'app') return;
    NIRV.promptNewTask('w');
  });

  // new action (scheduled)
  $(document).on('keydown', null, 's', function () {
    if (NIRV.viewport != 'app') return;
    NIRV.promptNewTask('s');
  });

  // new action (someday)
  $(document).on('keydown', null, 'y', function () {
    if (NIRV.viewport != 'app') return;
    NIRV.promptNewTask('y');
  });

  // new action (focus)
  $(document).on('keydown', null, 'f', function () {
    if (NIRV.viewport != 'app') return;
    NIRV.promptNewTask('f');
  });

  // new project
  $(document).on('keydown', null, 'p', function () {
    if (NIRV.viewport != 'app') return;
    NIRV.promptNewTask('p');
  });

  // new reflist
  $(document).on('keydown', null, 'l', function () {
    if (NIRV.viewport != 'app') return;
    NIRV.promptNewTask('l');
  });

  // new action (t for task)
  $(document).on('keydown', null, 't', function (e) {
    if (NIRV.viewport != 'app') return;
    NIRV.promptNewTask();
  });

  // select all
  $(document).on('keydown', null, 'a meta+a ctrl+a', function () {
    if (NIRV.viewport != 'app') return;
    // $('#main .tasklist.topn').removeClass('topn');
    $('#main li.task:visible').addClass('ui-selected');
    NIRV.draggable();
  });

  // new item (contextual)
  $(document).on('keydown', null, 'n', function () {
    if (NIRV.viewport != 'app') return;
    NIRV.nKey();
  });

  // new item (contextual top)
  $(document).on('keydown', null, 'shift+n', function () {
    if (NIRV.viewport != 'app') return;
    NIRV.nKey();
  });

  // collect completed items to logbook
  $(document).on('keydown', null, 'c', function () {
    if (NIRV.viewport != 'app') return;
    NIRV.collectCompleted(true);
  });

  // save (sync)
  $(document).on('keydown', null, 'r meta+s ctrl+s', function () {
    if (NIRV.viewport != 'app') return;
    $('#north a.sync').click();
    return false;
  });

  // toggle show/hide - inline help
  $(document).on('keydown', null, 'shift+h', function () {
    if (NIRV.viewport != 'app') return;
    NIRV.toggleSidebarHelp();
  });

  // show dialog - settings
  $(document).on('keydown', null, ',', function () {
    if (NIRV.viewport != 'app') return;
    NIRV.appSettings();
  });

  // show dialog - manage tags
  $(document).on('keydown', null, 'g', function () {
    if (NIRV.viewport != 'app') return;
    NIRV.manageTags();
  });

  // show dialog - keyboard shortcuts
  $(document).on('keydown', null, 'k', function () {
    if (NIRV.viewport != 'app') return;
    NIRV.keyboardShortcuts();
  });

  // show dialog - getting started
  $(document).on('keydown', null, 'h', function () {
    if (NIRV.viewport != 'app') return;
    NIRV.gettingStarted();
  });

  // select next item
  $(document).on('keydown', null, 'down', function (e) {
    e.preventDefault(); // prevent window scroll
    if ($('#main .ui-selected').length == 0) {
      $('#main li.task:visible:first').addClass('ui-selected');
    } else {
      var $selected = $('#main .ui-selected:last');
      $('.ui-selected').removeClass('ui-selected');
      var tasks = $('#main li.task:visible');
      for (var i = 0; i < tasks.length; i++) {
        if ($selected.attr('taskid') == $(tasks[i]).attr('taskid')) {
          $(tasks[i + 1]).addClass('ui-selected');
        }
      }
    }
    NIRV.scrollLiIntoView($('#main .ui-selected'));
    NIRV.draggable();
  });

  // select previous item
  $(document).on('keydown', null, 'up', function (e) {
    e.preventDefault(); // prevent window scroll
    if ($('#main .ui-selected').length == 0) {
      $('#main li.task:visible:last').addClass('ui-selected');
    } else {
      var $selected = $('#main .ui-selected:first');
      $('.ui-selected').removeClass('ui-selected');
      var tasks = $('#main li.task:visible');
      for (var i = 0; i < tasks.length; i++) {
        if ($selected.attr('taskid') == $(tasks[i]).attr('taskid')) {
          $(tasks[i - 1]).addClass('ui-selected');
        }
      }
    }
    NIRV.scrollLiIntoView($('#main .ui-selected'));
    NIRV.draggable();
  });

  // increase selected items downwards
  $(document).on('keydown', null, 'shift+down', function (e) {
    e.preventDefault(); // prevent window scroll
    if ($('#main .ui-selected').length == 0) {
      $('#main li.task:visible:first').addClass('ui-selected');
      NIRV.scrollLiIntoView($('#main .ui-selected'));
    } else {
      var $selected = $('#main .ui-selected:last');
      var tasks = $('#main li.task:visible');
      for (var i = 0; i < tasks.length; i++) {
        if ($selected.attr('taskid') == $(tasks[i]).attr('taskid')) {
          $(tasks[i + 1]).addClass('ui-selected');
          NIRV.scrollLiIntoView($(tasks[i + 1]));
        }
      }
      NIRV.draggable();
    }
  });

  // increase selected items upwards
  $(document).on('keydown', null, 'shift+up', function (e) {
    e.preventDefault(); // prevent window scroll
    if ($('#main .ui-selected').length == 0) {
      $('#main li.task:visible:last').addClass('ui-selected');
      NIRV.scrollLiIntoView($('#main .ui-selected'));
    } else {
      var $selected = $('#main .ui-selected:first');
      var tasks = $('#main li.task:visible');
      for (var i = 0; i < tasks.length; i++) {
        if ($selected.attr('taskid') == $(tasks[i]).attr('taskid')) {
          $(tasks[i - 1]).addClass('ui-selected');
          NIRV.scrollLiIntoView($(tasks[i - 1]));
        }
      }
      NIRV.draggable();
    }
  });

  // sortable via keys -- inspired by https://codepen.io/bartz/pen/rrgyjw
  // move selected tasks down the list
  $(document).on('keydown', null, 'meta+down alt+down', function (e) {
    e.preventDefault();
    var $selected = $('#main .ui-selected');
    var resequenced = false;
    if ($selected.length > 0) {
      $selected.reverse().each(function () {
        if ($(this).closest('.ui-sortable').attr('sortable') != 'false') {
          $(this).insertAfter($(this).next());
          resequenced = true;
        }
      });
      if (resequenced) {
        NIRV.reseqeuence_and_save($selected);
      }
    }
  });

  // move selected tasks up the list
  $(document).on('keydown', null, 'meta+up alt+up', function (e) {
    e.preventDefault();
    var $selected = $('#main .ui-selected');
    var resequenced = false;
    if ($selected.length > 0) {
      $selected.each(function () {
        if ($(this).closest('.ui-sortable').attr('sortable') != 'false') {
          $(this).insertBefore($(this).prev());
          resequenced = true;
        }
      });
      if (resequenced) {
        NIRV.reseqeuence_and_save($selected);
      }
    }
  });

  // expand #east projects, #east reflists, or selected #main tasks with notes
  $(document).on('keydown', null, 'right', function (e) {
    e.preventDefault();
    var $selected = $('#main li.task.ui-selected');
    var $selected_with_note = $('#main li.task.hasnote.ui-selected');
    if ($selected.length == 0) {
      var $projects = $('#east h3.projects');
      var $reflists = $('#east h3.reflists');
      if ($projects.hasClass('on') && $projects.hasClass('collapsed')) {
        $projects.find('.toggle').click();
      } else if ($reflists.hasClass('on') && $reflists.hasClass('collapsed')) {
        $reflists.find('.toggle').click();
      }
    } else {
      var $task, task, taskid;
      $selected_with_note.each(function () {
        $task = $(this);
        taskid = $task.attr('taskid');
        task = NIRV.tasks[taskid];
        task.__viewtoggle__ = 'expand';
        $task.replaceWith(NIRV.wtasks[taskid].render());
        $('#main li.task[taskid=' + taskid + ']').addClass('ui-selected');
      });
      NIRV.recalcWtasks();
      NIRV.reflow();
      NIRV.draggable();
    }
  });

  // collapse #east projects, #east reflists, or selected #main tasks with notes
  $(document).on('keydown', null, 'left', function (e) {
    e.preventDefault(); // prevent window scroll
    var $selected = $('#main li.task.ui-selected');
    var $selected_with_note = $('#main li.task.hasnote.ui-selected');
    if ($selected.length == 0) {
      var $projects = $('#east h3.projects');
      var $reflists = $('#east h3.reflists');
      if ($projects.hasClass('on') && !$projects.hasClass('collapsed')) {
        $projects.find('.toggle').click();
      } else if ($reflists.hasClass('on') && !$reflists.hasClass('collapsed')) {
        $reflists.find('.toggle').click();
      }
    } else {
      var $task, task, taskid;
      $selected_with_note.each(function () {
        $task = $(this);
        taskid = $task.attr('taskid');
        task = NIRV.tasks[taskid];
        task.__viewtoggle__ = 'collapse';
        $task.replaceWith(NIRV.wtasks[taskid].render());
        $('#main li.task[taskid=' + taskid + ']').addClass('ui-selected');
      });
      NIRV.recalcWtasks();
      NIRV.reflow();
      NIRV.draggable();
    }
  });

  // move selected items to Trash
  $(document).on('keydown', null, 'backspace del', function () {
    var tasks = $('#main li.task.ui-selected');
    if (tasks.length) {
      var taskids = [];
      for (var i = 0; i < tasks.length; i++) {
        taskids.push($(tasks[i]).attr('taskid'));
      }
      var $prev = $('#main li.task[taskid=' + taskids[0] + ']').prev();
      var $next = $(
        '#main li.task[taskid=' + taskids[taskids.length - 1] + ']'
      ).next();
      var highlightme = false;
      if ($next.hasClass('task')) {
        highlightme = $next.attr('taskid');
      } else if ($prev.hasClass('task')) {
        highlightme = $prev.attr('taskid');
      }
      if (tasks.length > 1) {
        NIRV.confirm('Move selected items to the Trash?', () => {
          NIRV.dropTasksOnList(taskids, 'trash', [highlightme]);
        });
      } else {
        NIRV.confirm('Move selected item to the Trash?', () => {
          NIRV.dropTasksOnList(taskids, 'trash', [highlightme]);
        });
      }
    }
    return false; // prevents back button navigation
  });

  // Empty Trash
  $(document).on('keydown', null, 'meta+backspace alt+backspace', function () {
    if (NIRV.currentview == 'trash') {
      NIRV.emptyTrash();
    }
    return false; // prevents back button navigation
  });

  // View Inbox
  $(document).on('keydown', null, '1', function () {
    if (NIRV.viewport != 'app') return;
    location.hash = '#inbox';
  });

  // View Next
  $(document).on('keydown', null, '2', function () {
    if (NIRV.viewport != 'app') return;
    location.hash = '#next';
  });

  // View Later | Waiting
  $(document).on('keydown', null, '3', function () {
    if (NIRV.viewport != 'app') return;
    if (NIRV.prefs.UIEnableLater && NIRV.prefs.UIEnableLater.value == 1) {
      location.hash = '#later';
    } else {
      location.hash = '#waiting';
    }
  });

  // View Waiting | Scheduled
  $(document).on('keydown', null, '4', function () {
    if (NIRV.viewport != 'app') return;
    if (NIRV.prefs.UIEnableLater && NIRV.prefs.UIEnableLater.value == 1) {
      location.hash = '#waiting';
    } else {
      location.hash = '#scheduled';
    }
  });

  // View Scheduled | Someday
  $(document).on('keydown', null, '5', function () {
    if (NIRV.viewport != 'app') return;
    if (NIRV.prefs.UIEnableLater && NIRV.prefs.UIEnableLater.value == 1) {
      location.hash = '#scheduled';
    } else {
      location.hash = '#someday';
    }
  });

  // View Someday | Focus
  $(document).on('keydown', null, '6', function () {
    if (NIRV.viewport != 'app') return;
    if (NIRV.prefs.UIEnableLater && NIRV.prefs.UIEnableLater.value == 1) {
      location.hash = '#someday';
    } else {
      location.hash = '#focus';
    }
  });

  // View Focus | Projects
  $(document).on('keydown', null, '7', function () {
    if (NIRV.viewport != 'app') return;
    if (NIRV.prefs.UIEnableLater && NIRV.prefs.UIEnableLater.value == 1) {
      location.hash = '#focus';
    } else {
      location.hash = '#projects';
    }
  });

  // View Projects | Reference
  $(document).on('keydown', null, '8', function () {
    if (NIRV.viewport != 'app') return;
    if (NIRV.prefs.UIEnableLater && NIRV.prefs.UIEnableLater.value == 1) {
      location.hash = '#projects';
    } else {
      location.hash = '#reflists';
    }
  });

  // view logbook
  $(document).on('keydown', null, '9', function () {
    if (NIRV.viewport != 'app') return;
    location.hash = '#logbook';
  });

  // view trash
  $(document).on('keydown', null, '0', function () {
    if (NIRV.viewport != 'app') return;
    location.hash = '#trash';
  });

  // view the list above in #east
  $(document).on('keydown', null, '[', function (e) {
    if (NIRV.viewport != 'app') return;
    if (e.which == 91) return; // because windows key - wat
    var views = $('#east *[view] .view, #east .tags .tag').filter(':visible');
    var selected = 0;
    for (var i = 0; i < views.length; i++) {
      if (
        $(views[i]).parent().attr('rel') == NIRV.currentview ||
        $(views[i]).attr('rel') == NIRV.currentview
      ) {
        selected = i;
      }
    }
    if (selected > 0) {
      $(views[selected - 1]).trigger($.Event(NIRV.mousedown));
    }
    return false;
  });

  // view the list below in #east
  $(document).on('keydown', null, ']', function () {
    if (NIRV.viewport != 'app') return;
    var views = $('#east *[view] .view, #east .tags .tag').filter(':visible');
    var selected = 0;
    for (var i = 0; i < views.length; i++) {
      if (
        $(views[i]).parent().attr('rel') == NIRV.currentview ||
        $(views[i]).attr('rel') == NIRV.currentview
      ) {
        selected = i;
      }
    }
    if (selected < views.length) {
      $(views[selected + 1]).trigger($.Event(NIRV.mousedown));
    }
    return false;
  });

  // global filter for area __NONE__
  $(document).on('keydown', null, 'shift+9', function (e) {
    if (NIRV.viewport != 'app') return;
    if (NIRV.areas().length > 0) {
      NIRV.setCurrentarea('__NONE__');
    }
    e.preventDefault();
  });

  // global filter for area - alpha sorted position 1
  $(document).on('keydown', null, 'shift+1', function (e) {
    if (NIRV.viewport != 'app') return;
    if (NIRV.areas()[0] != undefined) {
      NIRV.setCurrentarea(NIRV.areas()[0]);
    }
    e.preventDefault();
  });

  // global filter for area - alpha sorted position 2
  $(document).on('keydown', null, 'shift+2', function (e) {
    if (NIRV.viewport != 'app') return;
    if (NIRV.areas()[1] != undefined) {
      NIRV.setCurrentarea(NIRV.areas()[1]);
    }
    e.preventDefault();
  });

  // global filter for area - alpha sorted position 3
  $(document).on('keydown', null, 'shift+3', function (e) {
    if (NIRV.viewport != 'app') return;
    if (NIRV.areas()[2] != undefined) {
      NIRV.setCurrentarea(NIRV.areas()[2]);
    }
    e.preventDefault();
  });

  // global filter for area - alpha sorted position 4
  $(document).on('keydown', null, 'shift+4', function (e) {
    if (NIRV.viewport != 'app') return;
    if (NIRV.areas()[3] != undefined) {
      NIRV.setCurrentarea(NIRV.areas()[3]);
    }
    e.preventDefault();
  });

  // global filter for area - alpha sorted position 5
  $(document).on('keydown', null, 'shift+5', function (e) {
    if (NIRV.viewport != 'app') return;
    if (NIRV.areas()[4] != undefined) {
      NIRV.setCurrentarea(NIRV.areas()[4]);
    }
    e.preventDefault();
  });

  // global filter for area - alpha sorted position 6
  $(document).on('keydown', null, 'shift+6', function (e) {
    if (NIRV.viewport != 'app') return;
    if (NIRV.areas()[5] != undefined) {
      NIRV.setCurrentarea(NIRV.areas()[5]);
    }
    e.preventDefault();
  });

  // global filter for area - alpha sorted position 7
  $(document).on('keydown', null, 'shift+7', function (e) {
    if (NIRV.viewport != 'app') return;
    if (NIRV.areas()[6] != undefined) {
      NIRV.setCurrentarea(NIRV.areas()[6]);
    }
    e.preventDefault();
  });

  // global filter for area - alpha sorted position 8
  $(document).on('keydown', null, 'shift+8', function (e) {
    if (NIRV.viewport != 'app') return;
    if (NIRV.areas()[7] != undefined) {
      NIRV.setCurrentarea(NIRV.areas()[7]);
    }
    e.preventDefault();
  });

  // global filter for area - show all
  $(document).on('keydown', null, 'shift+0', function (e) {
    if (NIRV.viewport != 'app') return;
    if (NIRV.areas().length > 0) {
      NIRV.setCurrentarea('__ALL__');
    }
    e.preventDefault();
  });

  // global filter for area - alpha sorted : previous area
  $(document).on('keydown', null, 'shift+[', function (e) {
    if (NIRV.viewport != 'app') return;
    var NIRV_areas = NIRV.areas();
    if (NIRV_areas.length > 0) {
      var area = '';
      var areas = [];
      areas.push('__ALL__');
      for (var i = 0; i < NIRV_areas.length; i++) {
        areas.push(NIRV_areas[i]);
      }
      areas.push('__NONE__');
      var offset = 0;
      for (var i = 0; i < areas.length; i++) {
        if (areas[i] == NIRV.currentarea) {
          offset = i;
        }
      }
      if (offset > 0) {
        area = areas[offset - 1];
        NIRV.setCurrentarea(area);
      }
    }
    e.preventDefault();
  });

  // global filter for area - alpha sorted : next area
  $(document).on('keydown', null, 'shift+]', function (e) {
    if (NIRV.viewport != 'app') return;
    var NIRV_areas = NIRV.areas();
    if (NIRV_areas.length > 0) {
      var area = '';
      var areas = [];
      areas.push('__ALL__');
      for (var i = 0; i < NIRV_areas.length; i++) {
        areas.push(NIRV_areas[i]);
      }
      areas.push('__NONE__');
      var offset = 0;
      for (var i = 0; i < areas.length; i++) {
        if (areas[i] == NIRV.currentarea) {
          offset = i;
        }
      }
      if (offset < areas.length - 1) {
        area = areas[offset + 1];
        NIRV.setCurrentarea(area);
      }
    }
    e.preventDefault();
  });

  // filter #main for items with due dates
  $(document).on('keydown', null, 'd', function () {
    if (NIRV.viewport != 'app') return;
    $('#cbar .filters .due').click();
  });

  // search :focus()
  $(document).on('keydown', null, '/', function () {
    if (NIRV.viewport != 'app') return;
    $('input.q').focus();
    return false;
  });

  // rapid entry :focus()
  $(document).on('keydown', null, 'e', function () {
    if (NIRV.viewport != 'app') return;
    $('input.rapidentry').focus();
    return false;
  });

  // rapid entry toggle show/hide
  $(document).on('keydown', null, 'shift+e', function (e) {
    if (NIRV.viewport != 'app') return;
    if (
      NIRV.prefs.UIEnableRapidEntry &&
      NIRV.prefs.UIEnableRapidEntry.value == 0
    ) {
      NIRV.setPref('UIEnableRapidEntry', '1', true);
    } else {
      NIRV.setPref('UIEnableRapidEntry', '0', true);
    }
  });

  // UNDO
  $(document).on('keydown', null, 'meta+z ctrl+z', function (e) {
    if (NIRV.viewport != 'app') return;
    // NIRV.alert('undo?')
  });

  // LOGOUT
  // $(document).on('keydown', null, 'meta+l', function (e) {
  //   if (NIRV.viewport != 'app') return;
  //   setTimeout(function () {
  //     NIRV.logout();
  //   }, 100);
  // });
});
