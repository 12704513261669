NIRV.appSettings = function () {
  NIRV.highlight_tasks(false);

  var checked_pref = function (key, value) {
    return NIRV.prefs[key] != undefined && NIRV.prefs[key].value == value
      ? 'checked'
      : '';
  };
  var selected_pref = function (key, value) {
    return NIRV.prefs[key] != undefined && NIRV.prefs[key].value == value
      ? 'selected'
      : '';
  };

  var html = '';

  html +=
    '<div style="position: absolute; bottom: 0; left: 3rem; font-size: 10px; opacity: 0.4;">';
  html += 'Nirvana v.' + NIRV.version;
  html += '</div>';

  html +=
    '<form action="#" method="post" id="preferences" onSubmit="return false;">';
  html += '    <fieldset>';
  html += '        <table width="100%">';
  html += '            <tr valign="top">';
  html += '                <td width="33%">';

  html += '                    <div class="section">';
  html += '                        <h3>Global Area Filtering</h3>';
  html += '                        <div class="caption">';
  html +=
    '                            When globally filtering by area, items without an assigned area should remain...';
  html += '                        </div>';
  html +=
    '                        <input type="radio" name="UIAreaFiltering" value="exclusive" id="UIAreaFiltering-exclusive" ' +
    checked_pref('UIAreaFiltering', 'exclusive') +
    ' /> <label for="UIAreaFiltering-exclusive">Hidden</label> &nbsp;&nbsp;';
  html +=
    '                        <input type="radio" name="UIAreaFiltering" value="inclusive" id="UIAreaFiltering-inclusive" ' +
    checked_pref('UIAreaFiltering', 'inclusive') +
    ' /> <label for="UIAreaFiltering-inclusive">Still Visible</label> &nbsp;&nbsp; ';
  html += '                        <br />';
  html += '                    </div>';

  html += '                    <div class="section">';
  html += '                        <h3>Task Counts</h3>';
  html += '                        <div class="caption">';
  html +=
    '                            By default we only display task counts that require your attention. Alternatively you can choose to show all task counts.';
  html += '                        </div>';
  html +=
    '                        <input type="radio" name="UICounts" value="" id="UICounts-default" ' +
    checked_pref('UICounts', '') +
    ' /> <label for="UICounts-default">Default</label> &nbsp;&nbsp;';
  html +=
    '                        <input type="radio" name="UICounts" value="all" id="UICounts-all" ' +
    checked_pref('UICounts', 'all') +
    ' /> <label for="UICounts-all">Show All</label> &nbsp;&nbsp; ';
  html += '                        <br />';
  html += '                    </div>';

  html += '                    <div class="section">';
  html += '                        <h3>Projects</h3>';
  html += '                        <div class="caption">';
  html += '                            When creating new projects make them...';
  html += '                        </div>';
  html +=
    '                        <input type="radio" name="UIDefaultProjectType" value="0" id="UIDefaultProjectType-parallel" ' +
    checked_pref('UIDefaultProjectType', '0') +
    ' /> <label for="UIDefaultProjectType-parallel">Parallel</label> &nbsp;&nbsp;';
  html +=
    '                        <input type="radio" name="UIDefaultProjectType" value="1" id="UIDefaultProjectType-sequential" ' +
    checked_pref('UIDefaultProjectType', '1') +
    ' /> <label for="UIDefaultProjectType-sequential">Sequential</label> &nbsp;&nbsp; ';
  html += '                        <br />';
  html += '                    </div>';

  html += '                    <div class="section">';
  html += '                        <h3>Later</h3>';
  html += '                        <div class="caption">';
  html +=
    '                            Later is not really part of GTD® but some find it useful as a middle-ground between Next and Someday.';
  html += '                        </div>';
  html +=
    '                        <input type="radio" name="UIEnableLater" value="0" id="UIEnableLater-no" ' +
    checked_pref('UIEnableLater', '0') +
    ' /> <label for="UIEnableLate-no">Off</label> &nbsp;&nbsp;';
  html +=
    '                        <input type="radio" name="UIEnableLater" value="1" id="UIEnableLater-yes" ' +
    checked_pref('UIEnableLater', '1') +
    ' /> <label for="UIEnableLater-yes">On</label> &nbsp;&nbsp; ';
  html += '                        <br />';
  html += '                    </div>';

  html += '                </td>';
  html += '                <td width="33%">';

  html += '                    <div class="section">';
  html += '                        <h3>Inbox and Areas</h3>';
  html += '                        <div class="caption">';
  html +=
    '                            When dragging tasks from your Inbox to other lists Nirvana can automatically assign these tasks to the current Area, as indicated in the top navi.';
  html += '                        </div>';
  html +=
    '                        <input type="radio" name="UIAreaAssignment" value="0" id="UIAreaAssignment-no" ' +
    checked_pref('UIAreaAssignment', '0') +
    ' /> <label for="UIAreaAssignment-no">Move tasks without assignment</label>';
  html += '                        <div style="height:5px;"></div>';
  html +=
    '                        <input type="radio" name="UIAreaAssignment" value="1" id="UIAreaAssignment-yes" ' +
    checked_pref('UIAreaAssignment', '1') +
    ' /> <label for="UIAreaAssignment-yes">Assign tasks to current Area</label>';
  html += '                        <br />';
  html += '                    </div>';

  html += '                    <div class="section">';
  html += '                        <h3>Rapid Entry</h3>';
  html += '                        <div class="caption">';
  html +=
    '                            Add items in quick succession without a mouse.';
  html += '                        </div>';
  html +=
    '                        <input type="radio" name="UIEnableRapidEntry" value="" id="UIEnableRapidEntry-no" ' +
    checked_pref('UIEnableRapidEntry', '0') +
    ' /> <label for="UIEnableRapidEntry-no">Hide</label> &nbsp;&nbsp;';
  html +=
    '                        <input type="radio" name="UIEnableRapidEntry" value="hide" id="UIEnableRapidEntry-yes" ' +
    checked_pref('UIEnableRapidEntry', '1') +
    ' /> <label for="UIEnableRapidEntry-yes">Show</label> ';
  html += '                        <br />';
  html += '                    </div>';

  html += '                    <div class="section">';
  html += '                        <h3>Logbook</h3>';
  html += '                        <div class="caption">';
  html += '                            Move completed items to the Logbook...';
  html += '                        </div>';
  html +=
    '                        <select name="UICollectCompleted" id="UICollectCompleted">';
  html += '                           <optgroup label="Log items">';
  html +=
    '                               <option value="immediately" ' +
    selected_pref('UICollectCompleted', 'immediately') +
    '>Immediately</option>';
  html +=
    '                               <option value="daily" ' +
    selected_pref('UICollectCompleted', 'daily') +
    '>Daily</option>';
  html +=
    '                               <option value="manually" ' +
    selected_pref('UICollectCompleted', 'manually') +
    '>Manually</option>';
  html += '                           </optgroup>';
  html += '                        </select><br />';
  // html += '                        <input type="radio" name="UICollectCompleted" value="immediately" id="UICollectCompleted-immediately" ' + checked_pref('UICollectCompleted', 'immediately') + ' /> <label for="UICollectCompleted-immediately">Immediately</label> &nbsp;&nbsp;';
  // html += '                        <input type="radio" name="UICollectCompleted" value="daily" id="UICollectCompleted-daily" ' + checked_pref('UICollectCompleted', 'daily') + ' /> <label for="UICollectCompleted-immediadailytely">Daily</label> &nbsp;&nbsp;';
  // html += '                        <input type="radio" name="UICollectCompleted" value="manually" id="UICollectCompleted-manually" ' + checked_pref('UICollectCompleted', 'manually') + ' /> <label for="UICollectCompleted-manually">Manually</label> &nbsp;&nbsp;';
  // html += '                        <br />';
  html += '                    </div>';

  html +=
    '                    <div class="section" rel="UIBLeaveCompletedInPlace">';
  html += '                        <h3>Completed</h3>';
  html += '                        <div class="caption">';
  html += '                            Leave completed items in place?';
  html += '                        </div>';
  html +=
    '                        <input type="radio" name="UIBLeaveCompletedInPlace" value="0" id="UIBLeaveCompletedInPlace-no" ' +
    checked_pref('UIBLeaveCompletedInPlace', '0') +
    ' /> <label for="UIBLeaveCompletedInPlace-no">No</label> &nbsp;&nbsp;';
  html +=
    '                        <input type="radio" name="UIBLeaveCompletedInPlace" value="1" id="UIBLeaveCompletedInPlace-yes" ' +
    checked_pref('UIBLeaveCompletedInPlace', '1') +
    ' /> <label for="UIBLeaveCompletedInPlace-yes">Yes</label> &nbsp;&nbsp;';
  html += '                        <br />';
  html += '                    </div>';

  html += '                </td>';
  html += '                <td width="*">';

  html += '<div class="section">';
  html += '<h3>Look &amp; Feel</h3>';
  html += '<div class="caption">';
  html += 'Customize your workspace.';
  html += '</div>';

  html += '<ul style="margin: .5rem 0;">';
  NIRV.themes.forEach((theme) => {
    html += `<li class="theme-chip fn" style="background-color: ${theme.backgroundColor}; border: 1px solid #797979; border-radius: 1rem; width: 1rem; height: 1rem; display: inline-block; margin: 0 5px 3px 0; box-shadow: 0 0 1px rgba(0,0,0,0);" fn="NIRV.setPref" p1="UITheme" p2='${theme.name}' p3="true"  />`;
  });
  html += '</ul>';

  html += '<select name="UIBAppearance" id="UIBAppearance">';
  html += '<optgroup label="Appearance">';
  html += '<option value="light" ';
  html += selected_pref('UIBAppearance', 'light') + '>Light</option>';
  html += '<option value="dark" ';
  html += selected_pref('UIBAppearance', 'dark') + '>Dark</option>';
  html += '<option disabled>───────</option>';
  html += '<option value="auto" ';
  html += selected_pref('UIBAppearance', 'auto') + '>Sync with OS</option>';
  html += '</optgroup>';
  html += '</select>';

  // html += '                        <select name="UIBThemeName" id="UIBThemeName">';
  // html += '                           <optgroup label="Color Theme">';
  // html += '                               <option value="blue" ' + selected_pref('UIBThemeName', 'blue') + '>Nirvana Blue</option>';
  // html += '                           </optgroup>';
  // html += '                           <optgroup label="Experimental Themes">';
  // html += '                               <option value="pink" ' + selected_pref('UIBThemeName', 'pink') + '>Pink</option>';
  // html += '                               <option value="tomato" ' + selected_pref('UIBThemeName', 'tomato') + '>Tomato</option>';
  // html += '                               <option value="tangerine" ' + selected_pref('UIBThemeName', 'tangerine') + '>Tangerine</option>';
  // html += '                               <option value="sand" ' + selected_pref('UIBThemeName', 'sand') + '>Sand</option>';
  // html += '                               <option value="frozen" ' + selected_pref('UIBThemeName', 'frozen') + '>Frozen</option>';
  // html += '                               <option value="forest" ' + selected_pref('UIBThemeName', 'forest') + '>Forest</option>';
  // html += '                               <option value="ocean" ' + selected_pref('UIBThemeName', 'ocean') + '>Ocean</option>';
  // html += '                               <option value="navy" ' + selected_pref('UIBThemeName', 'navy') + '>Navy</option>';
  // html += '                               <option value="plum" ' + selected_pref('UIBThemeName', 'plum') + '>Plum</option>';
  // html += '                               <option value="graphite" ' + selected_pref('UIBThemeName', 'graphite') + '>Graphite</option>';
  // html += '                               <option value="black" ' + selected_pref('UIBThemeName', 'black') + '>Black</option>';
  // html += '                           </optgroup>';
  // html += '                        </select>';

  html += '<span class="hide-darkmode hide-2025" style="white-space: nowrap">';
  html += '<select name="UIBThemeBackground" id="UIBThemeBackground">';
  html += '<optgroup label="Background">';
  html += '<option value="light" ';
  html += selected_pref('UIBThemeBackground', 'light') + '>Snow</option>';
  html += '<option value="dark" ';
  html += selected_pref('UIBThemeBackground', 'dark') + '>Slate</option>';
  html += '</optgroup>';
  html += '</select>';
  html += '</span>';

  html += '<div style="white-space: nowrap">';
  html += '<select name="UIBDensity" id="UIBDensity">';
  html += '<optgroup label="Density">';
  html += '<option value="compact" ';
  html += selected_pref('UIBDensity', 'compact') + '>Compact</option>';
  html += '<option value="comfortable" ';
  html += selected_pref('UIBDensity', 'comfortable') + '>Comfortable</option>';
  html += '</optgroup>';
  html += '</select>';

  // html +=
  //   '<select name="UIB3FontFamily" id="UIB3FontFamily" class="hide-2025">';
  // html += '<optgroup label="Font">';
  // html += '<option value="proxima-nova" ';
  // html +=
  //   selected_pref('UIB3FontFamily', 'proxima-nova') + '>Proxima Nova</option>';
  // html += '<option value="Trebuchet MS" ';
  // html +=
  //   selected_pref('UIB3FontFamily', 'Trebuchet MS') + '>Trebuchet</option>';
  // html += '</optgroup>';
  // html += '</select>';

  html += '</div>';
  html += '</div>';

  html += '                    <div class="section">';
  html += '                        <h3>Date Format</h3>';
  html += '                        <div class="caption">';
  html += '                            Which looks correct to you?';
  html += '                        </div>';
  html +=
    '                        <select name="UIDateLocale" id="UIDateLocale">';
  html += '                           <optgroup label="Date Format">';
  html +=
    '                               <option value="en-US" ' +
    selected_pref('UIDateLocale', 'en-US') +
    '>' +
    ymdtolocale(datetoymd(new Date()), 'MMMDY', 'en-US') +
    '</option>';
  html +=
    '                               <option value="en-GB" ' +
    selected_pref('UIDateLocale', 'en-GB') +
    '>' +
    ymdtolocale(datetoymd(new Date()), 'MMMDY', 'en-GB') +
    '</option>';
  html += '                           </optgroup>';
  html += '                        </select>';
  html += '                        <br />';
  html += '                    </div>';

  html += '                    <div class="section">';
  html += '                        <h3>Calendar Week</h3>';
  html += '                        <div class="caption">';
  html += '                            The first day of the week should be...';
  html += '                        </div>';
  html +=
    '                        <input type="radio" name="UIDateWeekStartsOn" value="0" id="UIDateWeekStartsOnSun" ' +
    checked_pref('UIDateWeekStartsOn', '0') +
    ' /> <label for="UIDateWeekStartsOnSun">Sunday</label> &nbsp;&nbsp; ';
  html +=
    '                        <input type="radio" name="UIDateWeekStartsOn" value="1" id="UIDateWeekStartsOnMon" ' +
    checked_pref('UIDateWeekStartsOn', '1') +
    ' /> <label for="UIDateWeekStartsOnMon">Monday</label> &nbsp;&nbsp;';
  html += '                        <br />';
  html += '                    </div>';

  html += '                    <div class="section">';
  html += '                        <h3>Due Dates</h3>';
  html += '                        <div class="caption">';
  html += '                            Display as relative days or by date.';
  html += '                        </div>';
  html +=
    '                        <input type="radio" name="UIRelativeDueDates" value="hide" id="UIRelativeDueDates-enable" ' +
    checked_pref('UIRelativeDueDates', '1') +
    ' /> <label for="UIRelativeDueDates-enable">Due in 2 days</label> &nbsp;&nbsp; ';
  html +=
    '                        <input type="radio" name="UIRelativeDueDates" value="" id="UIRelativeDueDates-disable" ' +
    checked_pref('UIRelativeDueDates', '0') +
    ' /> <label for="UIRelativeDueDates-disable">' +
    ymdtomd(datetoymd(new Date().addDays(2))) +
    '</label> &nbsp;&nbsp;';
  html += '                        <br />';
  html += '                    </div>';

  // html += '                    <div class="section">';
  // html += '                        <h3>Account Settings</h3>';
  // html += '                        <div class="caption">To change your email preferences, name or password, to upgrade or delete your account, or to export your account data, <a class="sso-account" href="'+NIRV.baseurl_account+'" target="_blank" rel="external">click here</a>.<br><br></div>';
  // html += '                    </div>';

  html += '                </td>';
  html += '            </tr>';
  html += '        </table>';
  html += '    </fieldset>';
  html += '</form>';

  var options = {
    autoOpen: false,
    dialogClass: 'preferences',
    title: 'Preferences', // + NIRV.user.emailaddress,
    height: 'auto',
    width: 900,
    modal: true,
    resizable: false,
    buttons: {
      Done: function () {
        $(this).dialog('close');
      },
    },
  };
  // $('#dialog').dialog('destroy');
  $('#dialog').html(html);
  $('#dialog').dialog(options);
  $('#dialog').dialog('open');
};
