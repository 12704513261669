// SAVE DATA LOCALLY
NIRV.save = function () {
  DEBUG && VERBOSE && console.log(' NIRV.save()');
  // DEBUG && console.log(' NIRV.fcmtoken_modified_at ' + NIRV.fcmtoken_modified_at);

  var o_jsonrequest = {};
  var a_jsonrequest = [];

  if (NIRV.fcmtoken && NIRV.fcmtoken_modified_at == NIRV.update_counter) {
    o_jsonrequest['fcmtoken'] = {
      method: 'fcmtoken.save',
      fcmtoken: NIRV.fcmtoken,
    };
  }

  for (var i in NIRV.prefs) {
    if (NIRV.prefs[i].__stale__) {
      o_jsonrequest[NIRV.prefs[i].key] = NIRV.prefs[i].save(); // to localstorage
    }
  }

  for (var i in NIRV.tasks) {
    if (NIRV.tasks[i].__stale__) {
      o_jsonrequest[NIRV.tasks[i].id] = NIRV.tasks[i].save(); // to localstorage
    }
  }

  for (var i in NIRV.tags) {
    if (NIRV.tags[i].__stale__) {
      o_jsonrequest[NIRV.tags[i].key] = NIRV.tags[i].save(); // to localstorage
    }
  }

  for (var i in NIRV.appends) {
    if (NIRV.appends[i].__stale__) {
      o_jsonrequest[NIRV.appends[i].id] = NIRV.appends[i].save(); // to localstorage
    }
  }

  for (var i in o_jsonrequest) {
    a_jsonrequest.push(o_jsonrequest[i]);
  }

  return a_jsonrequest;
};

// SYNC DATA WITH SERVER
NIRV.sync = async function () {
  DEBUG &&
    console.log(
      '---------------------------------------\nNIRV.sync()',
      NIRV.sync_counter
    );

  const b_jsonrequest = NIRV.save();
  const a_jsonrequest = [];
  let slimItem = {};

  if (b_jsonrequest.length) {
    b_jsonrequest.forEach((item) => {
      slimItem = slimObject(item, NIRV.since);
      if (Object.keys(slimItem).length > 2) {
        a_jsonrequest.push(slimItem);
      }
    });
  }

  NIRV.sync_counter += 1;

  // FAILSAFE
  if (NIRV.ajaxconnections > 0 && unixtime() - NIRV.ajax_initiated > 60) {
    NIRV.ajaxconnections = 0;
    NIRV.handle_refresh_spinner();
    console.warn(
      '  NIRV.ajaxconnections',
      NIRV.ajax_initiated,
      NIRV.ajaxconnections,
      'failsafe forced zero'
    );
  }

  // cannot sync
  // no authtoken!
  if (!NIRV.authtoken) {
    DEBUG && console.log('   nope » NIRV.authtoken missing');
    NIRV.logout();
    return;
  }

  // cannot sync right now
  // user is manipulating something in UI
  if (NIRV.mouseIsDown) {
    DEBUG && console.log('   nope » NIRV.mouseIsDown: ' + NIRV.mouseIsDown);
    return;
  }

  // cannot sync right now
  // user is manipulating something in UI
  else if (NIRV.keyIsDown) {
    DEBUG && console.log('   nope » NIRV.keyIsDown: ' + NIRV.keyIsDown);
    return;
  }

  // cannot sync right now
  // user is editing something in UI
  else if (NIRV.editing()) {
    DEBUG && console.log('   nope » NIRV.editing(): ' + NIRV.editing());
    return;
  }

  // cannot sync right now
  // user is editing something in UI
  else if (NIRV.autosave == false) {
    DEBUG && console.log('   nope » NIRV.autosave: ' + NIRV.autosave);
    return;
  }

  // cannot sync right now
  // previous ajax request has not completed yet
  else if (NIRV.ajaxconnections > 0) {
    DEBUG &&
      console.log('   nope » NIRV.ajaxconnections: ' + NIRV.ajaxconnections);
    return;
  }

  // let's do this
  else if (
    a_jsonrequest.length || // sync because we have something to sync
    NIRV.ajax_counter == 0 || // sync because we haven't yet
    NIRV.must_sync // sync because we said so :-)
  ) {
    var jsonstring = JSON.stringify(a_jsonrequest);
    var since = NIRV.nextSyncFromZero ? NIRV.login_timestamp : NIRV.since;

    DEBUG && console.log('  NEXT SYNC since = ' + since);

    let clienttime = unixtime();
    let fetch_url =
      `${NIRV.baseurl_api}/everything?` +
      `&return=everything` +
      `&since=${since}` +
      `&authtoken=${NIRV.authtoken}` +
      `&appid=${NIRV.app_info().id}` +
      `&appversion=${NIRV.app_info().version}` +
      `&clienttime=${clienttime}` +
      `&requestid=${NIRV.uuid()}` +
      '';
    let response;

    let controller = new AbortController();
    let timeoutId = setTimeout(() => {
      controller.abort();
      console.log('   fetch timeout');
    }, 90000);

    DEBUG && console.log('  POST', '/api/everything', since, clienttime);

    NIRV.ajaxconnections += 1;
    NIRV.ajax_initiated = unixtime();
    NIRV.handle_refresh_spinner();

    try {
      response = await fetch(fetch_url, {
        signal: controller.signal,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: jsonstring,
      });

      if (!response.ok) {
        DEBUG && console.log('   fetch !response.ok', response.status);
        if (response.status == 401) {
          NIRV.logout('force'); // 401 Unauthorized
        }
      } else {
        DEBUG && console.log('   fetch response ok', response.status);
        const jsonresponse = await response.json();
        if (NIRV.viewport == 'app') {
          NIRV.update(jsonresponse);
        }
      }
    } catch (error) {
      DEBUG && console.log('   fetch error', error);
      if (NIRV.viewport == 'app') {
        NIRV.processNightly();
        NIRV.hideSplashSkeleton();
        NIRV.reflow();
      }
    } finally {
      clearTimeout(timeoutId); // Clear timeout if request succeeds
      NIRV.ajax_counter += 1;
      NIRV.ajaxconnections = 0;
      NIRV.handle_refresh_spinner();
    }
  }
};

NIRV.hard_sync = function () {
  DEBUG && console.log('NIRV.hard_sync()');

  // mark all local entities as stale to be included in POST payload
  for (var i in NIRV.prefs) {
    if (NIRV.prefs[i] != undefined) {
      NIRV.prefs[i].__stale__ = true;
    }
  }
  for (var i in NIRV.tasks) {
    if (NIRV.tasks[i] != undefined) {
      NIRV.tasks[i].__stale__ = true;
    }
  }
  for (var i in NIRV.tags) {
    if (NIRV.tags[i] != undefined) {
      NIRV.tags[i].__stale__ = true;
    }
  }
  for (var i in NIRV.appends) {
    if (NIRV.appends[i] != undefined) {
      NIRV.appends[i].__stale__ = true;
    }
  }

  // set since cursor to 0 to ensure response includes all available data
  NIRV.setSince(0);

  // just do it
  NIRV.must_sync = true;
  NIRV.sync();
};

NIRV.renderSyncStatus = function () {
  var d = NIRV.diagnostics();
  var delta = time() - NIRV.since;
  var humantime = '';
  var pending = false;
  var updated = timetoDate(parseInt(NIRV.since));
  var updated_toString = updated.toString('MMM d h:mm:ss');
  updated_toString += updated.toString('H') < 12 ? ' AM' : ' PM';

  if (delta < 60) {
    humantime = 'less than a minute ago';
  } else if (delta < 91) {
    humantime = 'about a minute ago';
  } else if (delta < 3600) {
    humantime = 'about ' + Math.round(delta / 60) + ' minutes ago';
  } else {
    humantime = "it's been quite awhile now hasn't it...";
  }

  var status = 'Sync Status ';
  if (d.tasks_pending + d.tasks_pending + d.tasks_pending == 0) {
    pending = false;
    status += '• all local changes have been saved ';
  } else {
    pending = true;
    status += '• local changes pending sync: ';
    if (d.prefs_pending != 0) {
      status += 'prefs (' + d.prefs_pending + ') ';
    }
    if (d.tasks_pending != 0) {
      status += 'tasks (' + d.tasks_pending + ') ';
    }
    if (d.tags_pending != 0) {
      status += 'tags (' + d.tags_pending + ') ';
    }
  }
  status += '• last fetch from server ';
  status += '' + timetomdhi(NIRV.since) + ' (' + humantime + ')';
  $('#north a.sync').attr('title', status);

  if (pending) {
    $('#north a.sync').addClass('pending');
  } else {
    $('#north a.sync').removeClass('pending');
  }
};

NIRV.handle_refresh_spinner = function () {
  // console.log('NIRV.handle_refresh_spinner');
  if (NIRV.ajaxconnections == 0) {
    setTimeout(() => {
      $('#north a.sync').removeClass('inprogress');
    }, 800);
    NIRV.renderSyncStatus();
  } else {
    $('#north a.sync').addClass('inprogress');
  }
};
