NIRV.promptRecurring = function (taskid) {
  DEBUG && console.log('promptRecurring(' + taskid + ')');
  // SANITY
  if (taskid == undefined) return false;

  if (NIRV.user.servicelevel == 'basic' && NIRV.user.createdon > 1445659200) {
    // Sat, 24 Oct 2015 00:00:00 -0400 => 1445659200
    if (taskid == 'taskedit' || NIRV.tasks[taskid].state != 9) {
      NIRV.promptUpgradeRequired('recurring');
      return false;
    }
  }

  // SETUP NIALOG
  var html = '';
  html += '<div class="content" style="padding: 10px;">';
  html +=
    '   <form class="recurring" autocomplete="off"  onsubmit="return false;" taskid="' +
    taskid +
    '">';
  html +=
    '       <input type="hidden" class="paused" name="paused" value="false">';
  // html += '      Copies are <span class="created">created</span><span class="due">due</span>';
  html += '      Repeat';
  html += '      <select class="freq" name="freq">';
  html += '      	   <option value="aftercompletion">after completion</option>';
  html += '      	   <option disabled>—</option>';
  html += '      	   <option value="daily">daily</option>';
  html += '          <option value="weekly">weekly</option>';
  html += '          <option value="monthly">monthly</option>';
  html += '          <option value="yearly">yearly</option>';
  html += '      </select>';
  html += '   	<div class="inset">';
  html += '   		<div class="intervalafter">';
  html += '   			<div class="intervalx aftercompletion"><table></table></div>';
  html += '   		</div>';
  html += '   		<div class="interval">';
  html += '   			<div class="intervalx daily"><table></table></div>';
  html += '   			<div class="intervalx weekly"><table></table></div>';
  html += '   			<div class="intervalx monthly"><table></table></div>';
  html += '   			<div class="intervalx yearly"><table></table></div>';
  html += '   			<br>';
  html += '   			Starting ';
  html +=
    '   			<input type="text" class="yyyymmdd nextdate" name="nextdate_" value="" placeholder="(runtime)"><input type="hidden" class="nextdate_alt" name="nextdate" value="" placeholder="mm/dd/yyyy"> <span class="futuredates"></span>';
  html += '   			<span class="nextup"></span>';
  html += '   			<br>';
  html += '   			<br>';
  html += '   			<br>';
  html += '   			Ending ';
  html += '   			<select class="ends" name="ends">';
  html += '   				<option value="never">never</option>';
  html += '   				<option value="count">after</option>';
  html += '   				<option value="until">on date</option>';
  html += '   			</select>';
  html +=
    '   			<span class="until"><input type="text" class="yyyymmdd until" name="until_" value="" placeholder="(runtime)"><input type="hidden" class="until_alt" name="until" value="" placeholder="mm/dd/yyyy"></span>';
  html +=
    '   			<span class="count"><input type="text" class="count" name="count" value="1" maxlength="3"> times</span>';
  html += '   		</div>';
  html += '   	</div>';
  html += '   	<div>';
  html +=
    '   		<input type="checkbox" class="hasduedate" name="hasduedate" value="1" id="hasduedate">';
  html += '   		<label for="hasduedate">Add due dates</label>';
  html += '   		<span class="spawnxdaysbefore"> and highlight in Focus ';
  html +=
    '               <select class="spawnxdaysbefore" name="spawnxdaysbefore">';
  html += '   				<option value="0">on the same day</option>';
  html += '   				<option value="1">1 day before</option>';
  html += '   				<option value="2">2 days before</option>';
  html += '   				<option value="3">3 days before</option>';
  html += '   				<option value="4">4 days before</option>';
  html += '   				<option value="5">5 days before</option>';
  html += '   				<option value="6">6 days before</option>';
  html += '   				<option value="7">7 days before</option>';
  html += '   				<option value="8">8 days before</option>';
  html += '   				<option value="9">9 days before</option>';
  html += '   				<option value="10">10 days before</option>';
  html += '   				<option value="11">11 days before</option>';
  html += '   				<option value="12">12 days before</option>';
  html += '   				<option value="13">13 days before</option>';
  html += '   				<option value="14">14 days before</option>';
  html += '               </select>';
  html += '           </span>';
  html += '      </div>';
  html += '   </form>';
  html += '</div>';

  // SETUP OPTIONS
  var dialog_title = '';
  if (taskid == 'taskedit') {
    dialog_title = $('li.task.edit input[name=name]').val();
  } else {
    dialog_title = NIRV.tasks[taskid].name;
  }
  var options = {
    autoOpen: false,
    dialogClass: 'dialog-recurring',
    title: dialog_title.slice(0, 70),
    height: 'auto',
    width: 'auto',
    modal: true,
    resizable: true,
    open: function () {
      if ($('#newtask').parent().hasClass('ui-dialog')) {
        // $('#newtask').parent().hide();
      }
    },
    close: function () {
      if ($('#newtask').parent().hasClass('ui-dialog')) {
        // $('#newtask').parent().show();
      }
      $('#recurring').remove();
    },
  };
  // SETUP EVENT HANDLING BASED ON INVOCATION
  if (taskid == 'taskedit') {
    var li = $('li.task.edit');
    options.buttons = {
      Cancel: function () {
        $(this).dialog('close');
      },
      'Save Changes': function () {
        var recurring = NIRV.captureRecurringForm();
        console.log('Task.taskedit Save Changes', recurring);
        var recurringObj = JSON.parse(recurring);
        var nextdates = NIRV.calcnextdates(recurringObj);
        if (nextdates.length == 0 && recurringObj.freq != 'aftercompletion') {
          NIRV.alert('nothing to repeat');
          return false;
        }
        recurringObj.nextdate = nextdates[0];
        li.find('input[name=state]').val(NIRV.CONST.RECURRING);
        if (recurringObj.nextdate) {
          li.find('input[name=startdate]').val(recurringObj.nextdate);
        } else {
          li.find('input[name=startdate]').val('');
        }
        li.find('input[name=duedate]').val('');
        li.find('div.cmenu_duedate').hide();
        li.find('input[name=recurring]').val(JSON.stringify(recurringObj));
        // li.find('input[name=seqt]').val('0');
        // li.removeClass('focus');
        // li.find('div.cmenu_state').addClass('cmenu_repeating').removeClass('cmenu_state').html('<span class="metaicon recurring"></span>' + recurringtostr(recurring));
        li.find('div.cmenu_state').html(
          '<span class="metaicon recurring"></span>' + recurringtostr(recurring)
        );
        $(this).dialog('close');
      },
    };
  } else {
    var task = NIRV.tasks[taskid];
    options.buttons = {
      Cancel: function () {
        $(this).dialog('close');
        $('#recurring').remove();
      },
      'Save Changes': function () {
        var recurring = NIRV.captureRecurringForm();
        console.log(`Task ${taskid} Save Changes`, recurring);
        var recurringObj = JSON.parse(recurring);
        console.log(recurringObj);
        var nextdates = NIRV.calcnextdates(recurringObj);
        if (nextdates.length == 0 && recurringObj.freq != 'aftercompletion') {
          NIRV.alert('nothing to repeat');
          return false;
        }
        recurringObj.nextdate = nextdates[0];
        task.set('state', NIRV.CONST.RECURRING);
        if (recurringObj.nextdate) {
          task.set('startdate', recurringObj.nextdate);
        } else {
          task.set('startdate', '');
        }
        task.set('duedate', '');
        task.set('recurring', JSON.stringify(recurringObj));
        task.set('seqt', 0);
        task.save();

        NIRV.processRecurring();

        NIRV.recalcWtasks();
        NIRV.refreshCbar();
        NIRV.refreshEast();
        NIRV.refreshMain();
        NIRV.reflow();

        $(this).dialog('close');
        $('#recurring').remove();
      },
    };
  }

  // if ($('#newtask').parent().hasClass('ui-dialog')) {
  //     options.position = $("#newtask").dialog("option","position");
  // }

  options.position = NIRV.dialog_position_default;

  // SHOW DIALOG
  $('body').append('<div id="recurring" class="nialog"></div>');
  $('#recurring').html(html);
  $('#recurring').dialog(options);
  $('#recurring').dialog('open');

  NIRV.form_recurring();

  $('form.recurring input.yyyymmdd').each(function () {
    var name = $(this).attr('name');
    var nextdate = new Date();
    var options = {
      minDate: nextdate,
      firstDay: 0,
      showWeek: false,
      showOtherMonths: true,
      selectOtherMonths: true,
    };
    if (name == 'nextdate_') {
      options.altField = '.nextdate_alt';
      options.altFormat = 'mm/dd/yy';
    } else if (name == 'until_') {
      options.altField = '.until_alt';
      options.altFormat = 'mm/dd/yy';
    }
    if (NIRV.prefs.UIDateLocale != undefined) {
      switch (NIRV.prefs.UIDateLocale.value) {
        case 'en-US':
          options.dateFormat = 'mm/dd/yy';
          break;
        case 'en-GB':
          options.dateFormat = 'dd/mm/yy';
          break;
      }
      // NIRV.alert(options.dateFormat);
    }
    if (NIRV.prefs.UIDateWeekStartsOn != undefined) {
      options.firstDay = parseInt(NIRV.prefs.UIDateWeekStartsOn.value);
    }
    if (NIRV.prefs.UIWeekNumber != undefined) {
      options.showWeek = NIRV.prefs.UIWeekNumber.value ? true : false;
    }
    $(this).datepicker(options);
  });
};

NIRV.renderRecurringFormAfterCompletion = function () {
  var html = '';
  html += '<tr>';
  html += '	 <td align="right">';
  html += '    <span class="first">';
  html +=
    '      <input type="text" class="interval" name="interval" value="1" maxlength="3">';
  html += '    </span>';
  html += '	   <select class="period" name="period">';
  html += '      <option value="day">day(s)</option>';
  html += '      <option value="week">week(s)</option>';
  html += '      <option value="month">month(s)</option>';
  html += '      <option value="year">year(s)</option>';
  html += '     </select>';
  html += '     <span>after previous to-do is checked off as done.</span>';
  html += '	 </td>';
  html += '</tr>';
  return html;
};

NIRV.renderRecurringFormDaily = function () {
  var html = '';
  html += '<tr>';
  html += '	<td align="right">';
  html +=
    '		<span class="first">Every <input type="text" class="interval" name="interval" value="1" maxlength="3"> day(s) </span>';
  html += '	</td>';
  html += '</tr>';
  return html;
};

NIRV.renderRecurringFormWeekly = function (interval, on) {
  var html = '';
  html += '<tr>';
  html += '	<td align="right">';
  html +=
    '		<span class="first">Every <input type="text" class="interval" name="interval" value="1" maxlength="2"> week(s) on </span>';
  html += '		<span class="andon">and on </span>';
  html += '	</td>';
  html += '	<td>';
  html += '		<select class="day" name="day">';
  html += '			<option value="sun">Sunday</option>';
  html += '    	    <option value="mon">Monday</option>';
  html += '			<option value="tue">Tuesday</option>';
  html += '			<option value="wed">Wednesday</option>';
  html += '			<option value="thu">Thursday</option>';
  html += '			<option value="fri">Friday</option>';
  html += '			<option value="sat">Saturday</option>';
  html += '		</select>';
  html += '	    <a class="plus"></a>';
  html += '	    <a class="minus"></a>';
  html += '	</td>';
  html += '</tr>';
  return html;
};

NIRV.renderRecurringFormMonthly = function (interval, on) {
  var html = '';
  html += '<tr>';
  html += '	<td align="right">';
  html +=
    '		<span class="first">Every <input type="text" class="interval" name="interval" value="1" maxlength="2"> month(s) on the </span>';
  html += '		<span class="andon">and on the </span>';
  html += '	</td>';
  html += '	<td>';
  html += '		<select class="nth" name="nth">';
  html += '    	    <option value="1">1st</option>';
  html += '    	    <option value="2">2nd</option>';
  html += '    	    <option value="3">3rd</option>';
  html += '    	    <option value="4">4th</option>';
  html += '    	    <option value="5">5th</option>';
  html += '    	    <option value="6">6th</option>';
  html += '    	    <option value="7">7th</option>';
  html += '    	    <option value="8">8th</option>';
  html += '    	    <option value="9">9th</option>';
  html += '    	    <option value="10">10th</option>';
  html += '    	    <option value="11">11th</option>';
  html += '    	    <option value="12">12th</option>';
  html += '    	    <option value="13">13th</option>';
  html += '    	    <option value="14">14th</option>';
  html += '    	    <option value="15">15th</option>';
  html += '    	    <option value="16">16th</option>';
  html += '    	    <option value="17">17th</option>';
  html += '    	    <option value="18">18th</option>';
  html += '    	    <option value="19">19th</option>';
  html += '    	    <option value="20">20th</option>';
  html += '    	    <option value="21">21st</option>';
  html += '    	    <option value="22">22nd</option>';
  html += '    	    <option value="23">23rd</option>';
  html += '    	    <option value="24">24th</option>';
  html += '    	    <option value="25">25th</option>';
  html += '    	    <option value="26">26th</option>';
  html += '    	    <option value="27">27th</option>';
  html += '    	    <option value="28">28th</option>';
  html += '    	    <option value="29">29th</option>';
  html += '    	    <option value="30">30th</option>';
  html += '    	    <option value="31">31st</option>;';
  html += '		    <option value="-" disabled>&mdash;</option>';
  html += '    	    <option value="last">last</option>';
  html += '		</select>';
  html += '		<select class="day" name="day">';
  html += '    	    <option value="day">day</option>';
  html += '			<option value="-" disabled>&mdash;</option>';
  html += '    	    <option value="mon">Monday</option>';
  html += '			<option value="tue">Tuesday</option>';
  html += '			<option value="wed">Wednesday</option>';
  html += '			<option value="thu">Thursday</option>';
  html += '			<option value="fri">Friday</option>';
  html += '			<option value="sat">Saturday</option>';
  html += '			<option value="sun">Sunday</option>';
  html += '	    </select>';
  html += '	    <a class="plus"></a>';
  html += '	    <a class="minus"></a>';
  html += '	</td>';
  html += '</tr>';
  return html;
};

NIRV.renderRecurringFormYearly = function (interval, on) {
  var html = '';
  html += '<tr>';
  html += '	<td align="right" nowrap>';
  html +=
    '		<span class="first">Every <input type="text" class="interval" name="interval" value="1" maxlength="2"> year(s) on the </span>';
  html += '		<span class="andon">and on the </span>';
  html += '	</td>';
  html += '	<td nowrap>';
  html += '		<select class="nth" name="nth">';
  html += '        	<option value="01">1st</option>';
  html += '        	<option value="02">2nd</option>';
  html += '        	<option value="03">3rd</option>';
  html += '        	<option value="04">4th</option>';
  html += '        	<option value="05">5th</option>';
  html += '        	<option value="06">6th</option>';
  html += '        	<option value="07">7th</option>';
  html += '        	<option value="08">8th</option>';
  html += '        	<option value="09">9th</option>';
  html += '        	<option value="10">10th</option>';
  html += '        	<option value="11">11th</option>';
  html += '        	<option value="12">12th</option>';
  html += '        	<option value="13">13th</option>';
  html += '        	<option value="14">14th</option>';
  html += '        	<option value="15">15th</option>';
  html += '        	<option value="16">16th</option>';
  html += '        	<option value="17">17th</option>';
  html += '        	<option value="18">18th</option>';
  html += '        	<option value="19">19th</option>';
  html += '        	<option value="20">20th</option>';
  html += '        	<option value="21">21st</option>';
  html += '        	<option value="22">22nd</option>';
  html += '        	<option value="23">23rd</option>';
  html += '        	<option value="24">24th</option>';
  html += '        	<option value="25">25th</option>';
  html += '        	<option value="26">26th</option>';
  html += '        	<option value="27">27th</option>';
  html += '        	<option value="28">28th</option>';
  html += '        	<option value="29">29th</option>';
  html += '        	<option value="30">30th</option>';
  html += '        	<option value="31">31st</option>';
  html += '			<option value="-" disabled>&mdash;</option>';
  html += '        	<option value="last">last</option>';
  html += '		</select>';
  html += '		<select class="day" name="day">';
  html += '    	    <option value="day">day</option>';
  html += '			<option value="-" disabled>&mdash;</option>';
  html += '    	    <option value="mon">Monday</option>';
  html += '			<option value="tue">Tuesday</option>';
  html += '			<option value="wed">Wednesday</option>';
  html += '			<option value="thu">Thursday</option>';
  html += '			<option value="fri">Friday</option>';
  html += '			<option value="sat">Saturday</option>';
  html += '			<option value="sun">Sunday</option>';
  html += '		</select>';
  html += '		of';
  html += '		<select class="month" name="month">';
  html += '			<option value="01">January</option>';
  html += '			<option value="02">February</option>';
  html += '			<option value="03">March</option>';
  html += '			<option value="04">April</option>';
  html += '			<option value="05">May</option>';
  html += '			<option value="06">June</option>';
  html += '			<option value="07">July</option>';
  html += '			<option value="08">August</option>';
  html += '			<option value="09">September</option>';
  html += '			<option value="10">October</option>';
  html += '			<option value="11">November</option>';
  html += '			<option value="12">December</option>';
  html += '		</select>';
  html += '	    <a class="plus"></a>';
  html += '	    <a class="minus"></a>';
  html += '	</td>';
  html += '</tr>';
  return html;
};

NIRV.captureRecurringForm = function () {
  var o = {};

  o.paused = $('form.recurring input.paused').val() == 'true' ? true : false;

  o.freq = $('form.recurring select.freq').val();

  if (o.freq == 'aftercompletion') {
    o.interval = $('input.interval').val();
    o.period = $('select.period').val();
    o.nextdate = '';
    // make corrections
    o.interval = parseInt(o.interval, 10);
    o.interval = o.interval > 0 ? o.interval : 1;
    console.log('o', o);
  } else {
    // o.refdate = '20110201';
    switch (o.freq) {
      case 'daily':
        o.interval = $(
          'form.recurring div.intervalx.daily input.interval:first'
        ).val();
        break;

      case 'weekly':
        o.interval = $(
          'form.recurring div.intervalx.weekly input.interval:first'
        ).val();
        o.on = [];
        $('form.recurring div.intervalx.weekly tr').each(function () {
          var recurrence = '';
          recurrence = $(this).find('select.day').val();
          o.on.push(recurrence);
        });
        break;

      case 'monthly':
        o.interval = $(
          'form.recurring div.intervalx.monthly input.interval:first'
        ).val();
        o.on = [];
        $('form.recurring div.intervalx.monthly tr').each(function () {
          var recurrence = {};
          recurrence.day = $(this).find('select.day').val();
          recurrence.nth = $(this).find('select.nth').val();
          o.on.push(recurrence);
        });
        break;

      case 'yearly':
        o.interval = $(
          'form.recurring div.intervalx.yearly input.interval:first'
        ).val();
        o.on = [];
        $('form.recurring div.intervalx.yearly tr').each(function () {
          var recurrence = {};
          recurrence.month = $(this).find('select.month').val();
          recurrence.day = $(this).find('select.day').val();
          recurrence.nth = $(this).find('select.nth').val();
          o.on.push(recurrence);
        });
        break;
    }

    o.nextdate = mmddyyyy2yyyymmdd(
      $('form.recurring input[name=nextdate]').val()
    );
    // o.ends = $('form.recurring select.ends').val();
    if ($('form.recurring select.ends').val() == 'count') {
      o.count = $('form.recurring input.count').val();
      // make corrections
      o.count = parseInt(o.count, 10);
      o.count = o.count > 0 ? o.count : 1;
    } else if ($('form.recurring select.ends').val() == 'until') {
      o.until = mmddyyyy2yyyymmdd($('form.recurring input[name=until]').val());
    }
  }

  o.hasduedate = $('form.recurring input.hasduedate').prop('checked');
  if (o.hasduedate) {
    o.spawnxdaysbefore = $('form.recurring select.spawnxdaysbefore').val();
    // make corrections
    o.spawnxdaysbefore = parseInt(o.spawnxdaysbefore, 10);
    o.spawnxdaysbefore = o.spawnxdaysbefore > 0 ? o.spawnxdaysbefore : 0;
  }

  // make corrections
  o.interval = parseInt(o.interval, 10);
  o.interval = o.interval > 0 ? o.interval : 1;

  // make corrections
  o.hasduedate = o.hasduedate == true ? 1 : 0;

  // validation
  // if (o.nextdate != undefined && (o.nextdate < '20100101' || o.nextdate > '30100101')) {
  //     NIRV.alert('Invalid Next Date');
  //     return false;
  // }
  // if (o.endsondate != undefined && (o.endsondate < '20100101' || o.endsondate > '30100101')) {
  //     NIRV.alert('Invalid End Date');
  //     return false;
  // }
  return JSON.stringify(cloneObject(o));
};
