NIRV.Task = {
  id: '',
  type: NIRV.CONST.TASK,
  _type: 0,
  state: NIRV.CONST.INBOX,
  _state: 0,
  ps: NIRV.CONST.PARALLEL,
  _ps: 0,
  parentid: '',
  _parentid: 0,
  waitingfor: '',
  _waitingfor: 0,
  completed: 0,
  _completed: 0,
  cancelled: 0,
  _cancelled: 0,
  seq: 0,
  _seq: 0,
  seqt: 0,
  _seqt: 0,
  seqp: 0,
  _seqp: 0,
  name: '',
  _name: 0,
  tags: ',',
  _tags: 0,
  note: '',
  _note: 0,
  etime: '0',
  _etime: 0,
  energy: '0',
  _energy: 0,
  duedate: '',
  _duedate: 0,
  startdate: '',
  _startdate: 0,
  recurring: '',
  _recurring: 0,
  deleted: 0,
  _deleted: 0,
  updated: 0,
  __stale__: false,
  __hide__: false,
  __inactive__: function __inactive__() {
    if (this.parentid.toString() == '') {
      return false;
    } else {
      if (NIRV.tasks[this.parentid.toString()] != undefined) {
        return NIRV.tasks[this.parentid.toString()].state ==
          NIRV.CONST.ACTIVEPROJECT ||
          NIRV.tasks[this.parentid.toString()].state == NIRV.CONST.REFERENCE
          ? false
          : true;
      } else {
        return false;
      }
    }
  },
  __viewtoggle__: '',
  __isasequentialproject__: function __isasequentialproject__() {
    return this.ps == NIRV.CONST.SEQUENTIAL ? true : false;
  },
  __parentstate__: function __parentstate__() {
    if (this.parentid == '') {
      return '';
    } else {
      if (NIRV.tasks[this.parentid.toString()] != undefined) {
        return NIRV.tasks[this.parentid.toString()].state;
      } else {
        return 'UNDEFINED';
      }
    }
  },
  __parentname__: function __parentname__() {
    if (this.parentid == '') {
      return '';
    } else {
      if (NIRV.tasks[this.parentid.toString()] != undefined) {
        return NIRV.tasks[this.parentid.toString()].name;
      } else {
        return 'UNDEFINED';
      }
    }
  },
  __parenttags__: function __parenttags__() {
    if (this.parentid == '') {
      return '';
    } else {
      if (NIRV.tasks[this.parentid.toString()] != undefined) {
        return NIRV.tasks[this.parentid.toString()].tags;
      } else {
        return '';
      }
    }
  },
  __tags__: function __tags__() {
    if (this.parentid == '') {
      return this.tags;
    } else {
      if (NIRV.tasks[this.parentid.toString()] != undefined) {
        return this.tags + NIRV.tasks[this.parentid.toString()].tags;
      } else {
        return this.tags;
      }
    }
  },
  __spawndate__: function __spawndate__() {
    if (this.state == NIRV.CONST.SCHEDULED) {
      return this.startdate;
    }
    if (this.state == NIRV.CONST.RECURRING && this.recurring) {
      var recurring = JSON.parse(this.recurring);
      var nextdate = new Date(yyyymmdd2mmddyyyy(recurring.nextdate));
      return recurring.spawnxdaysbefore == undefined
        ? nextdate.toString('yyyyMMdd')
        : nextdate
            .addDays(-1 * recurring.spawnxdaysbefore)
            .toString('yyyyMMdd');
    }
  },
  __nextdate__: function __nextdate__() {
    if (this.state == NIRV.CONST.RECURRING && this.recurring) {
      return JSON.parse(this.recurring).nextdate;
    }
  },
  __spawned__: function __spawned__() {
    if (this.id.length > 36) {
      return this.id.substr(37);
    } else {
      return '';
    }
  },
  set: function set(key, value, timestamp) {
    timestamp = timestamp || time();
    if (this[key] != value) {
      if (key == 'tags') {
        value = NIRV.cleanseTagKey(value);
      }
      this[key] = value;
      this['_' + key] = timestamp;
      this.__stale__ = true;
    }
    return this;
  },
  clone: function clone(init, timestamp) {
    init = init || {};
    timestamp = timestamp || time();
    var o = cloneObject(this);
    // set timestamps
    for (var i in o) {
      if (typeof o[i] !== 'function') {
        if (i[0] == '_' && i[1] != '_') {
          o[i] = timestamp;
        }
      }
    }
    // copy values
    for (var i in init) {
      o[i] = init[i];
    }
    return o;
  },
  renderedit: function Task_renderedit(prompt) {
    // DEBUG && console.log('~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~');
    DEBUG && console.log('Task.renderedit( ' + this.id + ' )');
    var placeholder = 'To Do';
    if (parseInt(this.type, 10) == NIRV.CONST.PROJECT)
      placeholder = 'Project Name';
    if (parseInt(this.type, 10) == NIRV.CONST.REFLIST)
      placeholder = 'Reference List Name';
    if (parseInt(this.type, 10) == NIRV.CONST.REFITEM)
      placeholder = 'Reference Item';
    var that = this;
    var prompt = prompt ? 'prompt' : '';
    var check = this.completed != 0 ? 'completed' : '';
    var focus = this.seqt != 0 ? 'focus' : '';
    var atags = this.tags.split(',');
    var ptags = this.__parenttags__().split(',');
    var appends = NIRV.appendsForTaskId(this.id);

    var i;
    var html = '';
    html +=
      '<li class="task edit ' +
      check +
      ' ' +
      focus +
      ' " taskid="' +
      this.id +
      '">';
    html +=
      '<form action="#" method="post" autocomplete="off" class="' +
      prompt +
      ' form_taskedit">';
    html += '<table><tr valign="top">';

    html += '<td>';
    html += '</td>';

    html += '<td class="task-edit-col1">';
    // HIDDEN INPUT FIELDS FOR "SUBMIT"
    html += '<input type="hidden" name="id" value="' + this.id + '" />';
    html += '<input type="hidden" name="type" value="' + this.type + '" />';
    html +=
      '<input type="hidden" name="parentid" value="' + this.parentid + '" />';
    html += '<input type="hidden" name="state" value="' + this.state + '" />';
    // html += '<input type="hidden" name="completed" value="' + this.completed + '" />';
    html += '<input type="hidden" name="seqt" value="' + this.seqt + '" />';
    html +=
      "<input type='hidden' name='recurring' value='" + this.recurring + "' />";
    html +=
      '<input type="hidden" name="startdate" value="' + this.startdate + '" />';
    html +=
      '<input type="hidden" name="duedate" value="' + this.duedate + '" />';
    html +=
      '<input type="hidden" name="waitingfor" value="' +
      this.waitingfor +
      '" />';
    html += '<input type="hidden" name="ps" value="' + this.ps + '" />';
    html += '<input type="hidden" name="energy" value="' + this.energy + '" />';
    html += '<input type="hidden" name="etime" value="' + this.etime + '" />';

    html += '<table>';
    // TASK NAME
    html += '<tr>';
    html += '<td class="task-edit-col1-icons">';
    html += '<span class="check"></span>';
    html += '<span class="icon star"></span>';
    html += '</td>';
    html += '<td>';
    html +=
      ' <input tabindex="1" class="name" type="text" name="name" value="' +
      nescape(this.name) +
      '" placeholder="' +
      placeholder +
      '" />';
    html += '</td>';
    html += '</tr>';

    // TASK TAGS
    html += '<tr>';
    html += '<td class="task-edit-col1-icons">';
    // html += ' <span class="icon icon-tags"></span>';
    html += '</td>';
    html += '<td style="position:relative; height: 46px;">';
    // CMENU FOR TASK TAGS (CONTEXTS)
    html += ' <div class="cmenu cmenu_tags"></div>';
    // FCBKCOMPLETE SETUP
    html += '<select id="tags" name="tags" style="display: none;">';
    for (var i = 0; i < ptags.length; i++) {
      if (ptags[i] != '') {
        html +=
          '<option value="' +
          ptags[i] +
          '" class="selected locked">' +
          ptags[i] +
          '</option>';
      }
    }
    for (var i = 0; i < atags.length; i++) {
      if (atags[i] != '') {
        html +=
          '<option value="' +
          atags[i] +
          '" class="selected">' +
          atags[i] +
          '</option>';
      }
    }
    html += '</select>';
    html += '</td>';
    html += '</tr>';

    // NOTES
    html += '<tr valign="top">';
    html += '<td class="task-edit-col1-icons">';
    // html += '  <span class="icon icon-note"></span>';
    html += '</td>';
    html += '<td>';
    html +=
      ' <textarea tabindex="3" class="note" rows="3" data-min-rows="3" name="note" placeholder="Notes">' +
      nescape(this.note) +
      '</textarea>';
    html += '</td>';
    html += '</tr>';

    // APPENDS
    // html += '<tr>';
    // html += '<td class="task-edit-col1-icons">';
    // // html += ' <span class="icon icon-files"></span>';
    // html += '</td>';
    // html += '<td class="task-appends">';
    // for (var i = 0; i < appends.length; i++) {
    //   html += appends[i].render();
    // }
    // html += '</td>';
    // html += '</tr>';

    // FILE
    // html += '<tr valign="top">';
    // html += '<td class="task-edit-col1-icons">';
    // // html += '  <span class="icon icon-file"></span>';
    // html += '</td>';
    // html += '<td>';
    // html += '<form method="POST" enctype="multipart/form-data" id="fileUploadForm">';
    // html += ' <input type="file" name="file" class="uploadFile" style="font-size: 11px; padding: 2px; background: #eee;" /> ';
    // html += '</form>';
    // html += '</td>';
    // html += '</tr>';

    // SAVE / CANCEL
    html += '<tr valign="top">';
    html += '<td class="task-edit-col1-icons">';
    html += '</td>';
    html += '<td>';
    html += ' <div align="left" class="buttons">';
    html +=
      '  <a class="save awesome button primary">' + NIRV.L.savechanges + '</a>';
    html += '  <a class="cancel awesome button">' + NIRV.L.cancel + '</a>';
    html += '</div>';
    html += '</td>';
    html += '</tr>';

    html += '</table>';

    html += '</td>';

    // VERTICAL DIVIDER
    html += '<td><div class="vertical-divider"></div></td>';

    // META MENUS
    if (this.state != NIRV.CONST.LOGGED && this.state != NIRV.CONST.TRASHED) {
      html += '<td>';
      var duedatestr =
        this.duedate == ''
          ? '<i class="cmenu_duedate">due</i>'
          : 'Due: ' + ymdtolocale(this.duedate, 'MMMDY');
      if (this.type == NIRV.CONST.PROJECT) {
        var statename = '';
        var stateicon = '';
        switch (parseInt(this.state, 10)) {
          case NIRV.CONST.ACTIVEPROJECT:
            statename = 'Active Project';
            stateicon = 'activeproject';
            break;
          case NIRV.CONST.LATER:
            statename = NIRV.L.inactive;
            stateicon = 'inactiveproject';
            break;
          case NIRV.CONST.SCHEDULED:
            statename =
              NIRV.L.scheduled +
              ' &rsaquo; ' +
              ymdtolocale(this.startdate, 'MMMDY');
            stateicon = 'scheduledproject';
            break;
          case NIRV.CONST.SOMEDAY:
            statename = NIRV.L.someday;
            stateicon = 'somedayproject';
            break;
          case NIRV.CONST.TRASHED:
            statename = NIRV.L.trash;
            stateicon = 'trash';
            break;
        }
        var psname = '';
        var psicon = '';
        switch (parseInt(this.ps, 10)) {
          case NIRV.CONST.PARALLEL:
            psname = 'Parallel';
            psicon = 'parallel';
            break;
          case NIRV.CONST.SEQUENTIAL:
            psname = 'Sequential';
            psicon = 'sequential';
            break;
        }
        html +=
          '<div class="meta cmenu_duedate"><span class="metaicon due"></span>' +
          duedatestr +
          '</div>';
        html += '<br /><br />';
        html +=
          '<div class="meta cmenu_state"><span class="metaicon ' +
          stateicon +
          '"></span>' +
          statename +
          '</div>';
        html +=
          '<div class="meta cmenu_ps"><span class="metaicon ' +
          psicon +
          '"></span>' +
          psname +
          '</div>';
      } else if (this.type == NIRV.CONST.TASK) {
        var statename = '';
        var stateicon = '';
        switch (parseInt(this.state, 10)) {
          case NIRV.CONST.INBOX:
            statename = NIRV.L.inbox;
            stateicon = 'inbox';
            break;
          case NIRV.CONST.NEXT:
            statename = NIRV.L.next;
            stateicon = 'next';
            break;
          case NIRV.CONST.LATER:
            statename = 'Later';
            stateicon = 'later';
            break;
          case NIRV.CONST.WAITING:
            statename = 'Waiting for ' + this.waitingfor;
            stateicon = 'waiting';
            break;
          case NIRV.CONST.SCHEDULED:
            statename =
              NIRV.L.scheduled +
              ' &rsaquo; ' +
              ymdtolocale(this.startdate, 'MMMDY');
            stateicon = 'scheduled';
            break;
          case NIRV.CONST.RECURRING:
            statename =
              NIRV.L.scheduled + ' &rsaquo; ' + recurringtostr(this.recurring);
            stateicon = 'recurring';
            break;
          case NIRV.CONST.SOMEDAY:
            statename = NIRV.L.someday;
            stateicon = 'someday';
            break;
          case NIRV.CONST.TRASHED:
            statename = NIRV.L.trash;
            stateicon = 'trash';
            break;
        }
        var projectname =
          this.parentid != '' ? NIRV.tasks[this.parentid].name : 'Standalone';
        var etimestr =
          this.etime == 0
            ? '<i class="cmenu_etime">time</i>'
            : etimetostr(this.etime) + ' required';
        var energystr =
          this.energy == 0
            ? '<i class="cmenu_energy">energy</i>'
            : energytostr(this.energy);
        html +=
          '<div class="meta cmenu_etime"><span class="metaicon etime"></span>' +
          etimestr +
          '</div>';
        html +=
          '<div class="meta cmenu_energy"><span class="metaicon energy"></span>' +
          energystr +
          '</div>';
        if (this.recurring == '') {
          html +=
            '<div class="meta cmenu_duedate"><span class="metaicon due"></span>' +
            duedatestr +
            '</div>';
        }

        html += '<br /><br />';

        if (this.state == NIRV.CONST.RECURRING) {
          html +=
            '<div class="meta cmenu_state"><span class="metaicon ' +
            stateicon +
            '"></span>';
          html += recurringtostr(this.recurring);
          html += '</div>';
        } else {
          html +=
            '<div class="meta cmenu_state"><span class="metaicon ' +
            stateicon +
            '"></span>' +
            statename +
            '</div>';
        }
        html +=
          '<div class="meta cmenu_parent"><span class="metaicon belongsto"></span>' +
          projectname +
          '</div>';
      }

      html += '           <div>';
      html += '       </div>';
      html += '   </td>';
    }
    html += '</tr></table>';
    html += '   </form>';
    html += '</li>';
    return html;
  },
  render: function Task_render(nplus) {
    nplus = nplus || false;
    var hasnote = this.note == '' ? false : true;
    var aclass = [];
    if (nplus) aclass.push('nplus');
    if (this.type == NIRV.CONST.TASK) {
      aclass.push('task0');
    } else if (this.type == NIRV.CONST.PROJECT) {
      aclass.push('project');
      if (this.ps == NIRV.CONST.PARALLEL) {
        aclass.push('parallel');
      } else if (this.ps == NIRV.CONST.SEQUENTIAL) {
        aclass.push('sequential');
      }
    } else if (this.type == NIRV.CONST.REFLIST) {
      aclass.push('reflist');
    } else if (this.type == NIRV.CONST.REFITEM) {
      aclass.push('refitem');
    }
    if (this.parentid != '') aclass.push('hasparent');
    if (this.completed != 0) aclass.push('completed');
    if (this.cancelled != 0) aclass.push('cancelled');
    if (this.recurring != '') {
      aclass.push('repeating');
      var recurring = JSON.parse(this.recurring);
      if (recurring.paused == true) {
        aclass.push('paused');
      }
    }
    if (this.duedate != '' && this.duedate <= today()) aclass.push('due');
    // if (this.duedate != '' && this.duedate == today()) aclass.push('today');
    if (hasnote) aclass.push('hasnote');
    if (this.seqt != 0) aclass.push('focus');
    if (this.__hide__ == true) aclass.push('hide');
    if (this.__inactive__()) aclass.push('suspended');

    if (this.state == NIRV.CONST.INBOX) aclass.push('inbox');
    else if (this.state == NIRV.CONST.NEXT) aclass.push('next');
    else if (this.state == NIRV.CONST.SCHEDULED) aclass.push('scheduled');
    else if (this.state == NIRV.CONST.WAITING) aclass.push('waitingfor');
    else if (this.state == NIRV.CONST.SOMEDAY) aclass.push('someday');
    else if (this.state == NIRV.CONST.LATER) aclass.push('later');
    else if (this.state == NIRV.CONST.ACTIVEPROJECT)
      aclass.push('activeproject');
    else if (this.state == NIRV.CONST.LOGGED) aclass.push('logged');
    else if (this.state == NIRV.CONST.TRASHED) aclass.push('trashed');
    else if (this.state == NIRV.CONST.REFERENCE) aclass.push('reference');

    var nitems = NIRV.L.project;
    if (this.type == NIRV.CONST.REFLIST) {
      if (NIRV.taskcounts.l[this.id] != undefined) {
        nitems = NIRV.taskcounts.l[this.id].all + ' Items';
      } else {
        nitems = 'List';
      }
    }
    if (this.type == NIRV.CONST.PROJECT && this.state != NIRV.CONST.LOGGED) {
      if (NIRV.taskcounts.p[this.id] != undefined) {
        nitems = NIRV.taskcounts.p[this.id].all + ' Actions';
      }
    }
    if (this.type == NIRV.CONST.PROJECT && this.completed != 0) {
      if (NIRV.taskcounts.p[this.id] != undefined) {
        nitems = NIRV.taskcounts.p[this.id].completed + ' Actions';
        // nitems = 'completed';
      }
    }

    var parentname = ''; //'<span class="standalone">No Project</span>';
    if (this.parentid != '') {
      parentname = this.__parentname__();
    }

    var ncount = 0;
    if (this.parentid != '' && this.state != NIRV.CONST.LOGGED) {
      // ncount = NIRV.taskcounts.p[this.parentid].next;
    }

    var ps = '';
    if (this.type == NIRV.CONST.PROJECT) {
      if (this.ps == NIRV.CONST.PARALLEL) {
        ps = '<span class="ps right" title="Parallel"></span>';
      }
      if (this.ps == NIRV.CONST.SEQUENTIAL) {
        ps = '<span class="ps right" title="Sequential"></span>';
      }
    }

    var etime = '';
    if (this.etime != 0 && this.etime < 60) {
      etime = '<span class="tag etime">' + this.etime + 'm</span>';
    } else if (this.etime >= 60) {
      etime = '<span class="tag etime">' + this.etime / 60 + 'h</span>';
    }

    var energy = '';
    if (this.energy == 1) {
      energy = '<span class="tag energy">low</span>';
    } else if (this.energy == 2) {
      energy = '<span class="tag energy">med</span>';
    } else if (this.energy == 3) {
      energy = '<span class="tag energy">high</span>';
    }

    var html = '';
    html +=
      '<li class="task render ' +
      aclass.join(' ') +
      '" taskid="' +
      this.id +
      '">';

    html += '<table width="100%">';
    html += '<tr>';

    // LEFT
    html += '<td class="left">';
    html += '<span class="i drag project"></span>';
    if (this.recurring == '') {
      if (this.state != NIRV.CONST.REFERENCE) {
        html += '<span class="i check"></span>';
      }
      // html += '<span class="i completed"></span>';
      // html += '<span class="i item"></span>';
      // html += '<span class="i trash"></span>';
      html += '<span class="i star"></span>';
      html +=
        '<span class="completedon">' + timetomd(this.completed) + '</span>';
      html +=
        '<span class="x_startdate startdate" taskid="' +
        this.id +
        '" rel="startdate" title="Becomes a Next Action on ' +
        ymdtostr(this.startdate) +
        '">' +
        ymdtolocale(this.startdate) +
        '</span>';
    } else {
      var recurring = JSON.parse(this.recurring);
      var recurring_summary = recurringtostr(this.recurring, 'text');
      html += '<span class="i pauserecurring"></span>';
      // html += '<span class="i completed"></span>';
      // html += '<span class="i trash"></span>';
      html += '<span class="i star"></span>';
      html +=
        '<span class="completedon">' + timetomdhi(this.completed) + '</span>';
      html +=
        '<span class="recurring fn" fn="NIRV.promptRecurring" p1="[taskid]" title="' +
        recurring_summary +
        '">';
      if (recurring.paused) {
        html += 'Paused';
      } else {
        html += ymdtolocale(this.startdate);
      }
      html += '</span>';
    }
    html += '<span class="nitems">' + nitems + '</span>';
    // html += '<span class="statebubble">' + statetostr(this.state) + '</span>';
    // if (this.type == NIRV.CONST.PROJECT) {
    //     html += '<span class="ps"></span>';
    // }
    html +=
      '<span class="waitingfor x_waitingfor">' + this.waitingfor + '</span>';
    // html += '<span class="ncount">' + ncount + '</span>';
    // html += '<span class="spawned">' + this.id + '</span>';
    // html += '<span class="debug">' + this.seq + '</span>';
    html += '</td>';

    // MIDDLE
    html += '<td class="middle">';
    // html += '<div class="debugtaskidcopy">' + this.id + '</div>';
    html += '<span class="name edittask">' + nescape(this.name) + '</span>';
    if (this.__spawned__() != '') {
      var spawned = new Date(yyyymmdd2mmddyyyy(this.__spawned__())).toString(
        'MMM d'
      );
      html += '<span class="spawned">Spawned ' + spawned + '</span>';
      // html += '<span class="spawned">' + this.id + '</span>';
    }
    if (this.state == NIRV.CONST.RECURRING) {
      html += '<span class="spawned">' + recurring_summary + '</span>';
    }
    if (hasnote) {
      html +=
        '<span class="i note x_note" title="expand/collapse notes view"></span>';
    }
    if (this.parentid) {
      html +=
        '<span class="parentname" pid="' +
        this.parentid +
        '">' +
        parentname +
        '</span>';
    }

    html += '</td>';

    // RIGHT
    html += '<td class="right">';
    // html += '<span class="right" style="margin: 1em;font-size:9px;color:red;">&nbsp; seq ' + this.seq + ' • seqp ' + this.seqp + ' • seqt ' + this.seqt + '</span>';
    html += ps;
    html += '<span class="tags">';

    html += etime;
    html += energy;

    var atags = this.__tags__().split(',');

    atags.sort(isort);

    var tag_color = '';
    var tag_type = 0;
    for (var i = 0; i < atags.length; i++) {
      if (atags[i] != '') {
        tag_type =
          NIRV.tags[atags[i]] == undefined ? '' : NIRV.tags[atags[i]].type;
        if (tag_type == NIRV.CONST.CONTACT) {
          tag_color =
            NIRV.tags[atags[i]] == undefined ? '' : NIRV.tags[atags[i]].color;
          html +=
            '<span class="tag contact color_' +
            tag_color +
            '">' +
            atags[i] +
            '</span>';
        }
      }
    }
    for (var i = 0; i < atags.length; i++) {
      if (atags[i] != '') {
        tag_type =
          NIRV.tags[atags[i]] == undefined ? '' : NIRV.tags[atags[i]].type;
        if (tag_type == NIRV.CONST.CONTEXT) {
          tag_color =
            NIRV.tags[atags[i]] == undefined ? '' : NIRV.tags[atags[i]].color;
          html +=
            '<span class="tag context color_' +
            tag_color +
            '">' +
            atags[i] +
            '</span>';
        }
      }
    }
    for (var i = 0; i < atags.length; i++) {
      if (atags[i] != '') {
        tag_type =
          NIRV.tags[atags[i]] == undefined ? '' : NIRV.tags[atags[i]].type;
        if (tag_type == NIRV.CONST.AREA) {
          tag_color =
            NIRV.tags[atags[i]] == undefined ? '' : NIRV.tags[atags[i]].color;
          html +=
            '<span class="tag area color_' +
            tag_color +
            '">' +
            atags[i] +
            '</span>';
        }
      }
    }

    // for (var i = 0; i < atags.length; i++) {
    //    if (atags[i] != '') {
    //       html += '<span class="tag">' + atags[i] + '</span>';
    //    }
    // }

    if (this.duedate != '') {
      if (
        NIRV.prefs.UIRelativeDueDates &&
        NIRV.prefs.UIRelativeDueDates.value == '1'
      ) {
        html +=
          '<span class="x_duedate duedate" taskid="' +
          this.id +
          '" rel="duedate" title="Due ' +
          ymdtolocale(this.duedate, 'MMMDY') +
          '">' +
          duedatetohuman(this.duedate) +
          '</span>';
      } else {
        html +=
          '<span class="x_duedate duedate" taskid="' +
          this.id +
          '" rel="duedate" title="Due ' +
          ymdtolocale(this.duedate, 'MMMDY') +
          '">' +
          ymdtolocale(this.duedate) +
          '</span>';
      }
    }

    html += '</span>';
    html += '</td>';
    html += '<td class="far-right">';
    html += '  <span class="cmenu cmenu_task xcmenu"></span>';
    html += '</td>';
    html += '</tr>';

    // DOUBLE / INFINITY DIV
    if (
      (hasnote && this.__viewtoggle__ == 'expand') ||
      (hasnote &&
        this.__viewtoggle__ != 'collapse' &&
        NIRV.prefs.UIBTaskDetails &&
        NIRV.prefs.UIBTaskDetails.value == '3')
    ) {
      html += '<tr>';
      html += '<td colspan="4">';
      if (hasnote) {
        html += '<div class="note infinity">';
        html += nl2br(linkify(subtaskr(nescape(this.note))));
        html += '</div>';
      }
      html += '</td>';
      html += '</tr>';
    } else if (
      hasnote &&
      this.__viewtoggle__ != 'expand' &&
      NIRV.prefs.UIBTaskDetails &&
      NIRV.prefs.UIBTaskDetails.value == '2'
    ) {
      html += '<tr>';
      html += '<td colspan="4">';
      html +=
        '<div class="note double">' +
        linkify(htmlentities(this.note)) +
        '</div>';
      html += '</td>';
      html += '</tr>';
    }

    html += '</table>';
    html += '</li>';
    return html;
  },
  redraw: function Task_redraw() {
    var html = this.render();
    $('li.task[taskid=' + this.id + ']').replaceWith(html);
    return this;
  },
  cleanup: function Task_cleanup() {
    // DEBUG && console.log('Task.cleanup()');
    var now = time();

    // NAME
    if (this.name != this.name.trim()) {
      this.set('name', this.name.trim());
    }
    if (this.name == '') {
      if (this.type == NIRV.CONST.PROJECT) {
        this.set('name', 'Project Name');
      } else if (this.type == NIRV.CONST.REFLIST) {
        this.set('name', 'Reference List Name');
      } else if (this.type == NIRV.CONST.REFITEM) {
        this.set('name', 'Reference Item');
      } else if (this.type == NIRV.CONST.TASK) {
        this.set('name', 'To Do');
      } else {
        this.set('name', 'Untitled');
      }
    }

    // TAGS
    var atags = this.tags.split(',');
    var ptags = this.__parenttags__().split(',');
    var ntags = ',';

    for (var i = 0; i < atags.length; i++) {
      if (atags[i].trim() != '') {
        if (ptags.indexOf(atags[i]) == -1) {
          ntags += atags[i] + ',';
        }
      }
    }

    var antags = ntags.split(',').sort();
    ntags = ',';
    antags.forEach((tag) => {
      if (tag.trim() != '') {
        ntags += tag + ',';
      }
    });

    if (this.tags != ntags) {
      this.set('tags', ntags);
    }

    NIRV.processTags(this);

    // TYPES
    var valid_types = [
      NIRV.CONST.TASK,
      NIRV.CONST.PROJECT,
      NIRV.CONST.REFLIST,
      NIRV.CONST.REFITEM,
    ];
    if (valid_types.indexOf(parseInt(this.type, 10)) == -1) {
      DEBUG && console.log('cleanup: type -> task (placed in inbox)');
      this.set('type', NIRV.CONST.TASK);
      this.set('state', NIRV.CONST.INBOX);
    }

    // STATES
    var valid_states = [
      NIRV.CONST.INBOX,
      NIRV.CONST.NEXT,
      NIRV.CONST.WAITING,
      NIRV.CONST.SCHEDULED,
      NIRV.CONST.RECURRING,
      NIRV.CONST.SOMEDAY,
      NIRV.CONST.ACTIVEPROJECT,
      NIRV.CONST.LATER,
      NIRV.CONST.LOGGED,
      NIRV.CONST.TRASHED,
      NIRV.CONST.DELETED,
      NIRV.CONST.REFERENCE,
    ];
    if (valid_states.indexOf(parseInt(this.state, 10)) == -1) {
      DEBUG && console.log('cleanup: state -> inbox');
      this.set('state', NIRV.CONST.INBOX);
    }
    // STATE vs STARTDATE & WAITINGFOR
    if (this.state == NIRV.CONST.INBOX) {
      if (this.startdate != '') {
        DEBUG && console.log('cleanup: inbox startdate -> ""');
        this.set('startdate', '');
      }
      if (this.waitingfor != '') {
        DEBUG && console.log('cleanup: inbox waitingfor -> ""');
        this.set('waitingfor', '');
      }
      if (this.recurring != '') {
        DEBUG && console.log('cleanup: inbox recurring -> ""');
        this.set('recurring', '');
      }
    } else if (this.state == NIRV.CONST.NEXT) {
      if (this.startdate != '') {
        DEBUG && console.log('cleanup: next startdate -> ""');
        this.set('startdate', '');
      }
      if (this.waitingfor != '') {
        DEBUG && console.log('cleanup: next waitingfor -> ""');
        this.set('waitingfor', '');
      }
      if (this.recurring != '') {
        DEBUG && console.log('cleanup: next recurring -> ""');
        this.set('recurring', '');
      }
    } else if (this.state == NIRV.CONST.WAITING) {
      if (this.startdate != '') {
        DEBUG && console.log('cleanup: waiting startdate -> ""');
        this.set('startdate', '');
      }
      if (this.waitingfor == '') {
        DEBUG && console.log('cleanup: waiting waitingfor -> "Someone"');
        this.set('waitingfor', 'Someone');
      }
      if (this.recurring != '') {
        DEBUG && console.log('cleanup: waiting recurring -> ""');
        this.set('recurring', '');
      }
    } else if (this.state == NIRV.CONST.SCHEDULED) {
      if (!_isvalid_yyyymmdd(this.startdate)) {
        DEBUG && console.log('cleanup: scheduled startdate -> today(+1)');
        this.set('startdate', today(+1));
      }
      if (this.waitingfor != '') {
        DEBUG && console.log('cleanup: scheduled waitingfor -> ""');
        this.set('waitingfor', '');
      }
    } else if (this.state == NIRV.CONST.RECURRING) {
      if (this.recurring == '') {
        DEBUG && console.log('cleanup: recurring state -> deleted');
        this.set('state', NIRV.CONST.DELETED);
        this.set('deleted', now);
      } else {
        if (this.cancelled) {
          DEBUG && console.log('cleanup: recurring cancelled -> 0');
          this.set('cancelled', 0);
        }
        if (this.completed) {
          DEBUG && console.log('cleanup: recurring completed -> 0');
          this.set('completed', 0);
        }
        if (this.duedate != '') {
          DEBUG && console.log('cleanup: recurring duedate -> ""');
          this.set('duedate', '');
        }
        if (!_isvalid_yyyymmdd(this.startdate)) {
          DEBUG && console.log('cleanup: recurring startdate -> nextdate');
          if (this.recurring) {
            this.set('startdate', JSON.parse(this.recurring).nextdate);
          } else {
            this.set('startdate', '');
            this.set('state', NIRV.CONST.NEXT);
          }
        }
        if (this.waitingfor != '') {
          DEBUG && console.log('cleanup: recurring waitingfor -> ""');
          this.set('waitingfor', '');
        }
      }
    } else if (this.state == NIRV.CONST.SOMEDAY) {
      if (this.startdate != '') {
        DEBUG && console.log('cleanup: someday startdate -> ""');
        this.set('startdate', '');
      }
      if (this.waitingfor != '') {
        DEBUG && console.log('cleanup: someday waitingfor -> ""');
        this.set('waitingfor', '');
      }
      if (this.recurring != '') {
        DEBUG && console.log('cleanup: someday recurring -> ""');
        this.set('recurring', '');
      }
    } else if (this.state == NIRV.CONST.LATER) {
      if (this.startdate != '') {
        DEBUG && console.log('cleanup: later startdate -> ""');
        this.set('startdate', '');
      }
      if (this.waitingfor != '') {
        DEBUG && console.log('cleanup: later waitingfor -> ""');
        this.set('waitingfor', '');
      }
      if (this.recurring != '') {
        DEBUG && console.log('cleanup: later recurring -> ""');
        this.set('recurring', '');
      }
    } else if (this.state == NIRV.CONST.ACTIVEPROJECT) {
      if (this.startdate != '') {
        DEBUG && console.log('cleanup: activeproject startdate -> ""');
        this.set('startdate', '');
      }
      if (this.waitingfor != '') {
        DEBUG && console.log('cleanup: activeproject waitingfor -> ""');
        this.set('waitingfor', '');
      }
      if (this.recurring != '') {
        DEBUG && console.log('cleanup: activeproject recurring -> ""');
        this.set('recurring', '');
      }
    } else if (this.state == NIRV.CONST.LOGGED) {
      if (this.startdate != '') {
        DEBUG && console.log('cleanup: logbook startdate -> ""');
        this.set('startdate', '');
      }
      if (this.waitingfor != '') {
        DEBUG && console.log('cleanup: logbook waitingfor -> ""');
        this.set('waitingfor', '');
      }
      if (this.recurring != '') {
        DEBUG && console.log('cleanup: logbook recurring -> ""');
        this.set('recurring', '');
      }
      if (this.seq != 0) {
        DEBUG && console.log('cleanup: logbook seq -> 0');
        this.set('seq', 0);
      }
      if (this.seqt != 0) {
        DEBUG && console.log('cleanup: logbook seqt -> 0');
        this.set('seqt', 0);
      }
      if (this.seqp != 0) {
        DEBUG && console.log('cleanup: logbook seqp -> 0');
        this.set('seqp', 0);
      }
      if (this.completed == 0) {
        DEBUG &&
          console.log('cleanup: logbook completed -> now, cancelled -> 1');
        this.set('completed', now);
      }
    } else if (this.state == NIRV.CONST.TRASHED) {
      if (this.startdate != '') {
        DEBUG && console.log('cleanup: trash startdate -> ""');
        this.set('startdate', '');
      }
      if (this.waitingfor != '') {
        DEBUG && console.log('cleanup: trash waitingfor -> ""');
        this.set('waitingfor', '');
      }
      if (this.recurring != '') {
        DEBUG && console.log('cleanup: trash recurring -> ""');
        this.set('recurring', '');
      }
      if (this.seq != 0) {
        DEBUG && console.log('cleanup: trash seq -> 0');
        this.set('seq', 0);
      }
      if (this.seqt != 0) {
        DEBUG && console.log('cleanup: trash seqt -> 0');
        this.set('seqt', 0);
      }
      if (this.seqp != 0) {
        DEBUG && console.log('cleanup: trash seqp -> 0');
        this.set('seqp', 0);
      }
    }

    // TASK PROPERTIES
    if (this.type == NIRV.CONST.TASK) {
      if (this.ps != 0) {
        DEBUG && console.log('cleanup: task ps -> 0');
        this.set('ps', 0);
      }
      if (this.__parentstate__() == NIRV.CONST.LOGGED) {
        DEBUG && console.log('cleanup: task state -> logged');
        this.set('state', NIRV.CONST.LOGGED);
        this.set('completed', now);
      }
      if (this.parentid != '' && this.state == NIRV.CONST.INBOX) {
        DEBUG && console.log('cleanup: project task state -> next');
        this.set('state', NIRV.CONST.NEXT);
      }
    }

    if (this.startdate == undefined) {
      this.startdate = '';
    }

    // PROJECT PROPERTIES
    if (this.type == NIRV.CONST.PROJECT) {
      if (this.energy != 0) {
        DEBUG && console.log('cleanup: project energy -> 0');
        this.set('energy', 0);
      }
      if (this.etime != 0) {
        DEBUG && console.log('cleanup: project etime -> 0');
        this.set('etime', 0);
      }
      if (this.waitingfor != '') {
        DEBUG && console.log('cleanup: project waitingfor -> ""');
        this.set('waitingfor', '');
      }
      if (this.parentid != '') {
        DEBUG && console.log('cleanup: project parentid -> ""');
        this.set('parentid', '');
      }
      if (this.seqp != 0) {
        DEBUG && console.log('cleanup: project seqp -> 0');
        this.set('seqp', 0);
      }
      if (this.state == NIRV.CONST.INBOX) {
        DEBUG && console.log('cleanup: project state inbox -> activeproject');
        this.set('state', NIRV.CONST.ACTIVEPROJECT);
      }
      if (this.state == NIRV.CONST.NEXT) {
        DEBUG && console.log('cleanup: project state next -> activeproject');
        this.set('state', NIRV.CONST.ACTIVEPROJECT);
      }
      if (this.state == NIRV.CONST.WAITING) {
        DEBUG && console.log('cleanup: project state waiting -> activeproject');
        this.set('state', NIRV.CONST.ACTIVEPROJECT);
      }
    }

    // REFERENCE LIST/ITEM CHECKS
    if (this.type == NIRV.CONST.REFLIST) {
      if (this.state != NIRV.CONST.REFERENCE) {
        DEBUG && console.log('cleanup: reflist state -> reference');
        this.set('state', NIRV.CONST.REFERENCE);
      }
    }
    if (this.type == NIRV.CONST.REFITEM) {
      if (this.state != NIRV.CONST.REFERENCE) {
        DEBUG && console.log('cleanup: refitem state -> reference');
        this.set('state', NIRV.CONST.REFERENCE);
      }
    }

    // FOCUS CHECK
    if (this.duedate != '' && this.duedate <= today() && this.seqt == 0) {
      DEBUG && console.log('cleanup: task --> focus');
      this.set('seqt', time());
    }

    // COMPLETED vs CANCELLED
    if (this.completed == 0 && this.cancelled != 0) {
      DEBUG && console.log('cleanup: task cancelled');
      this.set('cancelled', 0);
    }
  },
  save: function Task_save() {
    if (this.id == '') {
      return;
    }
    if (this.__stale__) {
      DEBUG && console.log('  Task.save() ' + this.id + ' "' + this.name + '"');

      this.cleanup();

      var data = {
        method: 'task.save',
        id: this.id,
        type: this.type,
        _type: this._type,
        parentid: this.parentid,
        _parentid: this._parentid,
        waitingfor: this.waitingfor,
        _waitingfor: this._waitingfor,
        state: this.state,
        _state: this._state,
        completed: this.completed,
        _completed: this._completed,
        cancelled: this.cancelled,
        _cancelled: this._cancelled,
        seq: this.seq,
        _seq: this._seq,
        seqt: this.seqt,
        _seqt: this._seqt,
        seqp: this.seqp,
        _seqp: this._seqp,
        name: this.name,
        _name: this._name,
        tags: this.tags,
        _tags: this._tags,
        note: this.note,
        _note: this._note,
        ps: this.ps,
        _ps: this._ps,
        etime: this.etime,
        _etime: this._etime,
        energy: this.energy,
        _energy: this._energy,
        startdate: this.startdate,
        _startdate: this._startdate,
        duedate: this.duedate,
        _duedate: this._duedate,
        recurring: this.recurring,
        _recurring: this._recurring,
        deleted: this.deleted,
        _deleted: this._deleted,
      };
      // DEBUG && console.log(data);

      var ldata = cloneObject(data);
      ldata.__stale__ = this.__stale__;
      delete ldata.method;
      NIRV.storage.setItem('task.' + ldata.id, JSON.stringify(ldata));

      return data;
    }
  },
};
