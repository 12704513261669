// LISTENERS LOGIN

$(function () {
  $(document).on('click', '.notme', function (e) {
    e.preventDefault();
    NIRV.storage.setItem('u', '');
    NIRV.goLogin();
  });

  $(document).on('submit', '#login form', async function (e) {
    DEBUG && console.log('login submit');

    e.preventDefault();

    $('html').addClass('loading');

    var gmtoffset = $(this).find('input[name=gmtoffset]').val();
    var username = $(this).find('input[name=username]').val();
    var password = md5($(this).find('input[name=password]').val());

    let url =
      `${NIRV.baseurl_api}/auth/new?` +
      `&appid=${NIRV.app_info().id}` +
      `&appversion=${NIRV.app_info().version}`;

    let body = JSON.stringify({
      gmtoffset: gmtoffset,
      u: username,
      p: password,
    });

    let response;

    try {
      response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: body,
      });
    } catch (error) {
      // console.log('Error:', error);
      NIRV.alert(
        'Unable to login.<br />Please check your internet connection.'
      );
      $('html').removeClass('loading');
      return false;
    }

    $('html').removeClass('loading');
    let json = await response.json();

    if (json.results[0].error != undefined) {
      if (json.results[0].error.code == 98) {
        $('#login form div.error').html(
          '<span>Incorrect password, please try again.</span>'
        );
        $('#login form input[name=password]').val('');
      } else if (json.results[0].error.code == 13) {
        location.href = NIRV.baseurl_app + 'reset';
      } else {
        NIRV.alert(
          'Nirvana appears to be temporarily unavailable.\n' +
            'Please try again in a few minutes?'
        );
      }
    } else {
      DEBUG && console.log('Login Success');

      // save 'u' for logging in next time
      NIRV.storage.setItem('u', username);

      // set authtoken
      NIRV.authtoken = json.results[0].auth.token;
      NIRV.storage.setItem('authtoken', NIRV.authtoken);

      // let's go
      NIRV.must_sync = true;
      NIRV.goApp();
    }

    return false; // kill default form submit
  });
});
