/*
    render/filter based on key, 
    using the of __param__value__param__value

    tasklist parameters :

    __SORTBY__column        the column on which to sort (eg. name, seq, seqt, seqp, completed, updated)
    __ASCDESC__asc|desc     when rendering, sort ascending (default) or descending
    __SORTABLE__1|0         allow user to manually re-sort the list (1 == yes, 0 == no)

    task filters :

    __FOCUS__0,1            
    __COMPLETED__0,1 
    __TAG__name 
    __PARENT__id 
    __STATE__int,int,int 
    __TYPE__int,int


*/
NIRV.Tasklist = {
  key: '__type__0',
  params: {},
  name: '',
  parentid: '',
  sortby: 'seq',
  sortnumeric: true,
  ascdesc: 'asc',
  sortable: 1,
  tasks: [],
  set: function (key, value) {
    if (this[key] != value) {
      this[key] = value;
    }
    return this;
  },
  clone: function clone(init) {
    init = init || {};
    var o = cloneObject(this);
    for (var i in init) {
      o.set(i, init[i]);
    }
    return o;
  },
  etimetotal: function etimetotal() {
    var total = 0;
    for (var i = 0; i < this.tasks.length; i++) {
      total += parseInt(this.tasks[i].etime, 10);
    }
    // return 'estimated time required ~ ' + total/60 + 'h';
  },
  render: function render() {
    DEBUG && VERBOSE && console.log('Tasklist.render() ' + this.key);
    var empty = '';
    var pid = this.parentid == '' ? '' : 'pid="' + this.parentid + '"';
    var parentname = this.parentid == '' ? '' : 'parentname';
    var children = this.key.indexOf('__parent__') != -1 ? 'children' : '';
    var noshadow =
      this.key.indexOf('__completed__1') != -1 ||
      this.key.indexOf('__state__7') != -1
        ? 'noshadow'
        : '';
    var taskcount = this.tasks.length;
    var topn = NIRV.moreless[this.key] == 'more' ? '' : 'topn';
    var addClass = this.addClass == undefined ? '' : this.addClass;
    var collapsed = NIRV.collapse[NIRV.currentview + ':' + this.key]
      ? 'collapsed'
      : '';
    var collapsed_arrow = collapsed ? '▸' : ''; // '▾';

    var commontags = '';
    if (NIRV.currentarea == '__ALL__') {
      commontags = '';
    } else if (NIRV.currentarea == '__NONE__') {
      commontags = 'Unassigned';
    } else if (NIRV.q != '') {
      commontags = '';
    } else if (this.key.length > 36) {
      commontags = '';
    } else {
      if (NIRV.currentview != 'inbox' && NIRV.currentview != 'trash') {
        if (NIRV.currentview[0] == 'p' && NIRV.currentview != 'projects') {
          // commontags += NIRV.wtasks[NIRV.currentview.substr(1)].tags.replace(/,/g,' &nbsp;');
        } else {
          commontags += ' ' + NIRV.currentarea;
        }
      }
    }

    var html = '';
    html +=
      '<div class="tasklist ' +
      topn +
      ' ' +
      empty +
      ' ' +
      collapsed +
      ' ' +
      addClass +
      '" key="' +
      this.key +
      '">';

    if (this.name) {
      html += '	<div class="name">';
      html +=
        '       <span class="toggle" ' +
        pid +
        ' title="">' +
        collapsed_arrow +
        ' ' +
        this.name +
        ' <span class="count">(' +
        taskcount +
        ')</span></span>';
      // html += '       <span class="commontags">' + commontags + '</span>';
      // html += '       <span class="plustask">&nbsp;&nbsp;+ New&nbsp;&nbsp;&nbsp;</span>';
      html +=
        '       <span class="go ' +
        parentname +
        '" ' +
        pid +
        '>go to project &rarr;</span>';
      html += '   </div>';
    }
    
    html +=
      '	<ul class="tasks ' +
      children +
      ' ' +
      noshadow +
      ' insertable" sortby="' +
      this.sortby +
      '" sortable="' +
      this.sortable +
      '">';
    for (var i = 0; i < taskcount; i++) {
      if (pid == '') {
        html += this.tasks[i].render();
      } else {
        if (i < NIRV.topn) {
          html += this.tasks[i].render();
        } else {
          html += this.tasks[i].render(true);
        }
      }
    }
    html += '	</ul>';
    if (taskcount - NIRV.topn > 0 && pid != '') {
      html +=
        '<div class="moreless"><u class="more">show ' +
        (taskcount - NIRV.topn) +
        ' more items</u> <u class="less">show top ' +
        NIRV.topn +
        ' only</u></div>';
    }
    html += '</div>';
    return html;
  },
  redraw: function redraw() {
    $('.tasklist[key="' + this.key + '"]').replaceWith(this.render());
  },
  recalc: function recalc() {
    // DEBUG && console.log('Tasklist.recalc() ' + this.key);
    this.tasks = [];

    var tasks = [];
    var params = this.params;
    var i, t, key; // iters
    var value = '';

    for (t in NIRV.wtasks) {
      tasks.push(NIRV.wtasks[t]);
    }

    // loop through tasks and filter out those that are suspended
    if (
      NIRV.currentview == 'focus' ||
      NIRV.currentview == 'next' ||
      NIRV.currentview == 'waiting' ||
      NIRV.currentview == 'scheduled' ||
      NIRV.currentview == 'someday' ||
      NIRV.currentview == 'later'
    ) {
      for (t = 0; t < tasks.length; t++) {
        if (tasks[t].__inactive__()) {
          tasks.splice(t, 1);
          t--;
        }
      }
    }

    // UNL
    if (
      NIRV.q == '' &&
      NIRV.currentview == 'next' &&
      NIRV.prefs.UIBNextGroupBy &&
      NIRV.prefs.UIBNextGroupBy.value == 'none' &&
      params['completed'] != undefined &&
      params['completed'] == 0
    ) {
      // remove non-NEXT todos
      for (t = 0; t < tasks.length; t++) {
        if (
          tasks[t].state == NIRV.CONST.SOMEDAY ||
          tasks[t].state == NIRV.CONST.LATER
        ) {
          tasks.splice(t, 1);
          t--;
        }
      }

      // remove completed
      for (t = 0; t < tasks.length; t++) {
        if (tasks[t].completed != 0) {
          tasks.splice(t, 1);
          t--;
        }
      }

      // create separate arrays for futher filtering ptasks and stasks
      var ptasks = [];
      var stasks = [];
      for (t = 0; t < tasks.length; t++) {
        ptasks.push(tasks[t]);
        stasks.push(tasks[t]);
      }
      tasks = [];

      // filter for standalone tasks
      for (t = 0; t < stasks.length; t++) {
        if (stasks[t].parentid != '') {
          stasks.splice(t, 1);
          t--;
        }
      }

      // push stasks onto tasks
      for (t = 0; t < stasks.length; t++) {
        tasks.push(stasks[t]);
      }

      // set up projects obj with keys
      var aprojects = [];
      var projects = cloneObject(NIRV.projects());
      for (i in projects) {
        if (typeof projects[i] === 'object') aprojects.push(projects[i]);
      }
      // aprojects = sortEntitiesBy(aprojects,'seq','asc',true);
      ptasks = sortEntitiesBy(ptasks, 'seqp', 'asc', true);

      // push top project task onto temptasks
      for (i = 0; i < aprojects.length; i++) {
        if (typeof aprojects[i] === 'object') {
          // sequential
          if (aprojects[i].__isasequentialproject__()) {
            for (t = 0; t < ptasks.length; t++) {
              if (ptasks[t].parentid == aprojects[i].id) {
                tasks.push(ptasks[t]);
                t = ptasks.length;
              }
            }
          }
          // parallel
          else {
            for (t = 0; t < ptasks.length; t++) {
              if (ptasks[t].parentid == aprojects[i].id) {
                tasks.push(ptasks[t]);
              }
            }
          }
        }
      }

      this.sortby = 'seq';
    }

    // SEQUENTIAL DRILLDOWN -ISH
    if (
      NIRV.currentview == 'next' &&
      NIRV.prefs.UIBNextGroupBy &&
      NIRV.prefs.UIBNextGroupBy.value == '' &&
      params['top'] != undefined
    ) {
      var temptasks = [];

      // remove non-NEXT todos
      for (t = 0; t < tasks.length; t++) {
        if (tasks[t].state != NIRV.CONST.NEXT) {
          tasks.splice(t, 1);
          t--;
        }
      }

      // remove non-project tasks
      for (t = 0; t < tasks.length; t++) {
        if (tasks[t].parentid == '') {
          tasks.splice(t, 1);
          t--;
        }
      }

      // remove completed
      for (t = 0; t < tasks.length; t++) {
        if (tasks[t].completed != 0) {
          tasks.splice(t, 1);
          t--;
        }
      }

      // set up projects obj with keys
      var aprojects = [];
      var projects = cloneObject(NIRV.projects());
      for (i in projects) {
        if (typeof projects[i] === 'object') aprojects.push(projects[i]);
      }
      aprojects = sortEntitiesBy(aprojects, 'seq', 'asc', true);
      tasks = sortEntitiesBy(tasks, 'seqp', 'asc', true);

      // push top project task onto temptasks
      for (i = 0; i < aprojects.length; i++) {
        if (typeof aprojects[i] === 'object') {
          for (t = 0; t < tasks.length; t++) {
            if (tasks[t].parentid == aprojects[i].id) {
              temptasks.push(tasks[t]);
              t = tasks.length;
            }
          }
        }
      }

      tasks = temptasks;
      this.sortby = 'ignore';
    }

    var _today = new Date().clearTime().getTime().toString().slice(0, 10);
    var _thisweekend = new Date()
      .next()
      .sunday()
      .clearTime()
      .toString('yyyyMMdd');
    var _nextweekend = new Date()
      .next()
      .sunday()
      .clearTime()
      .addDays(7)
      .toString('yyyyMMdd');
    var _thisweek = new Date()
      .next()
      .sunday()
      .clearTime()
      .addDays(-7)
      .getTime()
      .toString()
      .slice(0, 10);
    var _lastweek = new Date()
      .next()
      .sunday()
      .clearTime()
      .addDays(-14)
      .getTime()
      .toString()
      .slice(0, 10);

    if (
      NIRV.prefs.UIDateWeekStartsOn != undefined &&
      NIRV.prefs.UIDateWeekStartsOn.value == '1'
    ) {
      var _thisweekend = new Date()
        .next()
        .monday()
        .clearTime()
        .toString('yyyyMMdd');
      var _nextweekend = new Date()
        .next()
        .monday()
        .clearTime()
        .addDays(7)
        .toString('yyyyMMdd');
      var _thisweek = new Date()
        .next()
        .monday()
        .clearTime()
        .addDays(-7)
        .getTime()
        .toString()
        .slice(0, 10);
      var _lastweek = new Date()
        .next()
        .monday()
        .clearTime()
        .addDays(-14)
        .getTime()
        .toString()
        .slice(0, 10);
    }

    var _historylimit = new Date()
      .clearTime()
      .addMonths(-6)
      .getTime()
      .toString()
      .slice(0, 10);

    // loop through params and remove tasks that do not match
    for (key in params) {
      value = params[key];

      // setup tasklist
      switch (key) {
        case 'sortby':
          this.sortby = value;
          break;
        case 'ascdesc':
          this.ascdesc = value;
          break;
        case 'sortable':
          this.sortable = value;
          break;
        case 'parentid':
          this.parentid = value;
          break;
      }

      // filter tasks
      for (t = 0; t < tasks.length; t++) {
        switch (key) {
          case 'sortby':
            break; // tasklist param
          case 'ascdesc':
            break; // tasklist param
          case 'sortable':
            break; // tasklist param
          case 'top':
            break;

          case 'focus':
            if (
              tasks[t]['state'] == NIRV.CONST.LOGGED ||
              tasks[t]['state'] == NIRV.CONST.TRASHED
            ) {
              tasks.splice(t, 1);
              t--;
            } else {
              if (value == 1) {
                if (tasks[t]['seqt'] == 0) {
                  tasks.splice(t, 1);
                  t--;
                }
              } else {
                if (tasks[t]['seqt'] != 0) {
                  tasks.splice(t, 1);
                  t--;
                }
              }
            }
            break;

          case 'nextsteps':
            if (value == 1) {
              if (
                tasks[t]['state'] == NIRV.CONST.INBOX ||
                tasks[t]['state'] == NIRV.CONST.LATER ||
                tasks[t]['state'] == NIRV.CONST.SOMEDAY ||
                tasks[t]['state'] == NIRV.CONST.RECURRING ||
                tasks[t]['state'] == NIRV.CONST.LOGGED ||
                tasks[t]['state'] == NIRV.CONST.TRASHED
              ) {
                tasks.splice(t, 1);
                t--;
              }
            }
            break;

          case 'suspended':
            if (value == 1) {
              if (!tasks[t].__inactive__()) {
                tasks.splice(t, 1);
                t--;
              }
            } else {
              if (tasks[t].__inactive__()) {
                tasks.splice(t, 1);
                t--;
              }
            }
            break;

          case 'completed':
            if (value == 0) {
              if (tasks[t]['completed'] != 0) {
                tasks.splice(t, 1);
                t--;
              }
            } else if (value == 'today') {
              if (tasks[t]['completed'] < _today) {
                tasks.splice(t, 1);
                t--;
              }
            } else if (value == 'thisweek') {
              if (
                tasks[t]['completed'] < _thisweek ||
                tasks[t]['completed'] >= _today
              ) {
                tasks.splice(t, 1);
                t--;
              }
            } else if (value == 'lastweek') {
              if (
                tasks[t]['completed'] < _lastweek ||
                tasks[t]['completed'] >= _thisweek
              ) {
                tasks.splice(t, 1);
                t--;
              }
            } else if (value == 'twoweeksplus') {
              if (
                tasks[t]['completed'] < _historylimit ||
                tasks[t]['completed'] >= _lastweek
              ) {
                tasks.splice(t, 1);
                t--;
              }
            } else {
              if (tasks[t]['completed'] == 0) {
                tasks.splice(t, 1);
                t--;
              }
            }
            break;

          case 'scheduledandrecurring':
            if (value == 1) {
              if (
                tasks[t]['state'] != NIRV.CONST.SCHEDULED &&
                tasks[t]['state'] != NIRV.CONST.RECURRING
              ) {
                tasks.splice(t, 1);
                t--;
              }
            } else if (value == 'thisweek') {
              if (
                tasks[t]['state'] != NIRV.CONST.SCHEDULED &&
                tasks[t]['state'] != NIRV.CONST.RECURRING
              ) {
                tasks.splice(t, 1);
                t--;
              } else {
                if (tasks[t].startdate >= _thisweekend) {
                  tasks.splice(t, 1);
                  t--;
                }
              }
            } else if (value == 'nextweek') {
              if (
                tasks[t]['state'] != NIRV.CONST.SCHEDULED &&
                tasks[t]['state'] != NIRV.CONST.RECURRING
              ) {
                tasks.splice(t, 1);
                t--;
              } else {
                if (
                  tasks[t].startdate < _thisweekend ||
                  tasks[t].startdate >= _nextweekend
                ) {
                  tasks.splice(t, 1);
                  t--;
                }
              }
            } else if (value == 'afternextweek') {
              if (
                tasks[t]['state'] != NIRV.CONST.SCHEDULED &&
                tasks[t]['state'] != NIRV.CONST.RECURRING
              ) {
                tasks.splice(t, 1);
                t--;
              } else {
                if (tasks[t].startdate < _nextweekend) {
                  tasks.splice(t, 1);
                  t--;
                }
              }
            }
            break;

          case 'logged':
            if (value == 0) {
              if (tasks[t]['state'] == NIRV.CONST.LOGGED) {
                tasks.splice(t, 1);
                t--;
              }
            } else {
              if (tasks[t]['state'] != NIRV.CONST.LOGGED) {
                tasks.splice(t, 1);
                t--;
              }
            }
            break;

          case 'trashed':
            if (value == 0) {
              if (tasks[t]['state'] == NIRV.CONST.TRASHED) {
                tasks.splice(t, 1);
                t--;
              }
            } else {
              if (tasks[t]['state'] != NIRV.CONST.TRASHED) {
                tasks.splice(t, 1);
                t--;
              }
            }
            break;

          case 'search':
            // THIS IS NOW HANDLED AT A GLOBAL LEVEL via NIRV.recalcWtasks()
            break;

          case 'tag':
            if (tasks[t] != undefined) {
              var searchthis, q;
              if (value == '__NONE__') {
                searchthis = (
                  tasks[t].__tags__() +
                  ',' +
                  tasks[t].waitingfor
                ).trim();
                searchthis = searchthis.replaceAll(',', '');
                if (NIRV.currentarea == '__NONE__') {
                  if (searchthis != '' || tasks[t].__inactive__()) {
                    tasks.splice(t, 1);
                    t--;
                  }
                } else {
                  if (
                    (searchthis != '' && searchthis != NIRV.currentarea) ||
                    tasks[t].__inactive__()
                  ) {
                    tasks.splice(t, 1);
                    t--;
                  }
                }
              } else {
                searchthis =
                  ',' + tasks[t].__tags__() + ',' + tasks[t].waitingfor + ',';
                q = ',' + value + ',';
                if (searchthis.indexOf(q) == -1 || tasks[t].__inactive__()) {
                  tasks.splice(t, 1);
                  t--;
                }
              }
            }
            break;

          default:
            if (tasks[t][key] != value) {
              tasks.splice(t, 1);
              t--; // because we've just shifted everything forward with the splice
            }
            break;
        }
      }
    }

    if (this.sortby == 'name') {
      this.sortable = false;
      this.sortnumeric = false;
      this.ascdesc = 'asc';
    }

    if (this.sortby == 'completed') {
      this.sortable = false;
      this.ascdesc = 'desc';
    }

    if (this.sortby == 'startdate') {
      this.sortable = false;
      this.ascdesc = 'asc';
    }

    if (this.sortby == 'duedate') {
      this.sortable = false;
      this.ascdesc = 'asc';
    }

    if (NIRV.currentfilters.due) {
      this.sortby = 'duedate';
      this.sortable = false;
      this.ascdesc = 'asc';
    }

    // sort tasks then push onto this
    if (this.sortby == 'ignore') {
      this.tasks = tasks;
    } else {
      this.tasks = sortEntitiesBy(
        tasks,
        this.sortby,
        this.ascdesc,
        this.sortnumeric
      );
    }

    return this;
  },
  save: function save() {},
};
