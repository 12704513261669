// LISTENERS TAGLIB

$(function () {
  // CANCEL TAG EDIT ON TAB CLICK
  $('body').on('click', '#taglib a.tab', function () {
    $('#taglib li.tag .cancel').click();
  });

  // NEW TAG
  $('body').on('click', '#taglib a.newtag', function () {
    $('#taglib li.tag .cancel').click();
    var type = NIRV.CONST.AREA;
    if ($(this).attr('rel') == 'context') {
      type = NIRV.CONST.CONTEXT;
    }
    if ($(this).attr('rel') == 'contact') {
      type = NIRV.CONST.CONTACT;
    }
    var tag = NIRV.Tag.clone({ type: type });
    $('#taglib ul.tags:visible').prepend(tag.renderedit());
    $('#taglib input[name=key]').focus();
  });

  // FORM SUBMIT
  $('body').on('submit', '#taglib form', function (e) {
    e.preventDefault();

    var now = time();
    var initkey = $(this).find('input[name=initkey]').val();
    var email = $(this).find('input[name=email]').val();
    var key = $(this).find('input[name=key]').val();
    var type = $(this).find('input[name=type]').val();
    key = NIRV.cleanseTagKey(key);

    if (key == '') {
      return false;
    }

    // NEW TAG
    if (initkey == '') {
      if (NIRV.tagExistsForKey(key)) {
        var existingtag = NIRV.tags[key];
        var alertmsg = '"' + key + '" already exists';
        if (existingtag.type == NIRV.CONST.CONTEXT) {
          alertmsg += ' as a Label';
        } else if (existingtag.type == NIRV.CONST.CONTACT) {
          alertmsg += ' as a Contact';
        } else if (existingtag.type == NIRV.CONST.AREA) {
          alertmsg += ' as an Area of Responsibility';
        }
        NIRV.alert(alertmsg);
        return false;
      } else {
        var tag = {
          key: key,
          _key: now,
          email: email,
          _email: now,
          type: type,
          _type: now,
          deleted: 0,
          _deleted: now,
        };
        NIRV.addTag(tag);
      }
    }

    // EXISTING TAG
    else {
      if (initkey != key && NIRV.tagExistsForKey(key)) {
        var tag = NIRV.tags[key];
        var stype = 'tag';
        if (tag.type == NIRV.CONST.AREA) {
          stype = 'area';
        }
        if (tag.type == NIRV.CONST.CONTACT) {
          stype = 'contact';
        }
        NIRV.confirm('Merge with existing ' + stype + ' "' + key + '"?', () => {
          NIRV.tags[initkey].set('email', email);
          if (key != initkey) {
            NIRV.renameTag(initkey, key);
            if (NIRV.tags[key] != undefined) {
              if (NIRV.currentarea == initkey) {
                NIRV.currentarea = key;
                // NIRV.refreshCurrentview();
              }
            }
            NIRV.refreshAll();
          }
        });
      }
    }

    NIRV.foo = setTimeout(function () {
      NIRV.renderTagLib();
      NIRV.refreshAll();
      // NIRV.refreshCurrentview();
    }, 250);

    // return false;
  });

  $('body').on('click', '#taglib li.tag span.name', function () {
    NIRV.editTag($(this).closest('li.tag').attr('key'));
    $('#taglib input[name=key]').focus().select();
  });

  $('body').on('click', '#taglib li.tag span.email', function () {
    NIRV.editTag($(this).closest('li.tag').attr('key'));
    $('#taglib input[name=email]').focus().select();
  });

  // EDIT TAG: SAVE (SUBMIT)
  $('body').on('click', '#taglib li.tag .save', function () {
    $(this).closest('.tagedit').find('form').submit();
  });

  // EDIT TAG: CANCEL
  $('body').on('click', '#taglib li.tag .cancel', function () {
    var key = $(this).closest('li.tag').attr('key');
    if (key == '') {
      $(this).closest('li.tag').remove();
    } else {
      $(this).closest('li.tag').replaceWith(NIRV.tags[key].render());
    }
  });

  $('body').on('click', '#taglib-areas a.sso-account', function () {
    NIRV.gotoAccount('upgrade');
    return false;
  });
});
