NIRV.manageAreas = function () {
  NIRV.manageTags('areas');
};

NIRV.manageContexts = function () {
  NIRV.manageTags('contexts');
};

NIRV.manageContacts = function () {
  NIRV.manageTags('contacts');
};

NIRV.manageTags = function (type) {
  NIRV.highlight_tasks(false);

  type = type || 'areas';
  var tab = 0;
  if (type == 'contacts') {
    tab = 1;
  } else if (type == 'contexts') {
    tab = 2;
  }

  var html = '';
  html += '   <div id="tabs">';

  html += '   	<ul>';
  html +=
    '   		<li><a class="tab" href="#taglib-areas">' + NIRV.L.areas + '</a></li>';
  html +=
    '   		<li><a class="tab" href="#taglib-contacts">' +
    NIRV.L.contacts +
    '</a></li>';
  html += '   		<li><a class="tab" href="#taglib-contexts">Labels</a></li>';
  html += '   	</ul>';

  html += '   	<div id="taglib-areas">';
  html += '           <div class="taglist">';
  if (0 == NIRV.user.maxareas) {
    html +=
      "      <p class='help'><a href='#' class='sso-account button awesome'>Upgrade to enable Areas</a></p>";
  } else if (NIRV.areaCount() >= NIRV.user.maxareas) {
    html +=
      "      <p class='help'><a href='#' class='sso-account button awesome'>Upgrade to Create Additional Areas</a></p>";
  } else {
    html += '      <a class="newtag button awesome" rel="area">New Area</a>';
  }
  html += '               <ul class="tags"><li></li></ul>';
  html +=
    "      <p class='inline-help'>Consider the areas in your life such as personal, work, school, family... areas let you filter your workspace globally, hiding all items not assigned to that area.</p>";
  html += '    </div>';
  html += '  </div>';

  html += '   	<div id="taglib-contacts">';
  html += '           <div class="taglist">';
  html +=
    '      <a class="newtag button awesome" rel="contact">New Contact</a>';
  html += '               <ul class="tags"><li></li></ul>';
  html +=
    "      <p class='inline-help'>Assigning contacts to a project or task lets you to quickly find everything concerning that person or organization when you reach out of them.</p>";
  html += '    </div>';
  html += '  </div>';

  html += '   	<div id="taglib-contexts">';
  html += '           <div class="taglist">';
  html += '      <a class="newtag button awesome" rel="context">New Label</a>';
  html += '               <ul class="tags"><li></li></ul>';
  html +=
    "      <p class='inline-help'>Assigning a label (context) will allow you to quickly filter for and knock off tasks that are better done in batches, such as errands or phone calls.</p>";
  html += '    </div>';
  html += '  </div>';

  html += '</div>';

  var options = {
    autoOpen: false,
    dialogClass: 'taglib',
    title: 'Manage Tags',
    height: 'auto',
    width: 420,
    position: NIRV.dialog_position_default,
    modal: true,
    resizable: false,
    open: function () {
      $('#main .awesome').addClass('dim');
      // $('#taglib').tabs('destroy');
      $('#taglib').tabs({
        selected: tab,
      });
      NIRV.renderTagLib();
    },
    close: function () {
      // $('#main .awesome').removeClass('dim');
      $('#taglib').tabs('destroy');
    },
    buttons: {
      Done: function () {
        $(this).dialog('close');
      },
    },
  };

  $('#taglib').dialog(options);

  if ($('#taglib').dialog('isOpen') == true) {
    return;
  } else {
    $('#taglib').html(html);
    $('#taglib').dialog('open');
  }
};

NIRV.renderTagLib = function () {
  var i;
  var areas = NIRV.areas();
  var contexts = NIRV.contexts();
  var contacts = NIRV.contacts();

  var $areas_ul = $('#taglib-areas ul.tags');
  var $contexts_ul = $('#taglib-contexts ul.tags');
  var $contacts_ul = $('#taglib-contacts ul.tags');

  $areas_ul.empty();
  $contexts_ul.empty();
  $contacts_ul.empty();

  for (i = 0; i < areas.length; i++) {
    if (NIRV.tags[areas[i]].deleted == 0) {
      $areas_ul.append(NIRV.tags[areas[i]].render());
    }
  }

  for (i = 0; i < contexts.length; i++) {
    if (NIRV.tags[contexts[i]].deleted == 0) {
      $contexts_ul.append(NIRV.tags[contexts[i]].render());
    }
  }

  for (i = 0; i < contacts.length; i++) {
    if (NIRV.tags[contacts[i]].deleted == 0) {
      $contacts_ul.append(NIRV.tags[contacts[i]].render());
    }
  }
};

NIRV.tagExistsForKey = function (key) {
  var exists = false;
  for (var i in NIRV.tags) {
    if (i == key) {
      if (NIRV.tags[i].deleted == 0) {
        exists = true;
      }
    }
  }
  return exists;
};

NIRV.setTagType = function (key, type) {
  var tag = NIRV.tags[key];
  var original_tag_type = tag.type;
  if (original_tag_type != type) {
    tag.set('type', type);
    tag.save();
    NIRV.renderTagLib();
    if (
      original_tag_type == NIRV.CONST.AREA &&
      type != NIRV.CONST.AREA &&
      key == NIRV.currentarea
    ) {
      NIRV.currentarea = '__ALL__';
      if (NIRV.currentview[0] == 'p' && NIRV.currentview != 'projects') {
        NIRV.view('focus');
      }
    }
    NIRV.refreshAll();
    // NIRV.refreshCurrentview();
  }
};

NIRV.setTagColor = function (key, color) {
  var tag = NIRV.tags[key];
  var original_tag_color = tag.color;
  if (original_tag_color != color) {
    tag.set('color', color);
    tag.save();
    NIRV.refreshAll();
    // NIRV.refreshCurrentview();
  }
};

NIRV.promptDeleteTag = function (key) {
  NIRV.foo = setTimeout(function () {
    // loop through all tasks to identify tags in use, and only confirm() prompt if found
    var tagsinuse = NIRV.tagsinuse();
    if (tagsinuse.indexOf(key) != -1) {
      if (key == 'Someone') {
        $('#taglib li.tag[key="' + key + '"]').remove();
      } else {
        NIRV.confirm(
          'Are you sure you want to remove "' +
            key +
            '" from your tag library?  This will remove "' +
            key +
            '" from any Actions and Projects it is currently assigned to, but the Actions and Projects themselves will remain.',
          () => {
            NIRV.deleteTag(key);
            $('#taglib li.tag[key="' + key + '"]').remove();
          }
        );
      }
    }
    //
    else {
      NIRV.confirm(
        'Are you sure you want to remove "' + key + '" from your tag library?',
        () => {
          NIRV.deleteTag(key);
          $('#taglib li.tag[key="' + key + '"]').remove();
        }
      );
    }
  });
};

NIRV.editTag = function (key) {
  $('#taglib li.tag .cancel').click();
  var $li = $('#taglib li.tag[key="' + key + '"]');
  var $ul = $li.closest('ul');
  var key = $li.attr('key');
  var tag = NIRV.tags[key];
  $li.replaceWith(tag.renderedit());
  $li = $('#taglib li.tag[key="' + key + '"]');
  if ($li.length != 0) {
    var ulH = $ul.height();
    if ($li.offset().top - $ul.offset().top > $ul.height() - $li.height()) {
      $ul.scrollTop($ul.scrollTop() + $li.height() - 42);
    }
  }
};

NIRV.fcbktags = function () {
  var fbtags = [];
  var tags = [];
  for (var i in NIRV.tags) {
    tags.push(i);
  }
  tags.sort(isort);
  for (var i = 0; i < tags.length; i++) {
    fbtags.push({
      key: tags[i],
      value: tags[i],
    });
  }
  return fbtags;
};

NIRV.fcbkcontacts = function () {
  var fbtags = [];
  var tags = [];
  for (var i in NIRV.tags) {
    if (NIRV.tags[i].type == NIRV.CONST.CONTACT) {
      tags.push(i);
    }
  }
  tags.sort(isort);
  for (var i = 0; i < tags.length; i++) {
    fbtags.push({
      key: tags[i],
      value: tags[i],
    });
  }
  return fbtags;
};

NIRV.fcbkcontexts = function () {
  var fbtags = [];
  var tags = [];
  for (var i in NIRV.tags) {
    if (NIRV.tags[i].type == NIRV.CONST.CONTEXT) {
      tags.push(i);
    }
  }
  tags.sort(isort);
  for (var i = 0; i < tags.length; i++) {
    fbtags.push({
      key: tags[i],
      value: tags[i],
    });
  }
  return fbtags;
};

NIRV.fcbkareas = function () {
  var fbtags = [];
  var tags = [];
  for (var i in NIRV.tags) {
    if (NIRV.tags[i].type == NIRV.CONST.AREA) {
      tags.push(i);
    }
  }
  tags.sort(isort);
  for (var i = 0; i < tags.length; i++) {
    fbtags.push({
      key: tags[i],
      value: tags[i],
    });
  }
  return fbtags;
};
