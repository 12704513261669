NIRV.goApp = function () {
  DEBUG && console.log('---------- NIRV.goApp() ----------');

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ '&uid': NIRV.user.id });

  NIRV.viewport = 'app';

  // STEP 1. hide interface until we're ready
  NIRV.showSplashSkeleton();

  // STEP 2. set default values on NIRV.prefs
  NIRV.setDefaults();

  // STEP 3. load cached data from local storage
  NIRV.rehydrateCachedData();

  // STEP 4. render content behind skeleton
  let content = '';
  content += '<div id="north"></div>';
  content += '<div id="east">';
  content += ' <div class="gtd"></div>';
  content += ' <div class="east_bottom"></div>';
  content += '</div>';
  content += '<div id="top"></div>';
  content += '<div id="help"></div>';
  content += '<div id="mast"></div>';
  content += '<div id="cbar"></div>';
  content += '<div id="main">';
  content += ' <div id="rapidentry"></div>';
  content += ' <div id="tips"></div>';
  content += ' <div id="tasklists"></div>';
  content += '</div>';
  $('#content').empty().html(content).hide();

  NIRV.applyTheme();

  // HASH BASED NAV BABY
  NIRV.hashnav();

  // DRAG SELECT
  $('#main').selectable({
    filter: 'li.task:not(.edit)',
    distance: 1,
    cancel: '.edit, .infinity, input',
    // selected: function (event, ui) {
    //   DEBUG && VERBOSE && console.log('selectable.selected()');
    //   DEBUG && VERBOSE && console.log($(ui.selected));
    // },
    // selecting: function (event, ui) {
    //   DEBUG && VERBOSE && console.log('selectable.selecting()');
    // },
    // start: function (event, ui) {
    //   DEBUG && VERBOSE && console.log('selectable.start()');
    // },
    stop: function (event, ui) {
      // DEBUG && VERBOSE && console.log('selectable.stop()');
      NIRV.draggable();
    },
    // unselected: function (event, ui) {
    //   DEBUG && VERBOSE && console.log('selectable.unselected()');
    // },
    // unselecting: function (event, ui) {
    //   DEBUG && VERBOSE && console.log('selectable.unselecting()');
    // },
  });

  // KICK OFF WITH INITIAL SYNC, THEN SYNC EVERY 5 SECONDS
  // skeleton splash screen will be removed after first successful sync
  NIRV.must_sync = true;
  NIRV.sync();

  NIRV.infiniteloop = setInterval(function () {
    NIRV.sync();
  }, 5000);
};

NIRV.reflow = function () {
  DEBUG && console.log('NIRV.reflow()');

  NIRV.dimensions.windowW = $(window).width() || 950;
  NIRV.dimensions.windowH = $(window).height() || 2000;
  NIRV.dimensions.northH = $('#north').outerHeight() || 42;
  NIRV.dimensions.eastW = $('#east').outerWidth() || 260;
  NIRV.dimensions.eastH = NIRV.dimensions.windowH - NIRV.dimensions.northH;
  NIRV.dimensions.east_bottomH = $('#east .east_bottom').outerHeight();
  NIRV.dimensions.gtdH = NIRV.dimensions.eastH - NIRV.dimensions.east_bottomH;
  NIRV.dimensions.cbarW = NIRV.dimensions.windowW - NIRV.dimensions.eastW;
  NIRV.dimensions.cbarH = $('#cbar').outerHeight() || 32;
  NIRV.dimensions.cbarT = NIRV.dimensions.northH;
  NIRV.dimensions.cbarL = NIRV.dimensions.eastW;
  NIRV.dimensions.topT = NIRV.dimensions.northH; // + NIRV.dimensions.cbarH;
  NIRV.dimensions.helpL = NIRV.dimensions.eastW + 15;
  // NIRV.dimensions.mainPT = NIRV.dimensions.northH + NIRV.dimensions.cbarH + 10;
  NIRV.dimensions.mainPL = NIRV.dimensions.eastW + 30;
  NIRV.dimensions.mainMH =
    NIRV.dimensions.windowH -
    NIRV.dimensions.northH -
    NIRV.dimensions.cbarH -
    140;
  NIRV.dimensions.projecteditcol1W =
    NIRV.dimensions.windowW - NIRV.dimensions.eastW - 376;
  NIRV.dimensions.taskeditcol1W =
    NIRV.dimensions.windowW - NIRV.dimensions.eastW - 376;
  NIRV.dimensions.mainW = NIRV.dimensions.windowW - NIRV.dimensions.eastW - 60;
  // NIRV.dimensions.projecteditcol1W = NIRV.dimensions.windowW - NIRV.dimensions.eastW - 376;
  // NIRV.dimensions.taskeditcol1W = NIRV.dimensions.windowW - NIRV.dimensions.eastW - 376;
  NIRV.dimensions.cmenuMH = NIRV.dimensions.windowH - 40;

  if ($('.task.edit').is(':visible')) {
    $('html').addClass('editing');
  } else {
    $('html').removeClass('editing');
  }
  $('#east').css('top', NIRV.dimensions.northH);
  $('#east').css('height', NIRV.dimensions.eastH);
  $('#east div.gtd').css('height', NIRV.dimensions.gtdH);
  if ($('#east div.gtd:hasScroll').length) {
    $('#east div.gtd').addClass('hasScroll');
  } else {
    $('#east div.gtd').removeClass('hasScroll');
  }
  $('#top').css('margin-top', NIRV.dimensions.topT);
  $('#help').css('margin-left', NIRV.dimensions.helpL);

  $('#cbar').css('padding-left', NIRV.dimensions.mainPL);
  $('#cbar').css('width', NIRV.dimensions.mainW);

  $('#main').css('padding-left', NIRV.dimensions.mainPL);
  $('#main').css('width', NIRV.dimensions.mainW);
  $('#main').css('min-height', NIRV.dimensions.mainMH);

  $('#main li.task.edit td.task-edit-col1').css(
    'min-width',
    NIRV.dimensions.taskeditcol1W
  );
  $('#main li.task.edit td.task-edit-col1 input.name').css(
    'min-width',
    NIRV.dimensions.taskeditcol1W
  );
  $('#main li.task.edit td.task-edit-col1 ul.holder').css(
    'min-width',
    NIRV.dimensions.taskeditcol1W
  );
  $('#main li.task.edit td.task-edit-col1 textarea.note').css(
    'min-width',
    NIRV.dimensions.taskeditcol1W
  );
  $('#cbar li.task.edit td.task-edit-col1').css(
    'min-width',
    NIRV.dimensions.projecteditcol1W
  );
  $('#cbar li.task.edit td.task-edit-col1 input.name').css(
    'min-width',
    NIRV.dimensions.projecteditcol1W
  );
  $('#cbar li.task.edit td.task-edit-col1 ul.holder').css(
    'min-width',
    NIRV.dimensions.projecteditcol1W
  );
  $('#cbar li.task.edit td.task-edit-col1 textarea.note').css(
    'min-width',
    NIRV.dimensions.projecteditcol1W
  );
  // $('#cbar').css('top', NIRV.dimensions.cbarT);
  // $('#cbar').css('left', NIRV.dimensions.cbarL);
  // $('#cbar').css('width', NIRV.dimensions.cbarW);
  $('#cmenu_css').html(
    '<style>#cmenu .sf-menu ul.tall { max-height: ' +
      NIRV.dimensions.cmenuMH +
      'px; overflow: auto; } </style>'
  );
};

NIRV.refreshAll = function () {
  DEBUG && console.log('NIRV.refreshAll()');

  if (NIRV.editing()) return;

  NIRV.autosave = false;
  NIRV.recalcWtasks();
  NIRV.refreshNorth();
  NIRV.refreshEast();
  NIRV.refreshMain();
  NIRV.refreshCbar();
  NIRV.applyTheme();
  NIRV.reflow();
  NIRV.autosave = true;
};

NIRV.goLogin = function (callback) {
  DEBUG && console.log('---------- NIRV.goLogin() ----------');

  if (!NIRV.isElectron) {
    location.href = '/login';
  }

  NIRV.viewport = 'login';
  NIRV.autosave = true;

  var u =
    NIRV.storage.getItem('u') != 'null' && NIRV.storage.getItem('u') != null
      ? NIRV.storage.getItem('u')
      : '';

  $('html').attr('class', 'login');

  var content = `
    <a id="logo-click" href="${NIRV.baseurl_www}" rel="external"></a>
    <div id="login">
      <div class="container">
        <form method="post" action="(runtime)">
          <div class="error"></div>
          <input type="hidden" name="gmtoffset" value="${gmtoffset()}">
          <h1>Log in</h1>
          <input type="text" id="username" name="username" value="${u}" placeholder="E-mail Address or Username">
          <input type="password" id="password" name="password" value="" placeholder="Password">
          <input type="submit" value="Log in to your account" class="submit">
          <br>
          <a rel="external" href="${
            NIRV.baseurl_account
          }forgot">Forgot your password?</a> 
          <p>
          Don't have an account?&nbsp; <a rel="external" href="${
            NIRV.baseurl_www
          }">Sign Up!</a>
          </p>
        </form>
      </div>
    </div>`;

  $('#content').html(content);

  setTimeout(function () {
    if ($('#login input[name=username]').val() == '') {
      $('#login input[name=username]').focus();
    } else {
      $('#login input[name=password]').val('').focus();
    }
  }, 100);

  // EXECUTE CALLBACK FUNCTION
  if (callback != undefined) {
    callback();
  }
};
